import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import PrivateRoute from 'components/Pages/PrivateRoute'
import Viewer from 'components/Pages/Viewer'
import Home from 'components/Pages/Home'
import Login from 'components/Pages/Login'
import Activity from 'components/Pages/Activity'
import PersonalSettings from 'components/Pages/PersonalSettings'
import DetailPressCycles from 'components/Pages/DetailPressCycles'
import History from 'components/Pages/History'
import Settings from 'components/Pages/Settings'
import UserManagement from 'components/Pages/UserManagement'
import Report from 'components/Pages/Report'

export const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={Viewer} />
                <Route exact path='/login' component={Login} />
                <PrivateRoute exact path='/optimization' component={Home} />
                <PrivateRoute exact path='/activity' component={Activity} />
                <PrivateRoute exact path='/efficiencytracker' component={Report} />

                <PrivateRoute
                    exact
                    path='/press-cycles'
                    component={DetailPressCycles}
                />
                <PrivateRoute exact path='/history' component={History} />
                <PrivateRoute exact path='/settings' component={Settings} />
                <PrivateRoute
                    exact
                    path='/user-management'
                    component={UserManagement}
                />
                <PrivateRoute
                    exact
                    path='/personal_settings'
                    component={PersonalSettings}
                />
            </Switch>
        </BrowserRouter>
    )
}
