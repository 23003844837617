import React from 'react'
import Card from 'components/Atoms/Card'
import CardContent from 'components/Atoms/CardContent'
import { ReactComponent as Layer } from 'assets/icons/art_layers.svg'
import './CardCycleProgress.scss'

const CardCycleProgress = ({ item, color, onClick }) => {
  const { ProcessCycle, ToolNumbers, StatusString } = item
  const ToolNumber = ToolNumbers && ToolNumbers.toString()
  return (
    <div onClick={onClick}>
      <Card>
        <CardContent>
          <div className='card-content cardCycleProgress'>
            <div className='media'>
              <div className='media-left'>
                <figure
                  className='image is-48x48'
                  style={{ backgroundColor: color }}
                >
                  <Layer className='next' />
                </figure>
              </div>
              <div className='media-content'>
                <p className='title is-4'>Cycle {ProcessCycle}</p>
                <p className='subtitle is-6'>
                  {ToolNumber}{' '}
                  {/* <span>
                    <img className='dotImg' src={dotImg} />
                  </span>
                  ___
                  <span>
                    <img className='dotImg' src={dotImg} />
                  </span>
                  {FilledBook} */}
                </p>
                <button className='button processing'>{StatusString}</button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default CardCycleProgress
