import React, { useState } from 'react'
import MoveModal from 'components/Molecules/MoveModal'
import EditCyclePerBookModal from 'components/Molecules/EditCyclePerBookModal'
import treeDotImg from 'assets/icons/button_threedots.svg'
import './CycleDetail.scss'

const CycleDetail = ({
  data,
  headData,
  isShowTreeDot,
  processCycle,
  isHaveAccess,
  isShowEdit,
  skipCount,
  searchKeyword
}) => {
  const [isShowCard, setIsShowCard] = useState(null)
  const [isMoveModalOpen, setMoveModalOpen] = useState(false)
  const [editCycleModalOpen, setEditCycleModalOpen] = useState(false)
  const [moveData, setMoveData] = useState(false)
  const showCard = item => {
    const { Id } = item
    if (Id === isShowCard) {
      setIsShowCard(null)
    } else {
      setIsShowCard(Id)
    }
  }
  const onClickMove = e => {
    setMoveData(e)
    setIsShowCard(false)
    setMoveModalOpen(true)
  }
  const onClickEditCycle = e => {
    setMoveData(e)
    setEditCycleModalOpen(true)
    setIsShowCard(null)
  }
  const handleClose = () => {
    setMoveModalOpen(false)
    setEditCycleModalOpen(false)
  }
  const { ProcessCycle, Priority, StatusString, Id } = processCycle || {}
  return (
    <div className='box cycleDetail test'>
      <table className='table is-narrow'>
        <thead>
          <tr className='has-background-white custom-tr'>
            {headData.length > 0 &&
              headData.map((item, i) => <th key={i}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item, i) => {
              const {
                ScheduleGroup,
                ToolNumber,
                LotNumber,
                LocationString,
                ItemNumber,
                PrepregMaterial,
                Quantity,
                PressRecipe,
                PanelSize,
                Color,
                MaxPanelPerBook,
                Id
              } = item
              return (
                <tr className='has-background-white detail-cycle' key={i}>
                  <th>
                    <div
                      className='dotImg'
                      style={{ backgroundColor: Color }}
                    />
                  </th>
                  <th style={{ width: 60 }}>{ProcessCycle}</th>
                  <th>{Priority}</th>
                  <th>{ScheduleGroup}</th>
                  <th>{ToolNumber}</th>
                  <th>{LotNumber}</th>
                  <th>{LocationString}</th>
                  <th>{ItemNumber}</th>
                  <th>{PrepregMaterial}</th>
                  <th>{Quantity}</th>
                  <th>{PressRecipe}</th>
                  <th>{PanelSize}</th>
                  <th>{MaxPanelPerBook}</th>
                  <th>
                    <>
                      {isHaveAccess && isShowTreeDot && isShowEdit && (
                        <img
                          className='treeDotImg'
                          src={treeDotImg}
                          onClick={() => showCard(item)}
                        />
                      )}
                      {!isShowTreeDot && (
                        <button className='button processing'>
                          {StatusString}
                        </button>
                      )}
                      {isHaveAccess && isShowCard === Id && (
                        <aside className='menu'>
                          <ul className='menu-list'>
                            <li onClick={() => onClickEditCycle(item)}>
                              <a>Edit</a>
                            </li>
                            <li
                              className='move'
                              onClick={() => onClickMove(item)}
                            >
                              <a>Move</a>
                            </li>
                          </ul>
                        </aside>
                      )}
                    </>
                  </th>
                </tr>
              )
            })}
        </tbody>
      </table>
      {isMoveModalOpen && (
        <MoveModal
          isOpen={isMoveModalOpen}
          onClose={handleClose}
          data={moveData}
          processCycle={ProcessCycle}
          pressCycleId={Id}
          priority={Priority}
          skipCount={skipCount}
          searchKeyword={searchKeyword}
        />
      )}
      {editCycleModalOpen && (
        <EditCyclePerBookModal
          isOpen={editCycleModalOpen}
          onClose={handleClose}
          data={moveData}
          processCycle={ProcessCycle}
          pressCycleId={Id}
          priority={Priority}
          skipCount={skipCount}
          searchKeyword={searchKeyword}
        />
      )}
    </div>
  )
}
CycleDetail.defaultProps = {
  isShowTreeDot: true,
  isShowEdit: true
}

export default CycleDetail
