import React, { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import dotImg from 'assets/icons/text_dot.svg'
import Bell from 'components/Molecules/Bell'
import { ReactComponent as InfoImg } from 'assets/icons/button_infromation2.svg'

import './DashboardHeader.scss'

const DashboardHeader = ({ data, bellData, bellLoading }) => {
  const {
    PreviousOptimizationTime,
    LastOptimizationTime,
    NextOptimizationTime,
    CurrentAlgorithm
  } = data

  let tooltip = useRef(null)
  return (
    <div className='container mb-5 DashboardHeader'>
      <h4 className='is-size-1 has-text-weight-bold'>
        Press Optimization
        <span className='vertical'>
          <span className='wip'>{CurrentAlgorithm}</span>
        </span>
        <Bell bellLoading={bellLoading} bellData={bellData} />
      </h4>
      <h5>
      {moment(LastOptimizationTime).format('ll')}
      <span>
        <img className='dotImg' src={dotImg} />
      </span>{' '}
      {moment(LastOptimizationTime).format('dddd')}
      <span>
        <img className='dotImg' src={dotImg} />
      </span>{' '}
      {moment(LastOptimizationTime).format('HH:mm')}
      <span data-tip data-event='click' data-for='button-info'>
          <InfoImg className='infoImg has-tooltip-right' fill='#a5a8b5' />
      </span>
        {/* {moment(LastOptimizationTime).format('ll')}
        <span>
          <img className='dotImg' src={dotImg} />
        </span>{' '}
        Current {moment(LastOptimizationTime).format('HH:mm')}
        <span data-tip data-event='click' data-for='button-info'>
          <InfoImg className='infoImg has-tooltip-right' fill='#a5a8b5' />
        </span> */}
      </h5>
      <ReactTooltip
        id='button-info'
        clickable
        globalEventOff='click'
        place='right'
        ref={el => (tooltip = el)}
      >
        <div className='cycle'>
          <h5 className='mb-17'>
            Previous Cycle{' '}
            <span>
              <img className='dotImg' src={dotImg} />
            </span>
            {`${moment(PreviousOptimizationTime).format('ll')}, 
            ${moment(
              PreviousOptimizationTime
            ).format('HH:mm')}`}
          </h5>
          <h5 className='mb'>
            Next Cycle{' '}
            <span>
              <img className='dotImg' src={dotImg} />
            </span>
            {`${moment(NextOptimizationTime).format('ll')}, ${moment(
              NextOptimizationTime
            ).format('HH:mm')}`}
          </h5>
        </div>
      </ReactTooltip>
    </div>
  )
}

export default DashboardHeader
