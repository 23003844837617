import React from 'react'
import './UserManagementHeader.scss'

const UserManagementHeader = ({ onClick, isHaveAccess }) => {
  return (
    <div className='mb-5 HistoryHeader'>
      <h4 className='is-size-1 has-text-weight-bold'>
        User Management
        {isHaveAccess && (
          <span className='vertical' style={{ float: 'right' }}>
            <button className='button is-link' onClick={() => onClick()}>
              Create User
            </button>
          </span>
        )}
      </h4>
    </div>
  )
}

export default UserManagementHeader
