import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/dashboardSummary'

const initialState = {
  response: {
    TotalCycles: 0,
    TotalCycleReleased: 0,
    TotalCycleCompleted: 0,
    TotalCycleReady: 0,
    TotalCycleOnProgress: 0,
    PreviousOptimizationTime: '',
    LastOptimizationTime: '',
    NextOptimizationTime: '',
    CurrentAlgorithm: ''
  },
  loading: false,
  success: false,
  error: null
}

const dashboardSummarySlice = createSlice({
  name: 'dashboardSummary',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...dashboardSummarySlice.actions,
  get
}

export const reducer = dashboardSummarySlice.reducer
