import React, { useState } from 'react'
import cx from 'classnames'
import TableBody from 'components/Atoms/TableBody'
import EditCycleModal from 'components/Molecules/EditCycleModal'
import Book from 'components/Molecules/Book'
import treeDotImg from 'assets/icons/button_threedots.svg'
import './TableCycleItem.scss'

const TableCycleItem = ({
  data,
  onClick,
  isCollapseFor,
  onClickButtonRelease,
  isHaveAccess,
  skipCount,
  searchKeyword
}) => {
  const [isShowCard, setIsShowCard] = useState(null)
  const [isEditCycleModalOpen, setIsEditCycleModalOpen] = useState(false)
  const [index, setIndex] = useState(null)
  const [getData, setData] = useState(null)
  const actionCollapse = index => {
    onClick(index)
    setIndex(index)
    setIsShowCard(null)
  }

  const showCard = item => {
    const { Id } = item
    if (Id === isShowCard) {
      setIsShowCard(null)
    } else {
      setIsShowCard(Id)
    }
  }

  const handleOpenModalEditCycle = item => {
    setData(item)
    setIsEditCycleModalOpen(true)
    setIsShowCard(false)
  }

  const handleClose = () => {
    setIsEditCycleModalOpen(false)
  }

  return (
    <>
      {Array.isArray(data) &&
        data.length > 0 &&
        data.map((item, i) => {
          const {
            ProcessCycle,
            PressTypeString,
            TotalPanel,
            PressRecipe,
            PanelSize,
            Priority,
            Books,
            Id
          } = item || {}

          return (
            <>
              <TableBody
                className={cx(
                  'card cursorpointer',
                  index === i && isCollapseFor === i && 'activeCard'
                )}
                key={i}
              >
                <>
                  <tr>
                    <th className='dark-text' onClick={() => actionCollapse(i)}>
                      {ProcessCycle}
                    </th>
                    <th onClick={() => actionCollapse(i)}>{PressTypeString}</th>
                    <th onClick={() => actionCollapse(i)}>{TotalPanel}</th>
                    <th onClick={() => actionCollapse(i)}>{PressRecipe}</th>
                    <th onClick={() => actionCollapse(i)}>{PanelSize}</th>
                    <th onClick={() => actionCollapse(i)}>{Priority}</th>
                    <th className='btnrelease' style={{width:170}}>
                      <>
                        <button
                          className='button is-link'
                          onClick={() => onClickButtonRelease(item)}
                        >
                          Execute
                        </button>
                        {isHaveAccess && (
                          <img
                            className='treeDotImg'
                            src={treeDotImg}
                            onClick={() => showCard(item)}
                          />
                        )}
                        {isHaveAccess && isShowCard === Id && (
                          <aside className='menu'>
                            <ul className='menu-list'>
                              <li
                                className='move'
                                onClick={() => handleOpenModalEditCycle(item)}
                              >
                                <a>Edit cycle</a>
                              </li>
                            </ul>
                          </aside>
                        )}
                      </>
                    </th>
                  </tr>
                  {index === i && isCollapseFor === i && (
                    <Book
                      data={Books}
                      processCycle={data[i]}
                      isHaveAccess={isHaveAccess}
                      skipCount={skipCount}
                      searchKeyword={searchKeyword}
                    />
                  )}
                </>
              </TableBody>
              <div className='m-5' />
            </>
          )
        })}
      {isEditCycleModalOpen && (
        <EditCycleModal
          isOpen={isEditCycleModalOpen}
          onClose={handleClose}
          data={getData}
          skipCount={skipCount}
          searchKeyword={searchKeyword}
        />
      )}
    </>
  )
}

export default TableCycleItem
