import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk('wip/get', async () => {
  const response = await api.getWip()
  return response
})
export const put = createAsyncThunk('wip/put', async payload => {
  const response = await api.putWip(payload)
  return response
})
