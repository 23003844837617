export const HeadTable = [
  'Cycle',
  'Press Type',
  'Total Qty',
  'Recipe',
  'Panel Size',
  'Priority',
  ''
]

export const HeadDetail = [
  '',
  'Cycle',
  'Priority',
  'Schedule Group',
  'Tool No.',
  'Lot No.',
  'Location',
  'Item Number',
  'Prepreg Material',
  'Panel Qty',
  'Recipe',
  'Panel Size',
  'Max',
  ''
]

export const HeadDetailCycleProgress = [
  '',
  'Cycle',
  'Priority',
  'Schedule Group',
  'Tool No.',
  'Lot No.',
  'Location',
  'Item Number',
  'Prepreg Material',
  'Panel Qty',
  'Recipe',
  'Panel Size',
  'Max',
  'Status'
]

export const HeadTableDataToBeUpdated = ['Lot Number', 'Item Number', 'Issue']

export const HeadTableUsers = [
  'User Name',
  'Role',
  'Email',
  'Status',
  'Date Created',
  'Date Modified',
  ''
]

export const color = ['#3F8CFF', '#FF754C', '#47D5CB', '#FFA2C0', '#A0D7E7']

export const HeadTableActivity = ['Algorithm', 'Date', 'Status', 'Download']
