import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'connections/restApi';

export const get = createAsyncThunk(
    'reportFilterAllMachine/get',
    async payload  => {
        const response = await api.getReportFilterMachines();
        var result = [];
        result.push({ id: 'All Machine', label: 'All Machine', value: 'All Machine' })
        for (var i = 0; i < response.length; i++) {
            var name = response[i];
            result.push({ id: name, label: name, value: name })
        }

        return result;
    }
)
