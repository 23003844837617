import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import './MachineCycleList.scss'

const MachineCycleList = ({ data, skipCount, maxResultCount, totalCount, page }) => {
    return (
        <>
            <div>
                {data.length > 0 && data.map((item, index) => {
                    const { Books, ProcessCycle, StatusString, OptimizationDate, ReleasedDate } = item
                    const arrayBook = []
                    for (var i = 0; i < 10; i++) {
                        console.log("item.UseableBook", item.UseableBook)
                        arrayBook.push({
                            number: i + 1,
                            quantity: Books && Books[i] ? Books[i].Quantity : 0,
                            ToolNumber: Books && Books[i] ? Books[i].ToolNumber : '',
                            LocationString: Books && Books[i] ? Books[i].LocationString : '',
                            LotNumber: Books && Books[i] ? Books[i].LotNumber : '',
                            PrepregMaterial: Books && Books[i] ? Books[i].PrepregMaterial : '',
                            lenght: Books && Books[i] ? 1 : 0,
                            Color: Books && Books[i] ? Books[i].Color : '',
                            isGray: item.UseableBook > i,
                            IsEdittedWhenReleased: Books && Books[i] ? Books[i].IsEdittedWhenReleased : false
                        })
                    }
                    return (
                        <div className='columns is-multiline is-gapless has-background-white machineCycleListItem'>
                            <div className='column p-3'>
                                <div className='lh0'>
                                    <div className="item-info">
                                        <h4 className="process-cycle">{ProcessCycle}</h4>
                                        <h5 className="mt-1">{StatusString}</h5>
                                        <h5 className="mt-2 mb-2 fz-10">#
                          {
                                                skipCount === 0 ?
                                                    index + 1
                                                    :
                                                    index + 1 + (Math.floor(((5 * page) - 5) / 5) * 5)
                                            }
                          &nbsp;in Queue
                      </h5>
                                        <h5>{moment(ReleasedDate).format('ll (ddd)')}</h5>
                                    </div>
                                </div>
                            </div>
                            {arrayBook.map((item, i) => {
                                const {
                                    number,
                                    quantity,
                                    ToolNumber,
                                    LocationString,
                                    LotNumber,
                                    PrepregMaterial,
                                    lenght,
                                    Color,
                                    IsEdittedWhenReleased,
                                    isGray
                                } = item
                                return (
                                    <div
                                        className={cx(lenght === 0 && 'nothingBook', 'column p-3') + (IsEdittedWhenReleased ? ' is-background-danger' : '')}
                                        style={{
                                            backgroundColor: !isGray && 'gray'
                                        }}
                                        key={i}
                                    >
                                        <div className='lh0'>
                                            <span
                                                className={cx('numberbullet')}
                                                style={{ backgroundColor: quantity === 0 ? 'black' : Color }}
                                            >
                                                {number}
                                            </span>
                                            {quantity !== 0 && (
                                                <span className='numbercycles'>{quantity}</span>
                                            )}
                                            {quantity !== 0 && (
                                                <div className='mt15'>
                                                    <div className='has-text-left is-size-2'>{ToolNumber}</div>
                                                    <div className='has-text-left is-size-2'>{LotNumber}</div>
                                                    <div className='has-text-left is-size-3'>
                                                        {PrepregMaterial}
                                                    </div>
                                                    <div className='has-text-left is-size-3'>
                                                        {LocationString}
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
                {data.length === 0 && <p style={{ textAlign: 'center' }}>
                    There is no data to display</p>}
            </div>
        </>
    )
}

export default MachineCycleList
