import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { object, string, number } from 'yup'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import FormInput from 'components/Molecules/FormInput'
import FormDropdownWithId from 'components/Molecules/FormDropdownWithId'

import { actions as cyclereasonsActions } from 'state/slices/cyclereasons'
import { actions as editmaxpanelperbookActions } from 'state/slices/editmaxpanelperbook'
import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'

import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'

import styles from './EditCyclePerBookModal.module.scss'

const validationSchema = object({
  MaxPanelPerBook: number()
    .typeError('Input must be a number value')
    .required('Required')
    .max(10, 'Allowed maximum is 10')
    .min(1, 'Minimum atleast 1'),
  ReasonChangeId: string().required('Required')
})

const initialValues = {
  MaxPanelPerBook: '',
  ReasonChangeId: ''
}

const EditCyclePerBookModal = ({
  isOpen,
  onClose,
  data,
  pressCycleId,
  priority,
  skipCount,
  searchKeyword
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)
  const [isPrompModalOpen, setIsPrompModalOpen] = useState(false)
  const { LotNumber, WorkOrderId } = data
  const { cyclereasons } = useSelector(state => ({
    potentialCycles: state.potentialCycles.data.Items,
    cyclereasons: state.cyclereasons.data
  }))

  useEffect(() => {
    dispatch(cyclereasonsActions.get())
    return () => dispatch(cyclereasonsActions.clear())
  }, [dispatch])

  const properInitialValues = currentValue => {
    const newObj = { ...initialValues }

    Object.entries(newObj).forEach(([key, val]) => {
      newObj[key] = (currentValue && currentValue[key]) || val
    })

    return newObj
  }

  const confirmEdit = () => {
    setIsPrompModalOpen(true)
  }

  const onCloseModalPromp = () => {
    setIsPrompModalOpen(false)
  }

  return (
    <>
      <Modal
        haveClose
        isLarge
        isOpen={isOpen}
        onClose={onClose}
        title='Edit Cycle'
      >
        <section className={cx('modal-card-body', styles.modalCardMove)}>
          <Formik
            enableReinitialize
            initialValues={properInitialValues(data)}
            validationSchema={validationSchema}
            onSubmit={async values => {
              setIsLoading(true)
              // const { Id } = data
              values.PressCycleId = pressCycleId
              values.WorkOrderId = WorkOrderId
              await dispatch(
                editmaxpanelperbookActions.editmaxpanelperbook(values)
              )
              const payload = {
                priority,
                statuses: [10],
                skipCount: searchKeyword !== ''? 0: skipCount,
                maxResultCount: 5,
                searchKeyword
              }
              dispatch(optimizationsPressMachinesActions.get(payload))
              dispatch(dashboardSummaryActions.get())
              setIsLoading(false)
              onClose()
            }}
          >
            {pristine => {
              const { ReasonChangeId } = pristine.values
              return (
                <Form>
                  <h3 className={cx(styles.mb20)}>Lot No : {LotNumber}</h3>
                  <FormInput
                    name='MaxPanelPerBook'
                    label='Maximal Panel Per Book (1 - 10)'
                    isRequired
                    autoComplete
                  />
                  <FormDropdownWithId
                    name='ReasonChangeId'
                    label='Detail of Changes'
                    required
                    data={cyclereasons}
                  />
                  <div className='field'>
                    <div className='control'>
                      <button
                        className={cx('button is-primary', {
                          'is-loading': isLoading
                        })}
                        type='button'
                        disabled={
                          ReasonChangeId ===
                          '00000000-0000-0000-0000-000000000000'
                        }
                        onClick={() => confirmEdit()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <Modal
                    isOpen={isPrompModalOpen}
                    onClose={onCloseModalPromp}
                    title='Edit Max Panel Per Book Confirmation'
                  >
                    <section
                      className={cx(
                        'modal-card-body editcyclebook',
                        styles.modalCard
                      )}
                    >
                      <h3
                        className='h3-content'
                        style={{ fontFamily: 'PoppinsLight' }}
                      >
                        Are you sure you want to edit the information of this
                        lot number?
                      </h3>
                    </section>
                    <footer
                      className={cx('modal-card-foot', styles.rightButton)}
                    >
                      <button
                        className={cx('button is-info', {
                          'is-loading': isLoading
                        })}
                        type='submit'
                      >
                        Yes
                      </button>
                      <button
                        className='button is-light'
                        type='button'
                        onClick={() => setIsPrompModalOpen(false)}
                      >
                        No
                      </button>
                    </footer>
                  </Modal>
                </Form>
              )
            }}
          </Formik>
        </section>
      </Modal>
    </>
  )
}

export default EditCyclePerBookModal
