import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './state/store'
//import * as serviceWorker from './serviceWorker'
import { Router } from './components/Pages'
//import { isSafari } from 'react-device-detect'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './styles/App.scss'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Router />
        <ReduxToastr />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//if (!isSafari) {
//  serviceWorker.register()
//}
