import React from 'react'
// import './TableHead.scss'

const TableHead = ({ tHeadData, className }) => {
  return (
    <thead className='tHead'>
      <tr>
        {Array.isArray(tHeadData) &&
          tHeadData.length > 0 &&
          tHeadData.map((item, i) => {
            return <th key={i}>{item}</th>
          })}
      </tr>
    </thead>
  )
}

export default TableHead
