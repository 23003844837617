import React from 'react'
import Footer from '../../Atoms/Footer'
import './Viewers.scss'

const Viewers = ({ children }) => {
    return (
        <main id='viewer-template'>
            <div className='main'>{children}</div>
            <Footer />
        </main>
    )
}

export default Viewers
