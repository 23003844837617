import React from 'react'
import cx from 'classnames'
import CycleItemStatus from 'components/Molecules/CycleItemStatus'
import './CycleItem.scss'

const CycleItem = ({ data }) => {
    const { Books } = data || []
    const arrayBook = []
    // console.log('data sdsd', data)
    for (var i = 0; i < 10; i++) {
        arrayBook.push({
            number: i + 1,
            quantity: Books && Books[i] ? Books[i].Quantity : 0,
            ToolNumber: Books && Books[i] ? Books[i].ToolNumber : '',
            LocationString: Books && Books[i] ? Books[i].LocationString : '',
            LotNumber: Books && Books[i] ? Books[i].LotNumber : '',
            PrepregMaterial: Books && Books[i] ? Books[i].PrepregMaterial : '',
            Color: Books && Books[i] ? Books[i].Color : '',
            isGray: data && data.UseableBook > i,
            IsEdittedWhenReleased: Books && Books[i] ? Books[i].IsEdittedWhenReleased : false
        })
    }

    return (
        <>
            <div className='columns is-multiline is-gapless has-background-white cycleItem'>
                {arrayBook.map((item, i) => {
                    const {
                        number,
                        quantity,
                        ToolNumber,
                        LocationString,
                        LotNumber,
                        PrepregMaterial,
                        Color,
                        IsEdittedWhenReleased,
                        isGray
                    } = item || 0
                    return (
                        <div className={'column p-3' + (IsEdittedWhenReleased ? ' is-background-danger' : '')} key={i}
                        style={{
                            backgroundColor: Books && Books.length > 0 &&  !isGray && 'gray'
                        }}
                        >
                            <div className='lh0'>
                                <span
                                    className={cx(
                                        'numberbullet'
                                        // quantity === 0 ? 'black' : 'blue'
                                    )}
                                    style={{ backgroundColor: quantity === 0 ? 'black' : Color }}
                                >
                                    {number}
                                </span>
                                {quantity !== 0 && (
                                    <span className='numbercycles'>{quantity}</span>
                                )}
                            </div>
                            {quantity !== 0 && (
                                <div className='mt15'>
                                    <div className='has-text-left is-size-2'>{ToolNumber}</div>
                                    <div className='has-text-left is-size-2'>{LotNumber}</div>
                                    <div className='has-text-left is-size-3'>
                                        {PrepregMaterial}
                                    </div>
                                    <div className='has-text-left is-size-3'>
                                        {LocationString}
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            {Array.isArray(Books) && Books.length !== 0 && (
                <footer className='card-footer'>
                    <p className='card-footer-item left'>
                        <CycleItemStatus item={data} />
                    </p>
                </footer>
            )}
        </>
    )
}

export default CycleItem
