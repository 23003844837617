import { createSlice } from '@reduxjs/toolkit'

import { editmaxpanelperbook } from 'state/thunks/editmaxpanelperbook'

const initialState = {
  response: {},
  loading: false,
  success: false,
  error: null
}

const editmaxpanelperbookSlice = createSlice({
  name: 'editmaxpanelperbook',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(editmaxpanelperbook.pending, state => {
      state.loading = true
    })

    builder.addCase(editmaxpanelperbook.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(editmaxpanelperbook.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...editmaxpanelperbookSlice.actions,
  editmaxpanelperbook
}

export const reducer = editmaxpanelperbookSlice.reducer
