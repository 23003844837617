import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import ViewerCycleItem from 'components/Organisms/ViewerCycleItem'
import { actions as optimizationsMachinesActions } from 'state/slices/optimizationsMachines'
import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'

import dotImg from 'assets/icons/text_dot.svg'
import './ViewerPressOptimization.scss'

const ViewerPressOptimization = ({ propMachineId, machineName, index }) => {
  const dispatch = useDispatch()
  const [maxResultCount] = useState(1000)
  const [isCollapseFor, setCollapseFor] = useState(null)

  const { responseArray, responseViewer, newAryy } = useSelector(state => ({
    responseArray: state.optimizationsMachines.arrayRes,
    responseViewer: state.optimizationsMachines.response.Items,
    newAryy: state.optimizationsMachines.newArr,
  }))

  const actionCollapse = index => {
    if (isCollapseFor === index) {
      setCollapseFor(null)
    } else {
      setCollapseFor(index)
    }
  }



  useEffect(() => {
    const machineId = propMachineId
    const propsMachineName = machineName
    const payload = {
      statuses: [20, 30],
      skipCount: 0,
      maxResultCount: maxResultCount,
      machineId: machineId,
      machineName: propsMachineName
    }
    dispatch(dashboardSummaryActions.clear())
    dispatch(dashboardSummaryActions.get())
    dispatch(optimizationsMachinesActions.get(payload))
    return () => dispatch(optimizationsMachinesActions.clear())
  }, [dispatch])

  const newArray = []
  responseArray &&
    responseArray.map(item => {
      if (item) newArray.push(item)
    })

  const keys = ['machineCode']
  const filtered = newAryy.filter(
      (s => o => 
          (k => !s.has(k) && s.add(k))
          (keys.map(k => o[k]).join('|'))
      )
      (new Set)
  );

  filtered &&
  filtered.map(item => {
      if (item) newArray.push(item)
    })

  const newListsF =
  newArray &&
    Array.isArray(newArray) &&
    filtered.length > 0 &&
    filtered.sort((a, b) => (a.machineCode > b.machineCode ? 1 : -1))
  return useMemo(() => {
    return (
      <>
  
{isCollapseFor !== index  && (
  <div className='columns is-multiline mb-3 viewerPressOptimization'>
          <div className='column is-12'>
            <div className='card card-custom'>
              {newListsF &&
                newListsF.map((item, i) => {
                  const {
                    ReleasedBy,
                    ReleasedDate,
                    ProcessCycle,
                    StatusString,
                    Data,
                    PressTypeString
                  } = item.machine || {}
                  if (index === i) {
                    return (
                      <>
                      
                        <header className='card-header' key={i}>
                          <div className='card-header-title is-size-5 has-text-weight-bold'>
                            <button className='button is-dark'>
                              {machineName}
                            </button>
                            <span className='sub-title'>{ProcessCycle}</span>
                            {
                              Data[0].Books.length > 0 && 
                              <>
                                <span>
                                  <img className='dotImg recipe' src={dotImg} />
                                </span>{' '}
                                <span>
                                  Recipe : {Data && Data[0] && Data[0].PressRecipe}
                                </span>
                              </>
                            }
                            {
                              Data[0].Books.length > 0 && 
                              <>
                                <span>
                                  <img className='dotImg recipe' src={dotImg} />
                                </span>{' '}
                                <span>
                                  {Data && Data[0] && Data[0].PressTypeString}
                                </span>
                              </>
                            }
                            
                          </div>
                          {ReleasedBy && (
                            <div className='card-header-icon release-right'>
                              <span>
                                {StatusString}{' '}
                                <span>
                                  <img className='dotImg' src={dotImg} />
                                </span>{' '}
                                {`Executed by ${ReleasedBy}`}{' '}
                                <span>
                                  <img className='dotImg' src={dotImg} />
                                </span>{' '}
                                {moment(ReleasedDate).format('ll')}
                                <span>
                                  <img className='dotImg' src={dotImg} />
                                </span>{' '}
                                {moment(ReleasedDate).format('dddd')}
                                <span>
                                  <img className='dotImg' src={dotImg} />
                                </span>{' '}
                                {moment(ReleasedDate).format('HH:mm')}
                                {/* {`${moment(ReleasedDate).format('ll - HH:mm')}`} */}
                                
                              </span>
                            </div>
                          )}
                        </header>
                        {Data.length > 0 && Data.map((item, index)=> {
                          if(index === 0)
                          return(
                            <div className='card-content' key={index}>
                              <ViewerCycleItem data={item} />
                            </div>
                          )
                        })}
                        {Data.length > 0 && Data.length !== 1 && <div className='column is-12 mb-2 p-1rem'>
                          <button className="button float-right btn-collapse" onClick={() => actionCollapse(index)}>Expand</button>
                        </div>}
                        
                      </>
                    )
                  }
                })}
                
            </div>
          </div>
        </div>
)}

{isCollapseFor === index  && (
  <div className='columns is-multiline mb-3 viewerPressOptimization'>
          <div className='column is-12'>
            <div className='card card-custom'>
              {newListsF &&
                newListsF.map((item, i) => {
                  const {
                    Data
                  } = item.machine || {}
                  if (index === i) {
                    return (
                      <>
                        
                        {Data.length > 0 && Data.map((item, ind)=> {
                          return(
                            <>
                            <header className='card-header' key={i}>
                              <div className='card-header-title is-size-5 has-text-weight-bold'>
                              {ind === 0  && 
                                <button className='button is-dark'>
                                  {machineName}
                                </button>
                              }
                              {ind !== 0  && 
                                <button className='button is-transparant'>
                                  &nbsp;
                                </button>
                              }
                                
                                <span className='sub-title'>{item.ProcessCycle}</span>
                                {
                                  Data.length > 0 && <>
                                  <span>
                                    <img className='dotImg recipe' src={dotImg} />
                                  </span>{' '}
                                  <span>
                                    Recipe : {item.PressRecipe}
                                  </span></>
                                }
                                
                              </div>
                              {item.ReleasedBy && (
                                <div className='card-header-icon release-right'>
                                  <span>
                                    {item.StatusString}{' '}
                                    <span>
                                      <img className='dotImg' src={dotImg} />
                                    </span>{' '}
                                    {`Executed by ${item.ReleasedBy}`}{' '}
                                    <span>
                                      <img className='dotImg' src={dotImg} />
                                    </span>{' '}
                                    {moment(item.ReleasedDate).format('ll')}
                                    <span>
                                      <img className='dotImg' src={dotImg} />
                                    </span>{' '}
                                    {moment(item.ReleasedDate).format('dddd')}
                                    <span>
                                      <img className='dotImg' src={dotImg} />
                                    </span>{' '}
                                    {moment(item.ReleasedDate).format('HH:mm')}
                                    {/* {`${moment(ReleasedDate).format('ll - HH:mm')}`} */}
                                    
                                  </span>
                                </div>
                              )}
                            </header>
                            <div className='card-content' key={index}>
                              <ViewerCycleItem data={item} />
                            </div>
                            </>
                          )
                        })}
                        
                      </>
                    )
                  }
                })}
                <div className='column is-12 mb-2 p-1rem'>
                  <button className="button float-right btn-collapse" onClick={() => actionCollapse(index)}>Collapse</button>
                </div>
            </div>
          </div>
        </div>
        )}
      </>
    )
  })
}

export default ViewerPressOptimization
