import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk('activity/get', async payload => {
  const response = await api.getActivity(payload)
  return response
})

export const download = createAsyncThunk('activity/download', async payload => {
  console.log('payload download', payload)
  const response = await api.downloadActivity(payload)
  return response
})
