import React from 'react'
import s from 'shortid'
// import { useDetectClickOutside } from 'react-detect-click-outside'
import './SelectMachine.scss'

const SelectMachine = ({ data, onChange, value, onClickOutside }) => {
  // const ref = useDetectClickOutside({ onTriggered: onClickOutside })
  return (
    <div className='select-machine control dropdown' 
    // ref={ref}
    >
      <div className='select is-small'>
        <select className='is-rounded' onChange={onChange} value={value}>
          <option value=''>Please Select</option>
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map(item => {
              const { Name, Id, RemainingCycle } = item
              return (
                <option key={s.generate()} value={Id}>
                  {`${Name} (${RemainingCycle} Cycle(s) in queue)`}
                </option>
              )
            })}
        </select>
      </div>
    </div>
  )
}

export default SelectMachine
