import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import s from 'shortid'
import Modal from 'components/Atoms/Modal'
import { actions as resetActions } from 'state/slices/reset'
import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'
import { actions as activitiesActions } from 'state/slices/activities'

import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'

import { actions as optimizationsCyclesProgressActions } from 'state/slices/optimizationsCyclesProgress'


import styles from './ResetModal.module.scss'

const ResetModal = ({ isOpen, onClose, data }) => {
    const { ProcessCycle, Id } = data
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(0)
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
    const [isRemainingCycleBigger, setIsRemainingCycleBigger] = useState(false)
    const [currentTargetSelected, setCurrentTargetSelected] = useState(null)

    const { preunrelease } = useSelector(state => ({
        preunrelease: state.reset.preunrelease.response,
        machineIdCycleList:
            state.machineCycleList.machineId,
        machineCycleListSkipCount:
            state.machineCycleList.skipCount,
    }))

    useEffect(() => {
        dispatch(resetActions.preunrelease(Id))
        return () => dispatch(resetActions.clear())
    }, [dispatch])

    const { RemainingCycle } = preunrelease || {}

    const onClickSubmit = async () => {
        setIsLoading(true)
        const data = {
            RemainingCycle,
            isRemainingCycleBigger,
            currentTargetSelected,
            Id,
            LotNumber: currentTargetSelected
        }
        await dispatch(resetActions.unrelease(data))
        setIsLoading(false)
        await dispatch(dashboardSummaryActions.get())
        await dispatch(activitiesActions.get())

        const payloadCicleData = {
            priority: 1,
            status: 10,
            skipCount: 0,
            maxResultCount: 5
        }
        await dispatch(optimizationsPressMachinesActions.get(payloadCicleData))
        const payloadCycleProgress = {
            priority: null,
            status: 20,
            skipCount: 0,
            maxResultCount: 5
        }
        await dispatch(optimizationsCyclesProgressActions.get(payloadCycleProgress))
        onClose()
    }

    const onChangeLotNumber = (data) => {
        Array.isArray(preunrelease?.LotNumbers) && preunrelease.LotNumbers.length > 0 && preunrelease.LotNumbers.map((item) => {
            if (item.LotNumber === Number(data.currentTarget.value)) {
                if (item.RemainingCycle > 0) {
                    setIsRemainingCycleBigger(true);
                } else {
                    setIsRemainingCycleBigger(false);
                }
            } else {
                if (preunrelease.RemainingCycle > 0) {
                    setIsRemainingCycleBigger(true);
                }
                else {
                    setIsRemainingCycleBigger(false);
                }
            }
        })
        setCurrentTargetSelected(data.currentTarget.value)
    }

    return (
        <>
            <Modal
                haveClose
                isLarge
                isOpen={isOpen}
                onClose={onClose}
                title='Reset Cycle'
            >
                <section className={cx('modal-card-body', styles.modalCard)}>
                    <div className={cx(styles.customContainer)}>
                        <h3>{`Press Cycle: ${ProcessCycle}`}</h3>
                        <h3 className={cx(styles.textreset)}>
                            Please select to which Lot Number you would like to Reset: <span>*</span>
                        </h3>
                        <div className='select-movecycle control dropdown'
                        // ref={ref}
                        >
                            <div className='select'>
                                <select className='is-rounded' onChange={(data) => onChangeLotNumber(data)} value={currentTargetSelected}>
                                    <option value={0} selected>
                                        All
                  </option>
                                    {Array.isArray(preunrelease?.LotNumbers) &&
                                        preunrelease?.LotNumbers.length > 0 &&
                                        preunrelease?.LotNumbers.map(item => {
                                            const { LotNumber } = item
                                            return (
                                                <option key={s.generate()} value={LotNumber}>
                                                    {LotNumber}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className={cx('modal-card-foot', styles.rightButton)}>
                    <div className={cx("buttons", styles.rightBtn)}>
                        <button
                            className={cx('button is-success', {
                                'is-loading': isLoading
                            })}
                            onClick={() => setIsOpenConfirmModal(true)}
                        >
                            Reset
          </button>
                    </div>
                </footer>
            </Modal>
            <Modal
                haveClose
                isLarge
                isOpen={isOpenConfirmModal}
                onClose={() => setIsOpenConfirmModal(false)}
                title='Reset Cycle'
            >
                <section className={cx('modal-card-body', styles.modalCard)}>
                    <div className={cx(styles.customContainer)}>
                        <h3 className={cx(styles.textreset)}>
                            {
                                (currentTargetSelected && currentTargetSelected != 0) ?
                                    `Are you sure you want to reset Lot Number ${currentTargetSelected}?`
                                    :
                                    'Are you sure you want to reset this cycle?'
                            }
                        </h3>
                        {
                            (isRemainingCycleBigger || (currentTargetSelected && currentTargetSelected == 0 && RemainingCycle > 0) || (!currentTargetSelected && RemainingCycle > 0)) ?
                                <h3>
                                    Total related cycle = {RemainingCycle} cycles. Resetting of
                                          this {(currentTargetSelected && currentTargetSelected != 0) ? 'lot number' : 'cycle'} will effects the other related cycle(s)
                                        </h3>
                                :
                                <></>
                        }
                    </div>
                </section>
                <footer className={cx('modal-card-foot', styles.rightButton)}>
                    <div className={cx("buttons", styles.rightBtn)}>
                        <button className="button is-white" type="button" onClick={() => setIsOpenConfirmModal(false)}>No</button>
                        <button
                            className={cx('button is-success', {
                                'is-loading': isLoading
                            })}
                            onClick={() => onClickSubmit()}
                        >
                            Yes
          </button>
                    </div>
                </footer>
            </Modal>
        </>
    )
}

export default ResetModal
