import React from 'react'
import { bool, func, node } from 'prop-types'
import cx from 'classnames'

import styles from './machineItem.module.scss'

const MachineItem = ({ children, isActive, onClick, remainingCycle }) => (
  <div className='column is-4' onClick={onClick}>
    <div
      className={cx(
        'info card',
        isActive && styles.isActive,
        remainingCycle >= 30 && styles.isBordered
      )}
    >
      {children}
    </div>
  </div>
)

MachineItem.defaultProps = {
  children: () => {},
  isActive: false,
  onChange: () => {}
}

MachineItem.propTypes = {
  children: node,
  isActive: bool,
  onChange: func
}

export default MachineItem
