import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import ReleaseForm from 'components/Organisms/ReleaseForm'
import { actions as machineActions } from 'state/slices/machines'
import { actions as releaseActions } from 'state/slices/release'
import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'
import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'
import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'
import { actions as optimizationsCyclesProgressActions } from 'state/slices/optimizationsCyclesProgress'
import { actions as machineCycleListActions } from 'state/slices/machineCycleList'

import styles from './ReleaseModal.module.scss'

const ReleaseModal = ({ isOpen, onClose, data, searchKeyword, priority }) => {
  const { ProcessCycle, Id } = data
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)
  const [isPrompModalOpen, setIsPrompModalOpen] = useState(false)
  const [isSelectAllCycles, setIsSelectAllCycles] = useState(false)
  const [machineName, setMachineName] = useState('')
  const [machineId, setMachineId] = useState(null)
  const [maxResultCount] = useState(5)

  const { preRelease, responseMachines, machineListId, skipCountMachineListId } = useSelector(state => ({
    preRelease: state.release.preRelease.response,
    release: state.release,
    loadingRelease: state.release.loading,
    responseDashboardSummary: state.dashboardSummary.response,
    responseMachines: state.machines.response.Items,
    isLoading: state.dashboardSummary.loading,
    machineListId: state.machineCycleList.machineId,
    skipCountMachineListId: state.machineCycleList.skipCount,
  }))
  useEffect(() => {
    if (!preRelease) {
      onClose()
    }
  }, [dispatch])

  const { RemainingCycle, Cycles } = preRelease || {}
  let lotNumber = ''
  Array.isArray(Cycles) &&
    Cycles.map(item => {
      lotNumber =
        Array.isArray(item.Cycle.Books) && item.Cycle.Books[0].LotNumber
    })
  const onCloseModalPromp = () => {
    setIsPrompModalOpen(false)
  }
  const confirmRelease = () => {
    setIsPrompModalOpen(true)
  }
  const handleInputChangeCheckbox = () => {
    setIsSelectAllCycles(!isSelectAllCycles)
  }

  const getInfoMachine = (Name, Id) => {
    setMachineName(Name)
    setMachineId(Id)
  }

  const nameOfMachine =
    machineName === ''
      ? responseMachines && responseMachines[0] && responseMachines[0].Name
      : machineName

  const onClickSubmit = async () => {
    const IdMachine = responseMachines[0].Id
    setIsLoading(true)

    let values = {}
    if (isSelectAllCycles) {
      values = {
        nameOfMachine: nameOfMachine,
        RemainingCycle: RemainingCycle,
        ProcessCycle: ProcessCycle,
        lotNumber: lotNumber,
        isSelectAllCycles: isSelectAllCycles,
        Cycles: {
          PressCycleId: Id,
          MachineId: machineId ? machineId : IdMachine,
          ApplyToAll: true
        }
      }
    } else {
      values = {
        nameOfMachine: nameOfMachine,
        RemainingCycle: RemainingCycle,
        ProcessCycle: ProcessCycle,
        lotNumber: lotNumber,
        isSelectAllCycles: isSelectAllCycles,
        Cycles: {
          PressCycleId: Id,
          MachineId: machineId ? machineId : IdMachine,
          ApplyToAll: false
        }
      }
    }
    await dispatch(releaseActions.release(values))
    dispatch(dashboardSummaryActions.get())
    const payloadOptimizationsPressMachinesActions = {
      status: 10,
      skipCount: 0,
      maxResultCount: maxResultCount,
      searchKeyword: searchKeyword,
      priority
    }
    dispatch(
      optimizationsPressMachinesActions.get(
        payloadOptimizationsPressMachinesActions
      )
    )

    const payloadPrescycle = {
      statuses: [20, 30],
      skipCount: 0,
      maxResultCount: maxResultCount
    }
    dispatch(optimizationsPressCycleActions.get(payloadPrescycle))

    const payloadCyclesProgress = {
      priority: null,
      statuses: [20, 30],
      skipCount: 0,
      maxResultCount: 5
    }
    dispatch(
      optimizationsCyclesProgressActions.get(payloadCyclesProgress)
    )
    fetchMachine()
    const payloadMachineListId = {
      skipCount: skipCountMachineListId,
      maxResultCount: 5,
      machineId: machineListId ? machineListId : responseMachines[0].Id
    }

    dispatch(machineCycleListActions.get(payloadMachineListId))

    setIsLoading(false)
    setIsPrompModalOpen(false)
    onClose()
  }

  const fetchMachine = () => {
    console.log("hihi")
    const payload = {
      skipCount: 0
    }
    dispatch(machineActions.get(payload))
  }

  console.log("joko machineListId", machineListId)
  return (
    <>
      <Modal
        haveClose
        isLarge
        isOpen={isOpen}
        onClose={onClose}
        title={`Execute Cycle ${ProcessCycle}`}
      >
        <section className={cx('modal-card-body', styles.modalCard)}>
          <div className={cx(styles.customContainer)}>
            <h3>Please select your machine :</h3>
            <ReleaseForm onClick={(Name, Id) => getInfoMachine(Name, Id)} />
          </div>
        </section>
        <footer className={cx('modal-card-foot', styles.rightButton)}>
          <button
            className='button is-success'
            disabled={!preRelease}
            onClick={() => confirmRelease()}
          >
            Execute
          </button>
        </footer>
      </Modal>
      <Modal
        isOpen={isPrompModalOpen}
        onClose={onCloseModalPromp}
        title='Execute Cycle Confirmation'
      >
        <section className={cx('modal-card-body', styles.modalCard)}>
          {RemainingCycle > 0 && (
            <div className={cx(styles.customContainer)}>
              <h3>
                Are you sure you want to execute this cycle to{' '}
                {`${nameOfMachine}`}? <div />
                <input
                  name='isSelectAllCycles'
                  type='checkbox'
                  checked={isSelectAllCycles}
                  onChange={() => handleInputChangeCheckbox()}
                />{' '}
                <span style={{ fontStyle: 'italic' }}>
                  Apply to all related Cycle. Please note that all related cycle
                  will be executed to the same machine.
                </span>
                <div style={{ marginTop: 10, fontStyle: 'italic' }}>
                  Total remaining cycle(s) for {lotNumber} = {RemainingCycle}
                </div>
              </h3>
            </div>
          )}
          {RemainingCycle === 0 && (
            <div className={cx(styles.customContainer)}>
              <h3>
                Are you sure you want to execute this cycle to {nameOfMachine} ?
              </h3>
            </div>
          )}
        </section>
        <footer className={cx('modal-card-foot', styles.rightButton)}>
          <button
            className={cx('button is-info', {
              'is-loading': isLoading
            })}
            onClick={() => onClickSubmit()}
          >
            Yes
          </button>
          <button
            className='button is-light'
            disabled={!preRelease}
            onClick={() => setIsPrompModalOpen(false)}
          >
            No
          </button>
        </footer>
      </Modal>
    </>
  )
}

export default ReleaseModal
