import { createSlice } from '@reduxjs/toolkit';
import { get } from 'state/thunks/reportFilterMachine';

const initialState = {
    response: [],
    loading: false,
    success: false,
    error: null
}

const reportFilterMachineSlice = createSlice({
    name: 'reportFilterMachine',
    initialState,
    reducers: {
        clear: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(get.pending, state => {
            state.loading = true
        })

        builder.addCase(get.fulfilled, (state, action) => {
            state.response = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(get.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })
    }
})

export const actions = {
    ...reportFilterMachineSlice.actions,
    get
}

export const reducer = reportFilterMachineSlice.reducer;
