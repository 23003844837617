import React from 'react'
import Tabs, { MenuContext } from 'components/Atoms/TabsButtons'
import CycleItem from 'components/Organisms/CycleItem'

const TabsPressCyrcles = ({ onChange, dataTable, tabs }) => {
  return (
    <>
      <Tabs tabs={tabs} onChange={onChange}>
        <MenuContext.Consumer>
          {isActiveTab => {
            return (
              <div>
                <CycleItem
                  data={dataTable[isActiveTab]}
                  readyInfo={dataTable}
                />
              </div>
            )
          }}
        </MenuContext.Consumer>
      </Tabs>
    </>
  )
}

export default TabsPressCyrcles
