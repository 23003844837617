import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as occupancyReportActions } from 'state/slices/occupancyReport';
import { actions as reportYearActions } from 'state/slices/reportYear';
import { actions as reportFilterMachineActions } from 'state/slices/reportFilterAllMachine';
import ReactApexChart from 'react-apexcharts';
import FilterYearDropdown from 'components/Atoms/FilterYearDropdown';
import FilterPressTypeDropdown from 'components/Atoms/FilterPressTypeDropdown';
import FilterReportDropdown from 'components/Atoms/FilterReportDropdown';
import MultiSelectCheckbox from 'components/Atoms/MultiSelectCheckbox';
import Loading from 'components/Atoms/Loading';
import { DisplayReport } from 'constants/occupancyReport';
import { PressType } from 'constants/throughputReport';
import './OccupancyChart.scss';

const OccupancyChart = () => {
    const dispatch = useDispatch();
    const defaultYear = new Date().getFullYear();
    const [currentLoading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(defaultYear);
    const [currentPressType, setCurrentPressType] = useState(99);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [currentDisplayReport, setCurrentDisplay] = useState('Weekly');

    const { years } = useSelector(function (state) {
        return {
            years: state.reportYear.response
        };
    });

    const { machines } = useSelector(state => ({
        machines: state.reportFilterAllMachine.response
    }));

    useEffect(() => {
        const fetchFirstData = async () => {
            setLoading(true);
            const payload = {
                year: currentYear,
                machines: [],
                pressType: currentPressType
            };
            await dispatch(occupancyReportActions.getWeekly(payload))
            setLoading(false);
        };
        fetchFirstData();

        return () => dispatch(occupancyReportActions.clear());
    }, []);

    useEffect(() => {
        setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
    }, [machines])

    useEffect(() => {
        const payload = {
            year: currentYear
        }
        dispatch(reportYearActions.get(payload))
        return () => dispatch(reportYearActions.clear())
    }, []);

    useEffect(() => {
        dispatch(reportFilterMachineActions.get({}))
        return () => dispatch(reportFilterMachineActions.clear())
    }, []);

    const onChangeSelectYear = async e => {
        setLoading(true);
        const year = e.target.value;
        setCurrentYear(year);

        const payload = { year: year, machines: selectedMachines.map(i => i.value), pressType: currentPressType };
        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(occupancyReportActions.getMonthly(payload));
                break;
            case 'Weekly':
                await dispatch(occupancyReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    const onChangeSelectPressType = async e => {
        setLoading(true);
        const pressType = e.target.value;
        setCurrentPressType(pressType);

        const payload = { year: currentYear, machines: selectedMachines.map(i => i.value), pressType: pressType };
        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(occupancyReportActions.getMonthly(payload));
                break;
            case 'Weekly':
                await dispatch(occupancyReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    const onChangeDisplayReport = async e => {
        setLoading(true)

        const displayReport = e.target.value;
        const year = currentYear
        const payload = { year: year, machines: selectedMachines.map(i => i.value), pressType: currentPressType };

        setCurrentDisplay(displayReport);

        switch (displayReport) {
            case 'Monthly':
                await dispatch(occupancyReportActions.getMonthly(payload));
                break;

            case 'Weekly':
                await dispatch(occupancyReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    const onChangeMachineReport = async (value, event) => {
        setLoading(true);
        if (event.action === "select-option" && event.option.value === "*") {
            setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setSelectedMachines([]);
        } else if (event.action === "deselect-option") {
            setSelectedMachines(value.filter((o) => o.value !== "*"));
        }
        else if (value.length === machines.length) {
            setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
        }
        else {
            setSelectedMachines(value);
        }

        const selectedMachine = [];
        if (event.action === "select-option" && event.option.value === "*") {
        }
        else if (event.action === "deselect-option" && event.option.value === "*") {
        } else {
            value.map((data) => {
                selectedMachine.push(data.value)
            })
        }

        const payload = { year: currentYear, machines: selectedMachine, pressType: currentPressType };

        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(occupancyReportActions.getMonthly(payload));
                break;
            case 'Weekly':
                await dispatch(occupancyReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    const { seriesMonthly, optionsMonthly, seriesWeekly, optionsWeekly } = useSelector(function (state) {
        const year = currentYear.toString();
        const weeklyData = state.occupancyReport.weeklyData;
        return {
            seriesMonthly: state.occupancyReport.response,
            seriesWeekly: weeklyData,
            optionsMonthly: {
                colors:['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                chart: {
                    type: 'line',
                    stacked: false,
                    height: 480
                },
                markers: {
                    size: 5,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                      }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        curve: 'straight',
                        lineCap: 'butt',
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + "% cycles";
                            }
                            return y;
                        }
                    }
                },
                xaxis: {
                    categories: ['Oct ' + (year - 1), 'Nov ' + (year - 1), 'Dec ' + (year - 1), 'Jan ' + year, 'Feb ' + year, 'Mar ' + year, 'Apr ' + year, 'May ' + year, 'Jun ' + year, 'Jul ' + year, 'Aug ' + year, 'Sept ' + year],
                },
                yaxis: {
                    title: {
                        text: 'Occupancy Rate (%)',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    },
                    labels: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + "%";
                            }
                            return y;
                        }
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                        tools: {
                            download: false
                        }
                    }
                },
                legend: {
                    show: 'always',
                    showForSingleSeries: true
                }
            },
            optionsWeekly: {
                chart: {
                    type: 'line',
                    stacked: false,
                    height: 480
                },
                markers: {
                    size: 5,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                      }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                colors:['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        curve: 'straight',
                        lineCap: 'butt',
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + "%";
                            }
                            return y;
                        }
                    },
                    x: {
                        formatter: function (y, { w }) {
                            var category = w.globals.lastXAxis.categories[y - 1];
                            if (w.globals.lastXAxis.categories.length == 0) {
                                return "Wk-" + y;
                            }
                            return "Wk-" + category.split(";")[0] + " (" + category.split(";")[1] + ")";
                        }
                    }
                },
                xaxis: {
                    categories: weeklyData.length > 0 ? weeklyData[0].titles : "",
                    labels: {
                        formatter: function (value) {
                            if (typeof value === "undefined")
                                return "";
                            if (value == 0)
                                return "";
                            return "Wk-" + value.split(";")[0];
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Occupancy Rate (%)',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    },
                    labels: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + "%";
                            }
                            return y;
                        }
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                        tools: {
                            download: false
                        }
                    }
                },
                legend: {
                    show: 'always',
                    showForSingleSeries: true
                }
            }
        };
    });

    return (
        <div className='OccupancyChart'>
            <div className="is-pulled-left mt-3">
                <div className='card toolbox'>
                    <FilterReportDropdown
                        data={DisplayReport}
                        label='Display By'
                        value={currentDisplayReport}
                        placeholder="Report"
                        width='190px'
                        onChange={e => onChangeDisplayReport(e)}
                    />
                    <MultiSelectCheckbox
                        label='Select Machine'
                        options={machines}
                        getDropdownButtonLabel={e => getDropdownButtonLabel(e)}
                        onChange={onChangeMachineReport}
                        width={'190px'}
                        value={selectedMachines}
                        setState={setSelectedMachines}
                    />
                    <FilterPressTypeDropdown
                        data={PressType}
                        value={currentPressType}
                        placeholder="Select Press Type"
                        onChange={e => onChangeSelectPressType(e)}
                        isLoading={currentLoading}
                    />
                    <FilterYearDropdown
                        data={years}
                        value={currentYear}
                        placeholder="Select Year"
                        onChange={e => onChangeSelectYear(e)}
                        isLoading={currentLoading}
                    />
                </div>
            </div>
            <h4 className='is-size-2 has-text-weight-bold'>
                Machine Occupancy Rate (%)
            </h4>
            <div className='chart'>
                {currentLoading && <Loading color='#80808030' type='spin' />}
                {!currentLoading && currentDisplayReport === 'Monthly' && <ReactApexChart options={optionsMonthly} series={seriesMonthly} type="line" height={460} />}
                {seriesWeekly.length > 0 && !currentLoading && currentDisplayReport === 'Weekly' && <ReactApexChart options={optionsWeekly} series={seriesWeekly} type="line" height={460} />}
            </div>
        </div>
    )
}

export default OccupancyChart;
