import React, { useState, useEffect } from 'react'
import Viewers from 'components/Templates/Viewers'
import ViewerHeader from 'components/Organisms/ViewerHeader'
import ViewerMachines from 'components/Organisms/ViewerMachines'

import './Viewer.scss'

const Viewer = () => {
  const [height, setHeight] = useState('100%')

  const getHeight = () => {
    setHeight(window.innerHeight + 'px')
  }

  useEffect(() => {
    getHeight()
    window.addEventListener('resize', getHeight)
    return () => window.removeEventListener('resize', null)
  }, [])

  return (
    <Viewers>
          <div className='columns is-multiline viewer pt-5' style={{ height }}>
        <div className='column is-12'>
          <ViewerHeader />
          <ViewerMachines />
        </div>
      </div>
    </Viewers>
  )
}

export default Viewer
