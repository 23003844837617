import React from 'react'
import cx from 'classnames'
import s from 'shortid'

import './FilterReportDropdown.scss'

const FilterReportDropdown = ({ data, placeholder, label, onChange, width, ...props }) => {
    return (
        <div className="field is-horizontal toolbox-report">
            <div className="field-label is-normal">
                <label className="label">{label}: </label>
            </div>
            <div className="field-body">
                <div className="field is-narrow">
                    <div className="control">
                        <div className="select is-fullwidth is-small toolbox-report" style={{width: width}}>
                            <select {...props} className='is-rounded' onChange={onChange}>
                                <option disabled>
                                    {placeholder}
                                </option>
                                {Array.isArray(data) &&
                                    data.length > 0 &&
                                    data.map(item => {
                                        const { Id, Name } = item
                                        return (
                                            <option key={s.generate()} value={Id}>
                                                {Name}
                                            </option>
                                        )
                                    })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterReportDropdown
