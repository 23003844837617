import React from 'react'

import { ReactComponent as TotalImg } from 'assets/icons/button_stack.svg'
import './ColumnsInfo.scss'

const ColumnsInfo = ({ data }) => {
    const { TotalCycles, TotalCycleCompleted, TotalCycleReady, TotalCycleCompletedToday } = data
    return (
        <div className='columns is-multiline mb-3 columnsInfo'>
            <div className='column'>
                <div className='card'>
                    <div className='card-content'>
                        <p className='subtitle'>
                            <TotalImg className='icon1' fill='#ffffff' />
                            Available Cycles
                        </p>
                        <p className='title has-text-weight-bold'>{TotalCycleReady} Cycles</p>
                    </div>
                </div>
            </div>
            {/*<div className='column'>*/}
            {/*    <div className='card'>*/}
            {/*        <div className='card-content'>*/}
            {/*            <p className='subtitle'>*/}
            {/*                <TotalImg className='icon2' fill='#ffffff' />*/}
            {/*                Success Cycles*/}
            {/*            </p>*/}
            {/*            <p className='title has-text-weight-bold'>*/}
            {/*                {TotalCycleCompleted} Cycles*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='column'>
                <div className='card'>
                    <div className='card-content'>
                        <p className='subtitle'>
                            <TotalImg className='icon3' fill='#ffffff' />
                            Completed Cycles (Today)
                        </p>
                        <p className='title has-text-weight-bold'>
                            {TotalCycleCompletedToday} Cycles
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ColumnsInfo
