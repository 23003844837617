import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk('machines/get', async payload => {
  const response = await api.getMachines(payload)
  return response
})

export const post = createAsyncThunk('machines/post', async payload => {
  const response = await api.postMachine(payload)
  return response
})

export const put = createAsyncThunk('machines/put', async payload => {
  const response = await api.putMachine(payload)
  return response
})

export const destroy = createAsyncThunk('machines/destroy', async payload => {
  const response = await api.destroyMachine(payload)
  return response
})
