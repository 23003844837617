import React from 'react'
import './CardContent.scss'

const CardContent = ({ withImage, children }) => {
  return <>{children}</>
}
CardContent.defaultProps = {
  withImage: false
}

export default CardContent
