import qs from 'qs';
import { toastr } from 'react-redux-toastr';
import { getAccessToken } from 'utils';
import AppConst from '../libs/AppConst';

const URL = AppConst.API_URL;
const setOptions = restOptions => {
    const { headers, ...rest } = restOptions
    return {
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        ...rest
    }
}

export const login = async payload => {
    const options = {
        method: 'POST',
        body: JSON.stringify(payload)
    }

    const response = await window.fetch(`${URL}/token`, setOptions(options))
    const json = await response.json()

    if (!response.ok) {
        throw new Error(json.message)
    }

    return json
}

export const getDashboardSummary = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/summary`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const getOptimizationsPressMachines = async payload => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/presscycles?${queryString}`,
        setOptions(options)
    )
    const json = await res.json();
    if (!res.ok) {
        toastr.error('', json.Message)
        throw new Error(json.Message)
    }
    const data = {
        response: json
    }
    return data
}

export const getOptimizationsDataToBeUpdated = async payload => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/datatobeupdated?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const getMachines = async payload => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/machines?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        const data = {
            response: json
        }
        return data
    }
}

export const getOptimizationsMachines = async (payload, machineId) => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(
        `${URL}/optimizations/${machineId}?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const preRelease = async pressCycleId => {
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/${pressCycleId}/prerelease`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        const data = {
            response: json
        }
        return data
    }
}

export const release = async payload => {
    const {
        Cycles,
        nameOfMachine,
        RemainingCycle,
        ProcessCycle,
        lotNumber,
        isSelectAllCycles
    } = payload
    const options = {
        method: 'POST',
        body: JSON.stringify(Cycles),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const response = await window.fetch(
        `${URL}/optimizations/release/bulk`,
        setOptions(options)
    )

    if (!response.ok) {
        const json = await response.json()
        return toastr.error('', json.Details)
        // throw new Error(json.Details)
    } else {
        if (isSelectAllCycles) {
            toastr.success(
                '',
                `All cycles have been successfully executed to ${nameOfMachine}`
            )
        } else {
            if (RemainingCycle === 0) {
                toastr.success(
                    '',
                    `${ProcessCycle} cycle has been successfully executed to ${nameOfMachine}.`
                )
            } else {
                toastr.success(
                    '',
                    `${ProcessCycle} cycle has been successfully executed to ${nameOfMachine}. Please continue to execute the remaining cycles for ${lotNumber}`
                )
            }
        }
    }

    return true
}

export const getUsers = async payload => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/users?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const getRoles = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/users/roles`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const register = async payload => {
    const { Password, ConfirmPassword } = payload
    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    if (Password !== ConfirmPassword) {
        return toastr.error('', 'Password and Confirm Password don’t match.')
    }

    const response = await window.fetch(
        `${URL}/users/create`,
        setOptions(options)
    )
    if (!response.ok) {
        const json = await response.json()
        toastr.error('', json.Details)
    } else {
        toastr.success('', 'The user has successfully been created')
    }

    return true
}

export const edit = async payload => {
    const options = {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const response = await window.fetch(
        `${URL}/users/${payload.Id}/edit`,
        setOptions(options)
    )

    if (!response.ok) {
        const json = await response.json()
        toastr.error('', json.Details)
    } else {
        toastr.success('', 'The user has been edited')
    }

    return true
}

export const getMe = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/users/me?`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data
}

export const editme = async payload => {
    console.log('payload', payload)
    const { NewPassword, ConfirmPassword } = payload
    const options = {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    if (NewPassword !== ConfirmPassword) {
        return toastr.error('', 'New password and Confirm Password don’t match.')
    }

    const response = await window.fetch(
        `${URL}/users/${payload.Id}/changepassword`,
        setOptions(options)
    )
    if (!response.ok) {
        const json = await response.json()
        let error = ''
        if (json.Details === 'Incorrect password.') {
            error = 'The current password is incorrect'
        } else {
            error = json.Details
        }
        toastr.error('', error)
    } else {
        toastr.success('', 'The password has been updated.')
    }

    return true
}

export const getWip = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/configurations`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.Message)
        throw new Error(json.Message)
    } else {
        const data = {
            response: json
        }
        return data
    }
}

export const putWip = async payload => {
    const options = {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const response = await window.fetch(
        `${URL}/configurations/${payload.type}`,
        setOptions(options)
    )

    if (!response.ok) {
        const json = await response.json()
        toastr.error('', json.Details)
    } else {
        toastr.success(
            '',
            'WIP algorithm successfully changed. This will take effect on the next optimization'
        )
    }

    return true
}

export const getActivities = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/activities/pressoptimizations/last`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    } else {
        const data = {
            response: json
        }
        return data
    }
}

export const postMachine = async payload => {
    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const response = await window.fetch(`${URL}/machines`, setOptions(options))

    if (!response.ok) {
        const json = await response.json()
        toastr.error('', json.Message)
    } else {
        toastr.success('', `${payload.Name} has successfully created`)
    }

    return true
}

export const destroyMachine = async payload => {
    const { Name, Id } = payload
    const options = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(`${URL}/machines/${Id}`, setOptions(options))

    if (!res.ok) {
        const json = await res.json()
        return toastr.error('', json.Details)
    } else {
        return toastr.success('', `${Name} has successfully deleted.`)
    }
}

export const putMachine = async payload => {
    const options = {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(`${URL}/machines`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Details)
    } else {
        toastr.success('', `${payload.Name} has successfully edited.`)
    }
    const data = {
        response: json
    }
    return data
}

export const putCycleMachine = async payload => {
    const { PressCycleId } = payload
    const options = {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const response = await window.fetch(
        `${URL}/optimizations/${PressCycleId}/machine`,
        setOptions(options)
    )

    if (!response.ok) {
        const json = await response.json()
        toastr.error('', json.Details)
    } else {
        const json = await response.json()
        const { Machine } = json
        const { Name } = Machine
        toastr.success(
            '',
            `This cycle’s machine has successfully edited to ${Name}`
        )
    }

    return true
}

export const preUnRelease = async pressCycleId => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ pressCycleId }),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/${pressCycleId}/preunrelease`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        const data = {
            response: json
        }
        return data
    }
}

export const unRelease = async data => {
    const { Id, RemainingCycle, LotNumber, isRemainingCycleBigger, currentTargetSelected } = data
    const options = {
        method: 'POST',
        body: JSON.stringify({
            PressCycleId: Id,
            LotNumber
        }),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/unrelease`,
        setOptions(options)
    )

    if (!res.ok) {
        const json = await res.json()
        return toastr.error('', json.Message)
    } else {
        if ((currentTargetSelected && currentTargetSelected == 0 && RemainingCycle > 0) || (!currentTargetSelected && RemainingCycle > 0)) {
            toastr.success('', 'All cycles have been successfully reset.');
        }

        else if ((currentTargetSelected && currentTargetSelected == 0 && RemainingCycle == 0) || (!currentTargetSelected && RemainingCycle == 0)) {
            toastr.success('', 'This cycle has been successfully reset.');
        }

        else if (currentTargetSelected && currentTargetSelected != 0 && isRemainingCycleBigger) {
            toastr.success('', 'The Lot Number (' + currentTargetSelected + ') have been successfully reset.');
        }


        else if (currentTargetSelected && currentTargetSelected != 0 && !isRemainingCycleBigger) {
            toastr.success('', 'The Lot Number (' + currentTargetSelected + ') has been successfully reset.');
        }

        /*
        if (isRemainingCycleBigger || (currentTargetSelected && currentTargetSelected == 0 && RemainingCycle > 0) || (!currentTargetSelected && RemainingCycle > 0)) {
            toastr.success('', 'All cycles have been successfully reset.')
        } else {
            toastr.success('', 'This cycle has been successfully reset.')
        }*/
    }
}

export const getPotential = async payload => {
    const { pressCycleId, WorkOrderId } = payload
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/${pressCycleId}/move/${WorkOrderId}/potentialCycles`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.Message)
        throw new Error(json.Message)
    } else {
        return json
    }
}

export const move = async data => {
    const {
        cycle,
        DestinationPressCycleId,
        WorkOrderId,
        CreateAsNew,
        SourcePressCycleId,
        ReasonChangeId
    } = data
    const options = {
        method: 'POST',
        body: JSON.stringify({
            SourcePressCycleId,
            WorkOrderId,
            DestinationPressCycleId,
            CreateAsNew,
            ReasonChangeId
        }),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/optimizations/move`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        console.log('res', res)
        toastr.success('', json.Message)
    }
}

export const getCyclereasons = async () => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/cyclereasons`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }

    console.log('res getCyclereasons', json)
    return json.Items
}

export const editMaxPanelPerBook = async payload => {
    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(
        `${URL}/optimizations/editmaxpanelperbook`,
        setOptions(options)
    )

    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        const { Message } = json
        toastr.success('', Message)
    }
}

export const editNoOfBookToBeutilized = async payload => {
    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(
        `${URL}/optimizations/editnoofbooktobeutilized`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        return toastr.error('', json.Message)
    } else {
        console.log('json', json)
        const { Message } = json
        toastr.success('', Message)
    }
}

export const getActivity = async payload => {
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(
        `${URL}/activities/pressoptimizations/all?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.Message)
        throw new Error(json.Message)
    }
    const data = {
        response: json
    }
    return data
}

export const downloadActivity = async payload => {
    console.log('payload download', payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
        // body: JSON.stringify(payload)
    }
    const res = await window.fetch(
        `${URL}/activities/pressoptimizations/download?fileName=${payload}`,
        setOptions(options)
    )

    // const errorCheck = await res.json()
    // if (!errorCheck.ok) {
    //   toastr.error('', errorCheck.Message)
    //   throw new Error(errorCheck.Message)
    // } else {
    const json = await res
    json.blob().then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'test.txt'
        a.click()
    })
    // }
}

export const getInqueueByMachineId = async (payload) => {
    const { machineId } = payload
    const queryString = qs.stringify(payload)
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }

    const res = await window.fetch(
        `${URL}/optimizations/${machineId}/inqueue?${queryString}`,
        setOptions(options)
    )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    const data = {
        response: json
    }
    return data;
}


export const getThroughputMonthlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/ThroughputMonthlyReport?year=${payload.year}&itemGroup=${payload.itemNumber}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getThroughputYearlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/ThroughputYearlyReport?year=${payload.year}&itemGroup=${payload.itemNumber}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getThroughputQuarterlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/ThroughputQuarterlyReport?year=${payload.year}&itemGroup=${payload.itemNumber}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getThroughputDailyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/ThroughputDailyReport?dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&itemGroup=${payload.itemNumber}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getThroughputWeeklyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/ThroughputWeeklyReport?year=${payload.year}&itemGroup=${payload.itemNumber}&pressType=${payload.pressType}` , setOptions(options) )
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}


export const getOccupancyMonthlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/OccupancyMonthlyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getOccupancyWeeklyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/OccupancyWeeklyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getPressCycleMonthlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/PressCycleMonthlyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getPressCycleQuarterlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/PressCycleQuarterlyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getPressCycleYearlyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/PressCycleYearlyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getPressCycleWeeklyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/PressCycleWeeklyReport?year=${payload.year}&machines=${payload.machines}&pressType=${payload.pressType}`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getPressCycleDailyReport = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/PressCycleDailyReport?dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&machines=${payload.machines}&pressType=${payload.pressType}` , setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getReportYears = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/GetYears`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}

export const getReportFilterMachines = async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    }
    const res = await window.fetch(`${URL}/reports/GetMachines`, setOptions(options))
    const json = await res.json()
    if (!res.ok) {
        toastr.error('', json.message)
        throw new Error(json.message)
    }
    return json;
}