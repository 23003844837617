import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import * as api from 'connections/restApi'

export const preunrelease = createAsyncThunk('preUnRelease/post', payload => {
  const response = api.preUnRelease(payload)
  return response
})

export const unrelease = createAsyncThunk(
  'unrelease/post',
  async (params, { rejectWithValue }) => {
    try {
      await api.unRelease(params)
      return { success: true }
    } catch (error) {
      console.log('error', error)
      toastr.error('', error.Message)
      return rejectWithValue(error.Message)
    }
  }
)
