import React, { useState } from 'react'
import './InputGroup.scss'

const InputGroup = ({ placeholder, onClick, onChange, valueName }) => {
  // const [value, setValue] = useState('')
  // const onChange = e => {
  //   setValue(e.currentTarget.value)
  // }
  return (
    <div className='field has-addons'>
      <div className='control input-field'>
        <input
          className='input'
          type='text'
          value={valueName}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
      <div className='control'>
        <a className='button is-info' onClick={() => onClick(valueName)}>
          +
        </a>
      </div>
    </div>
  )
}

export default InputGroup
