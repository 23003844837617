import React from 'react'
import { bool, func, node } from 'prop-types'
import cx from 'classnames'

const TabItemButton = ({ children, isActive, onChange }) => (
  <li onClick={onChange}>
    <button
      className={cx(
        isActive && 'is-active is-link',
        'button is-white',
        'is-medium'
      )}
    >
      {children}
    </button>
  </li>
)

TabItemButton.defaultProps = {
  children: () => {},
  isActive: false,
  onChange: () => {}
}

TabItemButton.propTypes = {
  children: node,
  isActive: bool,
  onChange: func
}

export default TabItemButton
