import React from 'react'
import cx from 'classnames'
import s from 'shortid'

import './FilterDropdown.scss'

const FilterDropdown = ({ data, placeholder, onChange, isLoading, ...props }) => {
  return (
    // <p
    //   className={cx(
    //     'control search-bar'
    //   )}
    // >
      <div className="field is-horizontal filterdropdown">
        <div className="field-label is-normal">
          <label className="label">View By: </label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className="select is-fullwidth">
                <select {...props} className='is-rounded' onChange={onChange}>
                  <option disabled>
                    {placeholder}
                  </option>
                  <option value={null} selected>
                    All Machine
                  </option>
                  {Array.isArray(data) &&
                    data.length > 0 &&
                    data.map(item => {
                      const { Id, Name } = item
                      return (
                        <option key={s.generate()} value={Id}>
                          {Name}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default FilterDropdown
