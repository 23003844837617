// @flow
import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import s from 'shortid'

import './Dropdown.scss'

const Dropdown = ({
  field,
  form,
  data,
  className,
  placeholder,
  label,
  ...props
}) => {
  const { name } = field
  const { touched, errors } = form

  const isError = {
    'is-danger': get(touched, name, null) && get(errors, name, null)
  }

  return (
    <div className='control dropdown'>
      <div className={cx('select', isError, className)}>
        <select {...props} {...field} className='is-rounded'>
          <option value='' disabled>
            {placeholder}
          </option>
          {data.map(item => (
            <option key={s.generate()} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

Dropdown.defaultProps = {
  className: ''
}

export default Dropdown
