import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from 'components/Templates/Dashboard'
import Header from 'components/Atoms/Header'

import ActivityTable from 'components/Molecules/ActivityTable'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'

import { HeadTableActivity } from 'constants/cycleData'
import { actions as activityActions } from 'state/slices/activity'

import './Activity.scss'

const Activity = () => {
    const dispatch = useDispatch()
    const [maxResultCount] = useState(5)
    const [page, setPage] = useState(1)
    const [skipCount] = useState(1)

    useEffect(() => {
        const payload = {
            skipCount: 0,
            maxResultCount: maxResultCount,
            sort: '',
            sortBy: 0
        }
        dispatch(activityActions.get(payload))
        return () => dispatch(activityActions.clear())
    }, [dispatch])

    const { activity, totalCount, me, isLoading } = useSelector(state => ({
        activity: state.activity.response.Items,
        totalCount: state.activity.response.TotalCount,
        me: state.users.me,
        isLoading: state.activity.loading
    }))

    const fetchData = async page => {
        const skipCount = (page - 1) * maxResultCount
        const payload = {
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            sort: '',
            sortBy: 0
        }
        await dispatch(activityActions.get(payload))
        setPage(page)
        return () => dispatch(activityActions.clear())
    }

    const handleChangePage = async e => {
        const page = e.target.value !== '' ? parseInt(e.target.value) : 0
        setPage(page)
        if (page !== 0 && e.key === 'Enter') {
            fetchData(page)
        }
    }

    const onClickPrevNext = async page => {
        fetchData(page)
    }

    const actionDownload = async file => {
        await dispatch(activityActions.download(file))
    }

    const { RoleName } = me
    const isHaveAccess = RoleName === 'Administrator' || RoleName === 'SuperUser'

    return (
        <Dashboard>
            <div className='columns is-multiline homepage pt-5 activity'>
                <div className='column is-12'>
                    <Header title='Activity' />
                    {isHaveAccess && (
                        <>
                            <ActivityTable
                                data={activity}
                                headData={HeadTableActivity}
                                actionDownload={file => actionDownload(file)}
                            />
                            <TableFooterPagination
                                data={totalCount}
                                page={parseInt(page)}
                                totalData={activity.length}
                                maxResultCount={maxResultCount}
                                skipCount={skipCount}
                                handleChangePage={handleChangePage}
                                onClickPrevNext={page => onClickPrevNext(page)}
                                classNames="margin-bottom-50px"
                            />
                        </>
                    )}
                    {!isLoading && !isHaveAccess && (
                        <div>
                            <p>
                                Sorry you do not have access to open this page, please contact
                                the administrator
              </p>
                        </div>
                    )}
                </div>
            </div>
        </Dashboard>
    )
}

export default Activity
