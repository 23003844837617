import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import * as api from 'connections/restApi'

export const editmaxpanelperbook = createAsyncThunk(
  'editmaxpanelperbook/post',
  async (params, { rejectWithValue }) => {
    try {
      await api.editMaxPanelPerBook(params)
      return { success: true }
    } catch (error) {
      console.log('error', error)
      toastr.error('', error.Message)
      return rejectWithValue(error.Message)
    }
  }
)
