import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/roles'

const initialState = {
  data: [],
  loading: false,
  error: null
}

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clear: state => initialState
  },
  extraReducers: {
    [get.pending]: state => {
      state.loading = true
    },
    [get.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [get.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const actions = {
  ...rolesSlice.actions,
  get
}

export const reducer = rolesSlice.reducer
