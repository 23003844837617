import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import Table from 'components/Atoms/Table'
import Header from 'components/Atoms/Header'
import ResetModal from 'components/Molecules/ResetModal'
import DetailTableCycleItemDetailPressCycles from 'components/Organisms/DetailTableCycleItemDetailPressCycles'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'
import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'
import './DetailListPressCycles.scss'

const DetailListPressCycles = ({ isHaveAccess }) => {
  const dispatch = useDispatch()
  const [maxResultCount] = useState(5)
  const [page, setPage] = useState(1)
  const [skipCount, setSkipCount] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [dateToBeReset, setDateToBeReset] = useState('')
  const { responsePressCycles, totalCount, isLoading } = useSelector(state => ({
    responsePressCycles: state.optimizationsPressCycle.response.Items,
    isLoading: state.optimizationsPressCycle.loading,
    totalCount: state.optimizationsPressCycle.response.TotalCount
  }))
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    fetchOptimization(1)
    return () => dispatch(optimizationsPressCycleActions.clear())
  }, [dispatch])

  const tabs = []
  responsePressCycles.map(item => {
    return tabs.push({
      label: item.ProcessCycle
    })
  })

  const fetchOptimization = async pageA => {
    const skipCount = (pageA - 1) * maxResultCount
    const payload = {
      statuses: [20, 30],
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      searchKeyword: searchKeyword
    }
    await dispatch(optimizationsPressCycleActions.get(payload))
    setSkipCount(skipCount)
    setPage(pageA)
  }

  const handleChangePage = async e => {
    const pageA = e.target.value !== '' ? parseInt(e.target.value) : 0
    setPage(pageA)
    if (pageA !== 0 && e.key === 'Enter') {
      fetchOptimization(pageA)
    }
  }

  const onClickPrevNext = async pageA => {
    setPage(pageA)
    await fetchOptimization(pageA)
    window.scrollTo(0, document.body.scrollHeight)
  }

  const onChangeSearchKeyword = e => {
    setSearchKeyword(e.target.value)
    const skipCount = (1 - 1) * maxResultCount
    const payload = {
      statuses: [20, 30],
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      searchKeyword: e.target.value
    }
    debouncedRefreshList(payload)
  }

  const debouncedRefreshList = useMemo(
    () =>
      debounce(args => {
        setPage(1)
        return dispatch(optimizationsPressCycleActions.get(args))
      }, 1500),
    [dispatch]
  )

  const onCLickReset = e => {
    setIsResetModalOpen(true)
    setDateToBeReset(e)
    fetchOptimization(page)
  }
  const handleClose = () => {
    setIsResetModalOpen(false)
    fetchOptimization(page)
  }
  return (
    <>
      <Header
        title='Press Cycles'
        isHaveSearchBar
        isLoading={isLoading}
        onChange={e => onChangeSearchKeyword(e)}
      />
      <div className='columns is-multiline mb-3 detailPressCycles'>
        <div className='column is-12'>
          <div className='card card-custom'>
            <div className='card-content'>
              <Table>
                <DetailTableCycleItemDetailPressCycles
                  data={responsePressCycles}
                  isHaveAccess={isHaveAccess}
                  isShowEdit={false}
                  onCLickReset={e => onCLickReset(e)}
                />
              </Table>
            </div>
            <TableFooterPagination
              data={totalCount}
              page={parseInt(page)}
              totalData={responsePressCycles.length}
              maxResultCount={maxResultCount}
              skipCount={skipCount}
              handleChangePage={handleChangePage}
              onClickPrevNext={page => onClickPrevNext(page)}
            />
          </div>
        </div>
      </div>
      {isResetModalOpen && (
        <ResetModal
          isOpen={isResetModalOpen}
          onClose={handleClose}
          data={dateToBeReset}
        />
      )}
    </>
  )
}

export default DetailListPressCycles
