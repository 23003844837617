import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/machineCycleListData'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  machineId: '',
  skipCount: 0,
  pageMachine: 1,
  edit: {},
  loading: false,
  success: false,
  isOpenExecute : false,
  error: null
}

const machineCycleListSlice = createSlice({
  name: 'machineCycleList',
  initialState,
  reducers: {
    clear: () => initialState,
    setMachineId: (state, action)=>{
      state.machineId = action.payload
    },
    setSkipCount : (state, action) => {
      state.skipCount = action.payload
    },
    setOpenExecute : (state, action) => {
      state.isOpenExecute = action.payload
    },
    setPageMachine : (state, action) => {
      state.pageMachine = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...machineCycleListSlice.actions,
  get
}

export const reducer = machineCycleListSlice.reducer
