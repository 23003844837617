import React, { useState } from 'react'

import Table from 'components/Atoms/Table'
import TableHead from 'components/Atoms/TableHead'

import TableCycleItemDataToBeUpdated from 'components/Organisms/TableCycleItemDataToBeUpdated'
import { HeadTableDataToBeUpdated } from 'constants/cycleData'

import './TableCycleDataToBeUpdated.scss'

const TableCycleDataToBeUpdated = ({ tHeadData, isLoading, data }) => {
  const [isCollapseFor, setCollapseFor] = useState(null)
  const onClickCollapse = e => {
    if (isCollapseFor === e) {
      setCollapseFor(e)
    }
    setCollapseFor(e)
  }
  return (
    <div className='data-to-be-updated'>
      <Table>
        <TableHead tHeadData={HeadTableDataToBeUpdated} />
        <TableCycleItemDataToBeUpdated
          data={data}
          onClick={i => onClickCollapse(i)}
          isCollapseFor={isCollapseFor}
        />
      </Table>
    </div>
  )
}

export default TableCycleDataToBeUpdated
