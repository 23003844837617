export const ItemGroups = [
    {label: '675', value: '675'},
    {label: '680', value: '680'},
    { label: '690', value: '690' },
    { label: 'All Item Number', value: 'All Item Number' },
];

export const DisplayReport = [
    {Id: 'Yearly', Name: 'Yearly'},
    {Id: 'Quarterly', Name: 'Quarterly'},
    {Id: 'Monthly', Name: 'Monthly'},
    {Id: 'Weekly', Name: 'Weekly'},
    {Id: 'Daily', Name: 'Daily'}
];

export const PressType = [
    {Id: '1', Name: 'Pin Lam'},
    {Id: '2', Name: 'Mass Lam'},
    { Id: '99', Name: 'All Press Type' },
]
