import React from 'react'
import cx from 'classnames'
import './ViewerCycleItem.scss'

const ViewerCycleItem = ({ data }) => {
    const { Books } = data || []
    const arrayBook = []
    for (var i = 0; i < 10; i++) {
        arrayBook.push({
            number: i + 1,
            quantity: Books && Books[i] ? Books[i].Quantity : 0,
            ToolNumber: Books && Books[i] ? Books[i].ToolNumber : '',
            LocationString: Books && Books[i] ? Books[i].LocationString : '',
            LotNumber: Books && Books[i] ? Books[i].LotNumber : '',
            PrepregMaterial: Books && Books[i] ? Books[i].PrepregMaterial : '',
            lenght: Books && Books[i] ? 1 : 0,
            Color: Books && Books[i] ? Books[i].Color : '',
            isGray: data && data.UseableBook > i,
            IsEdittedWhenReleased: Books && Books[i] ? Books[i].IsEdittedWhenReleased : false
        })
    }
    console.log("Books.lenght", Books.length)
    return (
        <>
            <div className='columns is-multiline has-background-white viewerCycleItem'>
                {arrayBook.map((item, i) => {
                    const {
                        number,
                        quantity,
                        ToolNumber,
                        LocationString,
                        LotNumber,
                        PrepregMaterial,
                        lenght,
                        Color,
                        IsEdittedWhenReleased,
                        isGray
                    } = item
                    return (
                        <div
                            className={cx(lenght === 0 && 'nothingBook', 'column p-3 box') + (IsEdittedWhenReleased ? ' is-background-danger' : '')}
                            style={{
                                backgroundColor: Books && Books.length > 0 && !isGray && 'gray'
                            }}
                            key={i}
                        >
                            <div className='lh0'>
                                <span
                                    className={cx('numberbullet')}
                                    style={{ backgroundColor: quantity === 0 ? 'black' : Color }}
                                >
                                    {number}
                                </span>
                                {quantity !== 0 && (
                                    <span className='numbercycles'>{ToolNumber}</span>
                                )}
                            </div>
                            {quantity !== 0 && (
                                <div className="relative">
                                    <div className='has-text-left mt15 block'>
                                        <div className=' is-size-2-top'>{LotNumber}</div>
                                    </div>

                                    <div className='has-text-right absolute right-item' style={{ float: 'right' }}>
                                        <div style={{ fontSize: 30, color: '#11141A' }}>
                                            <div className="inline-box">
                                                <span className="panels">pnls</span>
                                                <span className="qty">{quantity}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="columns is-multiline">
                                        <div className='column is-6 is-size-2'>{LocationString}</div>
                                        <div className='column is-6 is-size-2'>{PrepregMaterial}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default ViewerCycleItem
