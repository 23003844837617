import { createSlice } from '@reduxjs/toolkit'

import { get, create, edit, me, editme } from 'state/thunks/users'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  login: {},
  edit: {},
  me: {},
  loading: false,
  error: null
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clear: state => initialState
  },
  extraReducers: {
    [get.pending]: state => {
      state.loading = true
    },
    [get.fulfilled]: (state, action) => {
      state.response = action.payload.response
      state.loading = false
    },
    [get.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [create.pending]: state => {
      state.loading = true
    },
    [create.fulfilled]: (state, action) => {
      state.login = action.payload.response
      state.loading = false
    },
    [create.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [edit.pending]: state => {
      state.loading = true
    },
    [edit.fulfilled]: (state, action) => {
      state.edit = action.payload.response
      state.loading = false
    },
    [edit.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [me.pending]: state => {
      state.loading = true
    },
    [me.fulfilled]: (state, action) => {
      state.me = action.payload.response
      state.loading = false
    },
    [me.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [editme.pending]: state => {
      state.loading = true
    },
    [editme.fulfilled]: (state, action) => {
      state.edit = action.payload.response
      state.loading = false
    },
    [editme.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const actions = {
  ...usersSlice.actions,
  get,
  create,
  edit,
  me,
  editme
}

export const reducer = usersSlice.reducer
