import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAccessToken } from 'utils'

const PrivateRoute = ({ path, component, exact, ...props }) => {
  if (getAccessToken()) {
    return <Route {...props} exact={exact} path={path} component={component} />
  } else {
    return <Redirect to='/login' />
  }
}

PrivateRoute.defaultProps = {
  exact: false
}

export default PrivateRoute
