import React from 'react'
import s from 'shortid'
// import { useDetectClickOutside } from 'react-detect-click-outside'
import './SelectMoveCycle.scss'

const SelectMoveCycle = ({ data, onChange, value, onClickOutside }) => {
  // const ref = useDetectClickOutside({ onTriggered: onClickOutside })
  return (
    <div className='select-movecycle control dropdown' 
    // ref={ref}
    >
      <div className='select'>
        <select className='is-rounded' onChange={onChange} value={value}>
          <option value='' disabled>
            Please Select
          </option>
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map(item => {
              const { ProcessCycle, Id, RemainingBook, Status } = item
              return (
                <option key={s.generate()} value={Id}>
                      {`${ProcessCycle} (${RemainingBook} Remaining Slot)`} {Status == 20 ? ' - Executed' : ''}
                </option>
              )
            })}
          <option value='CreateAsNew'>Create New Cycle</option>
        </select>
      </div>
    </div>
  )
}

export default SelectMoveCycle
