import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'
import * as api from 'connections/restApi'

export const get = createAsyncThunk('users/get', async payload => {
  const response = await api.getUsers(payload)
  return response
})

export const me = createAsyncThunk('users/me', async () => {
  const response = await api.getMe()
  return response
})

export const create = createAsyncThunk(
  'users/create',
  async (params, { rejectWithValue }) => {
    try {
      const result = await api.register(params)
      return result
    } catch (error) {
      // toastr.error('', error.message)
      return rejectWithValue(error.message)
    }
  }
)

export const edit = createAsyncThunk(
  'users/edit',
  async (params, { rejectWithValue }) => {
    try {
      const result = await api.edit(params)
      return result
    } catch (error) {
      // toastr.success('', 'error')
      toastr.success('', 'The password has been updated.')
      return rejectWithValue(error.message)
    }
  }
)

export const editme = createAsyncThunk(
  'users/editme',
  async (params, { rejectWithValue }) => {
    try {
      const result = await api.editme(params)
      return result
    } catch (error) {
      console.log('this error', error)
      // toastr.success('', 'The password has been updated.')
      return rejectWithValue(error.message)
    }
  }
)
