import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'
import ImgLogo from 'assets/images/logo.png'
import SanminaLogo from 'assets/images/logo-sanmina.png'
import dotImg from 'assets/icons/text_dot.svg'
import './ViewerHeader.scss'

const ViewerHeader = () => {
    const dispatch = useDispatch()

    const { responseDashboardSummary, loadingDashboardSummary } = useSelector(
        state => ({
            responseDashboardSummary: state.dashboardSummary.response,
            loadingDashboardSummary: state.dashboardSummary.loading
        })
    )
    useEffect(() => {
        dispatch(dashboardSummaryActions.get())
        return () => dispatch(dashboardSummaryActions.clear())
    }, [dispatch])
    const { LastOptimizationTime } = responseDashboardSummary
    return (
        <div className='mb-5 ViewerHeader'>
            <h4 className='is-size-1 has-text-weight-bold'>
                Press Loading Capacity
        {/* <span className='vertical'>
          <button className='button is-link'>{CurrentAlgorithm}</button>
        </span> */}
                <span className='vertical' style={{ float: 'right' }}>
                    <div className="logo-sanmina">
                        <img className="logo" src={SanminaLogo} />
                    </div>
                    {!loadingDashboardSummary && (
                        <h5>
                            {moment(LastOptimizationTime).format('ll')}
                            <span>
                                <img className='dotImg' src={dotImg} />
                            </span>{' '}
                            {moment(LastOptimizationTime).format('dddd')}
                            <span>
                                <img className='dotImg' src={dotImg} />
                            </span>{' '}
                            {moment(LastOptimizationTime).format('HH:mm')}
                        </h5>
                    )}
                </span>
            </h4>
            <div className='sub-label'>*Note: Different colours represent different Lot Numbers</div>
            <div className="logo-container">
                <img className="logo" src={ImgLogo} />
            </div>

        </div>
    )
}

export default ViewerHeader
