import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as authReducer } from 'state/slices/auth'
import { reducer as userReducer } from 'state/slices/users'
import { reducer as rolesReducer } from 'state/slices/roles'
import { reducer as dashboardSummaryReducer } from 'state/slices/dashboardSummary'
import { reducer as optimizationsPressMachinesReducer } from 'state/slices/optimizationsPressMachines'
import { reducer as optimizationsDataToBeUpdatedReducer } from 'state/slices/optimizationsDataToBeUpdated'
import { reducer as optimizationsCyclesProgressReducer } from 'state/slices/optimizationsCyclesProgress'
import { reducer as optimizationsPressCycleReducer } from 'state/slices/optimizationsPressCycle'
import { reducer as machinesReducer } from 'state/slices/machines'
import { reducer as optimizationsMachinesReducer } from 'state/slices/optimizationsMachines'
import { reducer as optimizationsCycleDataReducer } from 'state/slices/optimizationsCycleData'
import { reducer as releaseReducer } from 'state/slices/release'
import { reducer as wipReducer } from 'state/slices/wip'
import { reducer as activitiesReducer } from 'state/slices/activities'
import { reducer as resetReducer } from 'state/slices/reset'
import { reducer as potentialCyclesReducer } from 'state/slices/potentialCycles'
import { reducer as moveReducer } from 'state/slices/move'
import { reducer as cyclereasonsReducer } from 'state/slices/cyclereasons'
import { reducer as editmaxpanelperbookReducer } from 'state/slices/editmaxpanelperbook'
import { reducer as editnoofbooktobeutilizedReducer } from 'state/slices/editnoofbooktobeutilized'
import { reducer as activityReducer } from 'state/slices/activity'
import { reducer as machineCycleListReducer } from 'state/slices/machineCycleList'
import { reducer as throughputReportReducer } from 'state/slices/throughputReport'
import { reducer as occupancyReportReducer } from 'state/slices/occupancyReport'
import { reducer as pressCycleReportReducer } from 'state/slices/pressCycleReport'
import { reducer as reportYearReducer } from 'state/slices/reportYear'
import { reducer as reportFilterMachineReducer } from 'state/slices/reportFilterMachine'
import { reducer as reportFilterAllMachineReducer } from 'state/slices/reportFilterAllMachine'

const rootReducer = combineReducers({
    users: userReducer,
    auth: authReducer,
    roles: rolesReducer,
    dashboardSummary: dashboardSummaryReducer,
    optimizationsPressMachines: optimizationsPressMachinesReducer,
    optimizationsDataToBeUpdated: optimizationsDataToBeUpdatedReducer,
    optimizationsCyclesProgress: optimizationsCyclesProgressReducer,
    optimizationsPressCycle: optimizationsPressCycleReducer,
    machines: machinesReducer,
    toastr: toastrReducer,
    optimizationsMachines: optimizationsMachinesReducer,
    optimizationsCycleData: optimizationsCycleDataReducer,
    release: releaseReducer,
    wip: wipReducer,
    activities: activitiesReducer,
    reset: resetReducer,
    potentialCycles: potentialCyclesReducer,
    move: moveReducer,
    cyclereasons: cyclereasonsReducer,
    editmaxpanelperbook: editmaxpanelperbookReducer,
    activity: activityReducer,
    editnoofbooktobeutilized: editnoofbooktobeutilizedReducer,
    machineCycleList: machineCycleListReducer,
    throughputReport: throughputReportReducer,
    occupancyReport: occupancyReportReducer,
    pressCycleReport: pressCycleReportReducer,
    reportYear: reportYearReducer,
    reportFilterMachine: reportFilterMachineReducer,
    reportFilterAllMachine: reportFilterAllMachineReducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'auth',
        'optimizationsCycleData',
        'activity',
        'optimizationsPressMachines',
        'optimizationsCyclesProgress',
        'optimizationsPressCycle',
        'dashboardSummary'
    ],
    blacklist: []
}

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
})

export const persistor = persistStore(store)
