import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk(
  'machineCycleListData/get',
  async payload => {
    const response = await api.getInqueueByMachineId(payload)
    return response
  }
)
