import React from 'react'
import moment from 'moment'

import './ActivityTable.scss'

const ActivityTable = ({ data, headData, actionDownload }) => {
  return (
    <div className='box usersTable test'>
      <table className='table is-narrow'>
        <thead>
          <tr className='has-background-white custom-tr'>
            {headData.length > 0 &&
              headData.map((item, i) => <th key='i'>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item, i) => {
              const { Algorithm, FileLogName, Date, Status } = item
              return (
                <tr className='has-background-white detail-cycle' key={i}>
                  <th style={{ width: 200 }}>{Algorithm}</th>
                  <th>{moment(Date).format('ll')}</th>
                  <th>{Status}</th>
                  <th>
                    <button
                      className='button is-white btn-download'
                      onClick={() => actionDownload(FileLogName)}
                    >
                      <span>
                        <i className='fas fa-download' />
                      </span>{' '}
                      {`${FileLogName}`}
                    </button>
                  </th>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default ActivityTable
