import React from 'react'
import moment from 'moment'

import './UsersTable.scss'

const UsersTable = ({ data, headData, onClick }) => {
  return (
    <div className='box usersTable test'>
      <table className='table is-narrow'>
        <thead>
          <tr className='has-background-white custom-tr'>
            {headData.length > 0 &&
              headData.map((item, i) => <th key='i'>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item, i) => {
              const {
                UserName,
                RoleName,
                Email,
                StatusString,
                DateCreated,
                DateModified
              } = item
              return (
                <tr className='has-background-white detail-cycle' key={i}>
                  <th>{UserName}</th>
                  <th>{RoleName}</th>
                  <th>{Email}</th>
                  <th>{StatusString}</th>
                  <th>{moment(DateCreated).format('ll')}</th>
                  <th>
                    {DateModified ? moment(DateModified).format('ll') : '-'}
                  </th>
                  <th>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => onClick(item)}
                    >
                      Edit
                    </div>
                  </th>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default UsersTable
