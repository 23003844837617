import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from 'components/Templates/Dashboard'
import UserManagementHeader from 'components/Organisms/UserManagementHeader'
import UsersTable from 'components/Molecules/UsersTable'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'
import UserModalForm from 'components/Molecules/UserModalForm'

import { HeadTableUsers } from 'constants/cycleData'
import { actions as usersActions } from 'state/slices/users'

import './UserManagement.scss'

const UserManagement = () => {
    const dispatch = useDispatch()
    const [height, setHeight] = useState('100%')
    const [userDetail, setUserDetail] = useState(null)
    const [createUserModalOpen, setCreateUserModalOpen] = useState(false)

    const [maxResultCount] = useState(5)
    const [page, setPage] = useState(1)
    const [skipCount] = useState(1)

    const getHeight = () => {
        setHeight(window.innerHeight + 'px')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const payload = {
            skipCount: 0,
            maxResultCount: maxResultCount
        }
        dispatch(usersActions.get(payload))
        return () => dispatch(usersActions.clear())
    }, [dispatch])

    useEffect(() => {
        getHeight()
        window.addEventListener('resize', getHeight)
        return () => window.removeEventListener('resize', null)
    }, [])

    const { users, totalCount, me, isLoading } = useSelector(state => ({
        users: state.users.response.Items,
        totalCount: state.users.response.TotalCount,
        me: state.users.me,
        isLoading: state.users.loading
    }))

    const fetchData = async page => {
        const skipCount = (page - 1) * maxResultCount
        const payload = {
            skipCount: skipCount,
            maxResultCount: maxResultCount
        }
        await dispatch(usersActions.get(payload))
        setPage(page)
        return () => dispatch(usersActions.clear())
    }

    const handleChangePage = async e => {
        const page = e.target.value !== '' ? parseInt(e.target.value) : 0
        setPage(page)
        if (page !== 0 && e.key === 'Enter') {
            fetchData(page)
        }
    }

    const onClickPrevNext = async page => {
        fetchData(page)
    }
    const handleClose = () => {
        fetchData(page)
        setCreateUserModalOpen(false)
        setUserDetail(null)
    }

    const showTooltips = data => {
        setCreateUserModalOpen(true)
        setUserDetail(data)
    }

    const { RoleName } = me
    const isHaveAccess = RoleName === 'Administrator' || RoleName === 'SuperUser'

    return (
        <Dashboard>
            <div
                className='columns is-multiline homepage pt-5 tableUsers'
                style={{ height }}
            >
                <div className='column is-12'>
                    <UserManagementHeader
                        onClick={() => setCreateUserModalOpen(true)}
                        isHaveAccess={isHaveAccess}
                    />
                    {isHaveAccess && (
                        <>
                            <UsersTable
                                data={users}
                                headData={HeadTableUsers}
                                onClick={e => showTooltips(e)}
                            />
                            <TableFooterPagination
                                data={totalCount}
                                page={parseInt(page)}
                                totalData={users.length}
                                maxResultCount={maxResultCount}
                                skipCount={skipCount}
                                handleChangePage={handleChangePage}
                                onClickPrevNext={page => onClickPrevNext(page)}
                                classNames="margin-bottom-50px"
                            />
                        </>
                    )}
                    {!isLoading && !isHaveAccess && (
                        <div>
                            <p>
                                Sorry you do not have access to open this page, please contact
                                the administrator
              </p>
                        </div>
                    )}
                </div>
            </div>
            {createUserModalOpen && (
                <UserModalForm
                    isOpen={createUserModalOpen}
                    onClose={handleClose}
                    userDetail={userDetail}
                />
            )}
        </Dashboard>
    )
}

export default UserManagement
