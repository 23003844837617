import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk(
  'optimizationsCycleData/get',
  async payload => {
    const response = await api.getOptimizationsPressMachines(payload)
    return response
  }
)

export const put = createAsyncThunk(
  'optimizationsCycleData/put',
  async payload => {
    const response = await api.putCycleMachine(payload)
    return response
  }
)
