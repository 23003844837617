import { createSlice } from '@reduxjs/toolkit'

import { get, put } from 'state/thunks/optimizationsCycleData'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  edit: {},
  loading: false,
  success: false,
  error: null
}

const optimizationsCycleDataSlice = createSlice({
  name: 'optimizationsCycleData',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })

    builder.addCase(put.pending, state => {
      state.loading = true
    })

    builder.addCase(put.fulfilled, (state, action) => {
      state.edit = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(put.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...optimizationsCycleDataSlice.actions,
  get,
  put
}

export const reducer = optimizationsCycleDataSlice.reducer
