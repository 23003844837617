import React, { useState, useEffect } from 'react'
import './Footer.scss'
import OptipediaLogo from 'assets/icons/optipedia.png'
import AppConst from '../../../libs/AppConst'

const Footer = () => {

    return (
        <div className="sanmina-footer">
            <div className="columns">
                <div className="column align-left">
                    Release: <b>{AppConst.APP_VERSION}</b>
                </div>
                <div className="column align-center">
                    Powered by <img src={OptipediaLogo}/>
                </div>
                <div className="column align-right">
                    &copy; 2021 InnoArk Pte Ltd
                </div>
            </div>
        </div>
    )
}
export default Footer
