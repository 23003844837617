import { createSlice } from '@reduxjs/toolkit'

import { preunrelease, unrelease } from 'state/thunks/reset'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  preunrelease: {},
  loading: false,
  success: false,
  error: null
}

const unReleaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(preunrelease.pending, state => {
      state.loading = true
    })

    builder.addCase(preunrelease.fulfilled, (state, action) => {
      state.preunrelease = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(preunrelease.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })

    builder.addCase(unrelease.pending, state => {
      state.loading = true
    })

    builder.addCase(unrelease.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(unrelease.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...unReleaseSlice.actions,
  preunrelease,
  unrelease
}

export const reducer = unReleaseSlice.reducer
