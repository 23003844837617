import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSVG from 'components/Atoms/Loading'
import ViewerPressOptimization from 'components/Organisms/ViewerPressOptimization'
import { actions as machinesActions } from 'state/slices/machines'

import './ViewerMachines.scss'

const ViewerMachines = () => {
  const dispatch = useDispatch()

  const { responseMachines, loading } = useSelector(state => ({
    responseMachines: state.machines.response.Items,
    loading: state.optimizationsMachines.loading
  }))

  useEffect(() => {
    const payload = {
      status: 20,
      skipCount: 0
    }
    dispatch(machinesActions.get(payload))
    // dispatch(machinesActions.clear())
  }, [dispatch])

  useEffect(() => {
    const payload = {
      status: 20,
      skipCount: 0
    }
    const timer = setInterval(() => {
      dispatch(machinesActions.clear())
      dispatch(machinesActions.get(payload))
    }, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [dispatch])

  return (
      <div className='viewerMachine mb-5'>
      {loading && (
        <div className='loadingViewerData'>
          <LoadingSVG color='#80808030' type='spin' />
        </div>
      )}

      {responseMachines &&
        responseMachines.map((item, i) => {
          const { Id, Name } = item
          return (
            <div key={i}>
              <ViewerPressOptimization
                propMachineId={Id}
                machineName={Name}
                index={i}
              />
            </div>
          )
        })}
    </div>
  )
}

export default ViewerMachines
