import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/activities'

const initialState = {
  response: {},
  loading: false,
  error: null
}

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    clear: state => initialState
  },
  extraReducers: {
    [get.pending]: state => {
      state.loading = true
    },
    [get.fulfilled]: (state, action) => {
      state.response = action.payload.response
      state.loading = false
    },
    [get.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const actions = {
  ...activitiesSlice.actions,
  get
}

export const reducer = activitiesSlice.reducer
