import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'connections/restApi';

export const get = createAsyncThunk(
    'throughputReport/get',
    async payload => {
        const response = await api.getThroughputMonthlyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].ItemGroup;
            var data = response[i].Data;

            series.push({ name: name, data: data });
        }

        return series;
    }
)

export const getYearlyReport = createAsyncThunk(
    'throughputYearlyReport/get',
    async payload => {
        const response = await api.getThroughputYearlyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].ItemGroup;
            var data = response[i].Data;
            var years = response[i].Years;

            series.push({ name: name, data: data, years: years });
        }

        return series;
    }
)

export const getQuarterlyReport = createAsyncThunk(
    'throughputQuarterlyReport/get',
    async payload => {
        const response = await api.getThroughputQuarterlyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].ItemGroup;
            var title = response[i].Title;
            var data = response[i].Data;

            series.push({ name: name, data: data, title: title});
        }

        return series;
    }
)

export const getDailyReport = createAsyncThunk(
    'throughputDailyReport/get',
    async payload => {
        const response = await api.getThroughputDailyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].ItemGroup;
            var data = response[i].Data;
            var titles = response[i].Titles;

            series.push({ name: name, data: data, titles: titles});
        }

        return series;
    }
)

export const getWeeklyReport = createAsyncThunk(
    'throughputWeeklyReport/get',
    async payload => {
        const response = await api.getThroughputWeeklyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].ItemGroup;
            var data = response[i].Data;
            var weeks = response[i].Weeks;
            var titles = response[i].Titles;

            series.push({ name: name, data: data, weeks: weeks, titles: titles });
        }

        return series;
    }
)