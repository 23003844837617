import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import cx from 'classnames'
import './SidebarItem.scss'

import activeLi from 'assets/icons/button_menuwhite.svg'
import li from 'assets/icons/button_menublack.svg'

const SidebarItem = ({ label, to, isIcon, icon, onClick }) => {
  const match = useRouteMatch(to)

  if (isIcon) {
    if (onClick) {
      return (
        <li className='sidebar-item is-icon' title={label}>
          <button
            onClick={onClick}
            className='button is-large drawer-button is-icon'
          >
            <span className='icon is-medium'>
              <i className={cx('fas', icon)} />
            </span>
            <span />
          </button>
        </li>
      )
    } else {
      return (
        <li
          className={cx('sidebar-item is-icon', { 'is-active': match })}
          title={label}
        >
          <Link to={to} className='button is-large drawer-button'>
            <span className='icon is-medium'>
              <i className={cx('fas', icon)} />
            </span>
            <span />
          </Link>
        </li>
      )
    }
  }

  if (onClick) {
    return (
      <li className='sidebar-item'>
        <button onClick={onClick}>{label}</button>
      </li>
    )
  }

  return (
    <li className={cx('sidebar-item', { 'is-active': match })}>
      <Link to={to}>
        {' '}
        <img src={match ? activeLi : li} />
        {label}
      </Link>
    </li>
  )
}

SidebarItem.defaultProps = {
  to: ''
}

export default SidebarItem
