import React from 'react'
import Header from 'components/Atoms/Header'
import EditProfile from 'components/Organisms/EditProfile'

const SidebarPersonalSettings = () => {
  return (
    <div id='SidebarPersonalSettings' className='homepage'>
      <aside className='menu'>
        <div className='columns is-multiline personalSettings pt-5'>
          <div className='column is-6 borderRight'>
            <Header title='Personal Settings' />
            <ul className='menu-list'>
              <EditProfile />
            </ul>
          </div>
        </div>
        <aside />
      </aside>
    </div>
  )
}

export default SidebarPersonalSettings
