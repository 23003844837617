import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import SelectMoveCycle from 'components/Molecules/SelectMoveCycle'
import Cyclereasons from 'components/Molecules/Cyclereasons'

import { actions as potentialCyclesActions } from 'state/slices/potentialCycles'
import { actions as moveActions } from 'state/slices/move'
import { actions as cyclereasonsActions } from 'state/slices/cyclereasons'
import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'
import { actions as machineCycleListActions } from 'state/slices/machineCycleList'

import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'
import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'
import { actions as optimizationsCyclesProgressActions } from 'state/slices/optimizationsCyclesProgress'

import styles from './MoveModal.module.scss'

const MoveModal = ({
    isOpen,
    onClose,
    data,
    pressCycleId,
    priority,
    skipCount,
    searchKeyword
}) => {
    const {
        LotNumber,
        PressRecipe,
        PanelSize,
        WorkOrderId,
        ReasonChangeId
    } = data
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(0)
    const [cycle, setCycle] = useState('')
    const [value, setValue] = useState('')
    const [selectCyclereasons, setSelectCyclereasons] = useState(ReasonChangeId)
    const [createAsNew, setCreateAsNew] = useState(false)
    const [isPrompModalOpen, setIsPrompModalOpen] = useState(false)

    const { responseMachines, skipCountMachineListId, machineListId } = useSelector(state => ({
        responseMachines: state.machines.response.Items,
        machineListId: state.machineCycleList.machineId,
        skipCountMachineListId: state.machineCycleList.skipCount
    }))

    const onClickSubmit = async () => {
        setIsLoading(true)
        const data = {
            cycle,
            SourcePressCycleId: pressCycleId,
            WorkOrderId,
            CreateAsNew: createAsNew,
            ReasonChangeId: selectCyclereasons
        }
        if (!createAsNew) {
            data.DestinationPressCycleId = value
        } else {
            data.DestinationPressCycleId = ''
        }

        await dispatch(moveActions.post(data))

        const payloadCyclesProgress = {
            priority: null,
            statuses: [20, 30],
            skipCount: 0,
            maxResultCount: 5
        }

        dispatch(
            optimizationsCyclesProgressActions.get(payloadCyclesProgress)
        )

        const payloadPressCycle = {
            statuses: [20, 30],
            skipCount: 0,
            maxResultCount: 5
        }
        dispatch(optimizationsPressCycleActions.get(payloadPressCycle))

        const payload = {
            priority,
            status: 10,
            skipCount: searchKeyword !== '' ? 0 : skipCount,
            maxResultCount: 5,
            searchKeyword
        }
        dispatch(optimizationsPressMachinesActions.get(payload))
        dispatch(dashboardSummaryActions.get())

        debugger;
        const payloadMachineListId = {
            skipCount: skipCountMachineListId,
            maxResultCount: 5,
            machineId: machineListId ? machineListId : responseMachines[0].Id
        }

        dispatch(machineCycleListActions.get(payloadMachineListId))

        setIsLoading(false)
        onClose()
    }
    const confirmMove = () => {
        setIsPrompModalOpen(true)
    }
    const onCloseModalPromp = () => {
        setIsPrompModalOpen(false)
    }

    const { potentialCycles, cyclereasons } = useSelector(state => ({
        potentialCycles: state.potentialCycles.data.Items,
        cyclereasons: state.cyclereasons.data
    }))

    useEffect(() => {
        dispatch(potentialCyclesActions.get({ pressCycleId, WorkOrderId }))
        return () => dispatch(potentialCyclesActions.clear())
    }, [dispatch])

    useEffect(() => {
        dispatch(cyclereasonsActions.get())
        return () => dispatch(cyclereasonsActions.clear())
    }, [dispatch])

    const onChangeSelect = e => {
        const { currentTarget } = e
        const value = currentTarget.value
        const name = currentTarget.options[e.currentTarget.selectedIndex].text
        if (value === 'CreateAsNew') {
            setCycle(name)
            setCreateAsNew(true)
            setValue(value)
        } else {
            setCycle(name)
            setValue(value)
        }
    }
    const onChangeSelectCyclereasons = e => {
        const { currentTarget } = e
        const value = currentTarget.value
        setSelectCyclereasons(value)
    }
    const onCloseModal = () => {
        onClose()
        setValue('')
    }
    return (
        <>
            <Modal
                haveClose
                isLarge
                isOpen={isOpen}
                onClose={onCloseModal}
                title='Move Cycle'
            >
                <section className={cx('modal-card-body', styles.modalCardMove)}>
                    <div className={cx(styles.customContainer)}>
                        <h3>Lot Number : {LotNumber}</h3>
                        <h3>Press Recipe : {PressRecipe}</h3>
                        <h3>Panel Size : {PanelSize}</h3>
                        <div className={cx(styles.moveSelect)}>
                            <h3>
                                Please select to which cycle to be moved to:{' '}
                                <span className='is-danger' style={{ color: 'red' }}>
                                    {' '}
                  *
                </span>
                            </h3>
                            <SelectMoveCycle
                                data={potentialCycles}
                                onChange={e => onChangeSelect(e)}
                                value={value}
                            />
                        </div>
                        <div className={cx(styles.moveSelect)}>
                            <h3>
                                Detail of changes{' '}
                                <span className='is-danger' style={{ color: 'red' }}>
                                    {' '}
                  *
                </span>
                            </h3>
                            <Cyclereasons
                                data={cyclereasons}
                                onChange={e => onChangeSelectCyclereasons(e)}
                                value={selectCyclereasons}
                            />
                        </div>
                    </div>
                </section>
                <footer className={cx('modal-card-foot', styles.rightButton)}>
                    <button
                        className='button is-success'
                        disabled={!value || !selectCyclereasons}
                        onClick={() => confirmMove()}
                    >
                        Move
          </button>
                </footer>
            </Modal>
            <Modal
                isOpen={isPrompModalOpen}
                onClose={onCloseModalPromp}
                title='Move Cycle Confirmation'
            >
                <section className={cx('modal-card-body', styles.modalCard)}>
                    {!createAsNew && (
                        <h3>Are you sure you want to move this cycle to Cycle {cycle} ?</h3>
                    )}
                    {createAsNew && (
                        <h3>Are you sure you want to move this cycle to new cycle ?</h3>
                    )}
                </section>
                <footer className={cx('modal-card-foot', styles.rightButton)}>
                    <button
                        className={cx('button is-info', {
                            'is-loading': isLoading
                        })}
                        onClick={() => onClickSubmit()}
                    >
                        Yes
          </button>
                    <button
                        className='button is-light'
                        onClick={() => setIsPrompModalOpen(false)}
                    >
                        No
          </button>
                </footer>
            </Modal>
        </>
    )
}

export default MoveModal
