import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Machines, { MenuContext } from 'components/Atoms/Machines'
import { actions as machinesActions } from 'state/slices/machines'

import './ReleaseForm.scss'

const ReleaseForm = ({ onClick }) => {
  const dispatch = useDispatch()

  const { responseMachines } = useSelector(state => ({
    responseMachines: state.machines.response.Items
  }))

  // useEffect(() => {
  //   const payload = {
  //     status: 20,
  //     skipCount: 0
  //   }
  //   dispatch(machinesActions.get(payload))
  //   return () => dispatch(machinesActions.clear())
  // }, [dispatch])

  return (
    <Machines machines={responseMachines} onClick={onClick}>
      <MenuContext.Consumer>
        {index => {
          return <></>
        }}
      </MenuContext.Consumer>
    </Machines>
  )
}

export default ReleaseForm
