import React from 'react'
import { Field, ErrorMessage } from 'formik'
import cx from 'classnames'

import DropdownWithId from 'components/Atoms/DropdownWithId'

import './FormDropdownWithId.scss'

const FormDropdownWithId = ({
  name,
  label,
  data,
  placeholder,
  required,
  ...props
}) => {
  return (
    <div className='field formdropdown'>
      <label
        className={cx('label', required && 'required')}
        htmlFor={`dropdown-${name}`}
      >
        {label}
      </label>
      {data && (
        <Field
          {...props}
          name={name}
          id={`dropdown-${name}`}
          component={DropdownWithId}
          data={data}
          placeholder={placeholder}
        />
      )}

      <ErrorMessage
        name={name}
        render={msg => <p className='help is-danger'>{msg}</p>}
      />
    </div>
  )
}

FormDropdownWithId.defaultProps = {
  placeholder: 'Please Select',
  required: false
}

export default FormDropdownWithId
