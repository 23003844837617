import React from 'react'
import { useSelector } from 'react-redux'
import Sidebar from 'components/Atoms/Sidebar'
import Footer from 'components/Atoms/Footer'
import './Dashboard.scss'

const Dashboard = ({ children }) => {
    const { me } = useSelector(state => ({
        me: state.users.me
    }))

    const { RoleName } = me
    const isHaveAccess = RoleName === 'Administrator' || RoleName === 'SuperUser'

    let data = []
    if (isHaveAccess) {
        data = [
            { label: 'Optimization', to: '/optimization', icon: 'fa-pen' },
            { label: 'History', to: '/history', icon: 'fa-pen' },
            { label: 'Activity', to: '/activity', icon: 'fa-pen' },
            { label: 'Efficiency Tracker', to: '/efficiencytracker', icon: 'fa-chart-bar' },
            { label: 'Settings', to: '/settings', icon: 'fa-pen' },
            { label: 'User Management', to: '/user-management', icon: 'fa-pen' }
        ]
    } else {
        data = [
            { label: 'Optimization', to: '/optimization', icon: 'fa-pen' },
            { label: 'History', to: '/history', icon: 'fa-pen' },
            { label: 'Efficiency Tracker', to: '/efficiencytracker', icon: 'fa-chart-bar' },
            { label: 'Settings', to: '/settings', icon: 'fa-pen' }
        ]
    }
    return (
        <main id='dashboard-template'>
            <Sidebar data={data} />
            <div className='main'>{children}</div>
            <Footer />
        </main>
    )
}

export default Dashboard
