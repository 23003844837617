import React from 'react'
import './TagsMachine.scss'

const TagsMachine = ({ item, onClickEdit, onClickDelete }) => {
  const { Name, Id, Code } = item
  return (
    <div className='control'>
      <div className='tags has-addons is-large'>
        <a
          className='tag is-link'
          onClick={() => onClickEdit({ Name, Id, Code })}
        >
          {Name}
        </a>
        <a
          className='tag is-delete'
          onClick={() => onClickDelete({ Name, Id, Code })}
        />
      </div>
    </div>
  )
}

TagsMachine.defaultProps = {
  onClickEdit: () => {},
  onClickDelete: () => {},
  item: []
}

export default TagsMachine
