import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import * as api from 'connections/restApi'

export const preRelease = createAsyncThunk('preRelease/post', payload => {
  const response = api.preRelease(payload)
  return response
})

export const release = createAsyncThunk(
  'release/post',
  async (params, { rejectWithValue }) => {
    try {
      await api.release(params)
      return { success: true }
    } catch (error) {
      console.log('error', error)
      toastr.error('', error.Message)
      return rejectWithValue(error.Message)
    }
  }
)
