import React from 'react'
import Dashboard from 'components/Templates/Dashboard'
import SidebarPersonalSettings from 'components/Organisms/SidebarPersonalSettings'

import './PersonalSettings.scss'

const PersonalSettings = () => {
  return (
    <Dashboard>
      <SidebarPersonalSettings />
    </Dashboard>
  )
}

export default PersonalSettings
