import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { getAccessToken } from 'utils'
import { Redirect } from 'react-router-dom'
import FormInput from 'components/Molecules/FormInput'
import { actions as authActions } from 'state/slices/auth'

import './LoginForm.scss'

const validationSchema = object({
  username: string().required('Required'),
  password: string().required('Required')
})

const LoginForm = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)

  if (!isEmpty(getAccessToken())) {
    return <Redirect to='/optimization' />
  }
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        grant_type: 'password'
      }}
      validationSchema={validationSchema}
      onSubmit={async values => {
        setIsLoading(true)
        await dispatch(authActions.login(values))
        setIsLoading(false)
      }}
    >
      {() => {
        return (
          <Form>
            <FormInput name='username' label='Username' isRequired />
            <FormInput
              name='password'
              label='Password'
              type='password'
              isRequired
            />
            <div className='field'>
              <div className='control'>
                <button
                  className={cx('button is-primary is-fullwidth', {
                    'is-loading': isLoading
                  })}
                  type='submit'
                >
                  LOG IN
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
