import React from 'react'
import './StatusInfo.scss'

import { ReactComponent as OptiImg } from 'assets/icons/button_optimize.svg'
import { ReactComponent as TotalImg } from 'assets/icons/button_stack.svg'

import { ReactComponent as StokImg } from 'assets/icons/button_stock.svg'
//
const StatusInfo = ({ statusInfo }) => {
  const { StatusString, TotalPanel } = statusInfo || {}
  const { ToolNumbers } = statusInfo || []
  return (
    <div className='columns is-multiline mb-3 statusInfo'>
      <div className='column'>
        <div className='card'>
          <div className='card-content'>
            <p className='subtitle'>
              <OptiImg className='icon1' fill='#ffffff' />
              Status
            </p>
            <p className='title has-text-weight-bold'>{StatusString}</p>
          </div>
        </div>
      </div>
      <div className='column'>
        <div className='card'>
          <div className='card-content'>
            <p className='subtitle'>
              <TotalImg className='icon2' fill='#ffffff' />
              Tools Processing
            </p>
            <p className='title has-text-weight-bold'>
              {ToolNumbers && ToolNumbers.length > 0 && ToolNumbers.toString()}
            </p>
          </div>
        </div>
      </div>
      <div className='column'>
        <div className='card'>
          <div className='card-content'>
            <p className='subtitle'>
              <StokImg className='icon3' fill='#ffffff' />
              Total Quantity
            </p>
            <p className='title has-text-weight-bold'>{TotalPanel}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusInfo
