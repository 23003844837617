import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/potentialCycles'

const initialState = {
  data: {
    Items: []
  },
  loading: false,
  error: null
}

const potentialCyclesSlice = createSlice({
  name: 'potentialCycles',
  initialState,
  reducers: {
    clear: state => initialState
  },
  extraReducers: {
    [get.pending]: state => {
      state.loading = true
    },
    [get.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [get.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const actions = {
  ...potentialCyclesSlice.actions,
  get
}

export const reducer = potentialCyclesSlice.reducer
