import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TabsPressCyrcles from 'components/Molecules/TabsPressCyrcles'
import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'
import './PressCycles.scss'

const PressCycles = ({ changeTab }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [maxResultCount] = useState(5)

  const { responsePressCycles } = useSelector(state => ({
    responsePressCycles: state.optimizationsPressCycle.response.Items,
    isLoading: state.optimizationsPressCycle.loading
  }))

  useEffect(() => {
    const payload = {
      statuses: [20, 30],
      skipCount: 0,
      maxResultCount: maxResultCount
    }
    dispatch(optimizationsPressCycleActions.get(payload))
    return () => dispatch(optimizationsPressCycleActions.clear())
  }, [dispatch])

  const tabs = []
  responsePressCycles.map(item => {
    return tabs.push({
      label: item.ProcessCycle
    })
  })

  const onChangeTab = tab => {
    changeTab(responsePressCycles[tab])
  }
  return (
    <div className='columns is-multiline mb-3 pressCycles'>
      <div className='column is-12'>
        <div className='card card-custom'>
          <header className='card-header'>
            <p className='card-header-title is-size-5 has-text-weight-bold'>
              Press Cycles
            </p>
            <div className='card-header-icon'>
              <button
                className='button view-detail'
                onClick={() => history.push('/press-cycles')}
              >
                View Details
              </button>
            </div>
          </header>
          <div className='card-content'>
            <TabsPressCyrcles
              onChange={e => onChangeTab(e)}
              dataTable={responsePressCycles}
              tabs={tabs}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PressCycles
