import { createSlice } from '@reduxjs/toolkit'

import { login } from 'state/thunks/auth'

const initialState = {
  profile: {},
  loading: false,
  success: false,
  error: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear: () => initialState,
    logout: () => {
      document.cookie = 'sanmina_dashboard_token=; max-age=0; path=/;'
      return initialState
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.loading = true
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.profile = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...authSlice.actions,
  login
}

export const reducer = authSlice.reducer
