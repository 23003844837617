import React from 'react'
import cx from 'classnames'
import CycleDetail from 'components/Molecules/CycleDetail'
import { HeadDetail } from 'constants/cycleData'

const Book = ({
    data,
    processCycle,
    isHaveAccess,
    isShowEdit,
    skipCount,
    searchKeyword
}) => {
    const { UseableBook } = processCycle
    const arrayBook = []

    for (var i = 0; i < 10; i++) {
        const realNumber = i - 1
        arrayBook.push({
            number: i + 1,
            quantity: data[i] ? data[i].Quantity : 0,
            Color: data && data[i] ? data[i].Color : '',
            isHaveReasonChange: data && data[0] && data[0].ReasonChange !== null,
            isGray: UseableBook && UseableBook > i,
            IsEdittedWhenReleased: data && data[i] ? data[i].IsEdittedWhenReleased : false
        })
    }

    return (
        <>
            <div colSpan='8' />
            <tr>
                <th colSpan='8' className='radius-left-bottom'>
                    <div className='columns is-multiline is-gapless has-background-white bordercolums'>
                        {arrayBook.map((item, i) => {
                            const { number, quantity, Color, isHaveReasonChange, isGray, IsEdittedWhenReleased } =
                                item || 0
                            return (
                                <div
                                    className={'column p-3 ' + (IsEdittedWhenReleased ? ' is-background-danger' : '')}
                                    style={{
                                        backgroundColor: data && data.length > 0 && !isGray && 'gray'
                                    }}
                                    key={i}
                                >
                                    <div
                                        className={cx(
                                            'numberbullet'
                                            // quantity === 0 ? 'black' : 'blue'
                                        )}
                                        style={{
                                            backgroundColor: quantity === 0 ? 'black' : Color
                                        }}
                                    >
                                        {number}
                                    </div>
                                    {quantity !== 0 && (
                                        <div className='has-text-centered is-size-2'>
                                            {quantity}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <CycleDetail
                        data={data}
                        headData={HeadDetail}
                        processCycle={processCycle}
                        isHaveAccess={isHaveAccess}
                        isShowEdit={isShowEdit}
                        skipCount={skipCount}
                        searchKeyword={searchKeyword}
                    />
                </th>
            </tr>
        </>
    )
}

export default Book
