import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { object, number, string } from 'yup'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import FormInput from 'components/Molecules/FormInput'
import FormDropdownWithId from 'components/Molecules/FormDropdownWithId'

import { actions as editnoofbooktobeutilizedActions } from 'state/slices/editnoofbooktobeutilized'
import { actions as cyclereasonsActions } from 'state/slices/cyclereasons'

import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'

import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'

import { actions as optimizationsMachinesActions } from 'state/slices/optimizationsMachines'

import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'

import styles from './EditCycleModal.module.scss'

const EditCycleModal = ({
  isOpen,
  onClose,
  data,
  skipCount,
  searchKeyword
}) => {
  const [isPrompModalOpen, setIsPrompModalOpen] = useState(false)
  const { UseableBook, LastReasonChangeId } = data
  const initialValues = {
    NoOfBookToBeUtilized: UseableBook,
    ReasonChangeId: LastReasonChangeId
  }
  const validationSchema = object({
    NoOfBookToBeUtilized: number()
      .typeError('Input must be a number value')
      .required('Required')
      .max(10, 'Allowed maximum is 10')
      .min(1, 'Minimum atleast 1'),
    ReasonChangeId: string().required('Required')
  })
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)
  const { ProcessCycle, Priority } = data

  const { responseMachines } = useSelector(state => ({
    responseMachines: state.machines.response.Items
  }))

  const { cyclereasons } = useSelector(state => ({
    potentialCycles: state.potentialCycles.data.Items,
    cyclereasons: state.cyclereasons.data
  }))
  useEffect(() => {
    dispatch(cyclereasonsActions.get())
    return () => dispatch(cyclereasonsActions.clear())
  }, [dispatch])

  const properInitialValues = currentValue => {
    const newObj = { ...initialValues }

    Object.entries(newObj).forEach(([key, val]) => {
      newObj[key] = (currentValue && currentValue[key]) || val
    })

    return newObj
  }

  const confirmEdit = () => {
    setIsPrompModalOpen(true)
  }

  const onCloseModalPromp = () => {
    setIsPrompModalOpen(false)
  }

  return (
    <>
      <Modal
        haveClose
        isLarge
        isOpen={isOpen}
        onClose={onClose}
        title={`Edit Cycle ${ProcessCycle}`}
      >
        <section className={cx('modal-card-body', styles.modalCardMove)}>
          <Formik
            enableReinitialize
            initialValues={properInitialValues(data)}
            validationSchema={validationSchema}
            onSubmit={async values => {
              setIsLoading(true)
              const { Id } = data
              values.PressCycleId = Id
              await dispatch(
                editnoofbooktobeutilizedActions.editnoofbooktobeutilized(values)
              )
              const payload2 = {
                priority: Priority,
                status: 10,
                skipCount: searchKeyword !== ''? 0: skipCount,
                maxResultCount: 5,
                searchKeyword
              }
              await dispatch(optimizationsPressMachinesActions.get(payload2))

              const payload3 = {
                statuses: [20, 30],
                skipCount: 0,
                maxResultCount: 5
              }
              dispatch(optimizationsPressCycleActions.get(payload3))

              responseMachines &&
                responseMachines.map((item, i) => {
                  const { Id, Name } = item
                  const machineId = Id
                  const payload = {
                    statuses: [20, 30],
                    skipCount: 0,
                    maxResultCount: 5,
                    machineId: machineId,
                    machineName: Name
                  }
                  dispatch(optimizationsMachinesActions.get(payload))
                })
              dispatch(dashboardSummaryActions.get())
              setIsLoading(false)
              onClose()
            }}
          >
            {pristine => {
              const { ReasonChangeId, NoOfBookToBeUtilized } = pristine.values
              return (
                <Form>
                  <FormInput
                    name='NoOfBookToBeUtilized'
                    label='Number of Book to be Utilized (1 - 10)'
                    isRequired
                    autoComplete
                  />
                  <FormDropdownWithId
                    name='ReasonChangeId'
                    label='Detail of Changes'
                    required
                    data={cyclereasons}
                  />
                  <div className='field'>
                    <div className='control'>
                      <button
                        className={cx('button is-primary', {
                          'is-loading': isLoading
                        })}
                        disabled={
                          ReasonChangeId ===
                            '00000000-0000-0000-0000-000000000000' ||
                          !NoOfBookToBeUtilized
                        }
                        type='button'
                        onClick={() => confirmEdit()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <Modal
                    isOpen={isPrompModalOpen}
                    onClose={onCloseModalPromp}
                    title='Edit Book To Be Utilized Confirmation'
                  >
                    <section
                      className={cx(
                        'modal-card-body editcyclebook',
                        styles.modalCard
                      )}
                    >
                      <h3
                        className='h3-content'
                        style={{ fontFamily: 'PoppinsLight' }}
                      >
                        Are you sure you want to edit the information of this
                        cycle?
                      </h3>
                    </section>
                    <footer
                      className={cx('modal-card-foot', styles.rightButton)}
                    >
                      <button
                        className={cx('button is-info', {
                          'is-loading': isLoading
                        })}
                        type='submit'
                      >
                        Yes
                      </button>
                      <button
                        className='button is-light'
                        type='button'
                        onClick={() => setIsPrompModalOpen(false)}
                      >
                        No
                      </button>
                    </footer>
                  </Modal>
                </Form>
              )
            }}
          </Formik>
        </section>
      </Modal>
    </>
  )
}

export default EditCycleModal
