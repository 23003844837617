import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TabsCyrclesProgress from 'components/Molecules/TabsCyrclesProgress'

import { actions as optimizationsCyclesProgressActions } from 'state/slices/optimizationsCyclesProgress'

import './CyclesProgress.scss'

const CyclesProgress = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [tab, setTab] = useState(0)

    const { responseoptimizationsCyclesProgress } = useSelector(state => ({
        responseoptimizationsCyclesProgress:
            state.optimizationsCyclesProgress.response.Items
    }))

    const fetchData = status => {
        const payload = {
            priority: null,
            status,
            skipCount: 0,
            maxResultCount: 5
        }
        dispatch(optimizationsCyclesProgressActions.get(payload))
        setTab(status)
        return () => dispatch(optimizationsCyclesProgressActions.clear())
    }
    useEffect(() => {
        fetchData(20)
    }, [dispatch])

    const onChangeTab = e => {
        let status = tab
        switch (e) {
            case 0:
                status = 20
                break
            case 1:
                status = 30
                break
            case 2:
                status = 40
                break
        }
        fetchData(status)
    }
    return (
        <div className='columns is-multiline mb-3 cyclesProgress margin-bottom-50px'>
            <div className='column is-12'>
                <div className='card card-custom'>
                    <header className='card-header'>
                        <p className='card-header-title is-size-5 has-text-weight-bold'>
                            Cycles Progress
            </p>
                        <div className='card-header-icon'>
                            <button
                                className='button view-detail'
                                onClick={() => history.push('/history')}
                            >
                                Show All History
              </button>
                        </div>
                    </header>
                    <div className='card-content customCard'>
                        <TabsCyrclesProgress
                            onChange={e => onChangeTab(e)}
                            dataTable={responseoptimizationsCyclesProgress}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CyclesProgress
