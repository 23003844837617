import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs, { MenuContext } from 'components/Atoms/TabsButtons'
import MachineCycleList from 'components/Molecules/MachineCycleList'
import { actions as machinesActions } from 'state/slices/machines'
import { actions as machineCycleListActions } from 'state/slices/machineCycleList'


const TabsMachineCyrcleList = ({
    onChange,
    dataTable,
    machineId,
    skipCount,
    maxResultCount,
    totalCount,
    page
}) => {
    const dispatch = useDispatch()

    const {
        responseMachines
    } = useSelector(state => ({
        responseMachines: state.machines.response.Items
    }))

    useEffect(() => {
        const payload = {
            skipCount
        }
        dispatch(machinesActions.get(payload))
        return () => dispatch(machinesActions.clear())
    }, [dispatch])

    useEffect(() => {
        const payload = {
            skipCount,
            maxResultCount,
            machineId,
        }
        // setIdMachine(machineIdCycleList)
        dispatch(machineCycleListActions.get(payload))
        return () => dispatch(machineCycleListActions.clear())
    }, [dispatch])

    const newMachineLists = []
    responseMachines.map((item) => {
        return newMachineLists.push({
            label: item.Name
        })
    })

    return (
        <Tabs tabs={newMachineLists} onChange={onChange}>
            <MenuContext.Consumer>
                {index => {
                    return (
                        <div style={{ padding: 10 }}>
                            {newMachineLists && newMachineLists.map((item, i) => {
                                return index === i && <MachineCycleList
                                    data={dataTable}
                                    skipCount={skipCount}
                                    maxResultCount={maxResultCount}
                                    totalCount={totalCount}
                                    page={page}
                                />
                            })}
                        </div>
                    )
                }}
            </MenuContext.Consumer>
        </Tabs>
    )
}

export default TabsMachineCyrcleList
