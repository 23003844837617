import React, { useEffect } from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import styles from './Modal.module.scss'

const Modal = ({
  children,
  isOpen,
  onClose,
  title,
  className,
  isLarge,
  haveClose
}) => {
  const classList = get(document.documentElement, 'classList', null)

  useEffect(() => {
    if (classList) {
      if (isOpen) {
        classList.add('is-clipped')
      } else {
        classList.remove('is-clipped')
      }
    }

    return () => classList && classList.remove('is-clipped')
  }, [classList, isOpen])

  return (
    <div
      className={cx('modal', className, {
        'is-active': isOpen
      })}
    >
      <div className='modal-background' onClick={onClose} />
      <div className={cx('modal-card', isLarge && styles.modalContent)}>
        <header className={cx('modal-card-head')}>
          <p className='modal-card-title'>{title}</p>
          {haveClose && (
            <button
              type='button'
              className='delete is-large'
              aria-label='close'
              onClick={onClose}
            />
          )}
        </header>
        {children}
      </div>
    </div>
  )
}

export default Modal
