import React from 'react'
import cx from 'classnames'

import { ReactComponent as ButtonPrevImg } from 'assets/icons/button_prev.svg'
import { ReactComponent as ButtonNextImg } from 'assets/icons/button_next.svg'

const TableFooterPagination = ({
    data,
    page,
    maxResultCount,
    onClickPrevNext,
    handleChangePage,
    totalData,
    classNames
}) => {
    let totalPage = 0
    if (data % maxResultCount === 0) {
        totalPage = data / maxResultCount
        if (totalPage === 0) totalPage = 1
    } else {
        totalPage = data / maxResultCount
    }
    return (
        <>
            <footer className={'card-footer ' + classNames}>
                <p className='card-footer-item left'>
                    <input
                        className='input'
                        type='number'
                        placeholder='1'
                        value={page === 0 ? '' : page}
                        onKeyDown={handleChangePage}
                        onChange={handleChangePage}
                        disabled={data <= maxResultCount}
                    />{' '}
                    <span className='text-of'>of {Math.ceil(totalPage)}</span>
                </p>
                <p className='card-footer-item right'>
                    <div className='buttons prev-next'>
                        <button
                            className={cx(
                                page === 1 || page === 0 ? 'button is-black' : 'button'
                            )}
                            onClick={() => (page === 1 ? '' : onClickPrevNext(page - 1))}
                        >
                            <ButtonPrevImg
                                className='prev'
                                fill={page === 1 ? '#ffffff' : ''}
                            />{' '}
              Prev Page
            </button>
                        <button
                            className={cx(
                                page * maxResultCount >= data ? 'button is-black' : 'button'
                            )}
                            onClick={() =>
                                page * maxResultCount >= data ? '' : onClickPrevNext(page + 1)
                            }
                        >
                            Next Page{' '}
                            <ButtonNextImg
                                className='next'
                                fill={page * maxResultCount >= data ? '#ffffff' : ''}
                            />
                        </button>
                    </div>
                </p>
            </footer>
        </>
    )
}

export default TableFooterPagination
