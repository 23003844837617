import React, { useState, useEffect } from 'react'
import { actions as machinesActions } from 'state/slices/machines'
import { useDispatch, useSelector } from 'react-redux'
import TagsMachine from 'components/Atoms/TagsMachine'
import InputGroup from 'components/Atoms/InputGroup'
import ModalSettingMachine from 'components/Molecules/ModalSettingMachine'
import './MachineMaster.scss'

const MachineMaster = () => {
  const dispatch = useDispatch()
  const [settingMachineModalOpen, setSettingMachineModalOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isCreteNew, setIsCreteNew] = useState(false)
  const [valueName, setValueName] = useState('')
  const [itemMachine, setItemMachine] = useState({})

  const { responseMachines } = useSelector(state => ({
    responseMachines: state.machines.response.Items
  }))

  useEffect(() => {
    const payload = {
      skipCount: 0
    }
    dispatch(machinesActions.get(payload))
    return () => dispatch(machinesActions.clear())
  }, [dispatch])
  const onClickAdd = value => {
    const valueData = {
      Name: value
    }
    setIsCreteNew(true)
    if (value !== '') {
      setItemMachine(valueData)
      setSettingMachineModalOpen(true)
    }
  }
  const onClickEdit = item => {
    setSettingMachineModalOpen(true)
    setItemMachine(item)
  }
  const onClickDelete = item => {
    setSettingMachineModalOpen(true)
    setItemMachine(item)
    setIsDelete(true)
  }
  const handleClose = () => {
    setSettingMachineModalOpen(false)
    setIsDelete(false)
    setIsCreteNew(false)
    setItemMachine({})
    setValueName('')
    const payload = {
      skipCount: 0
    }
    dispatch(machinesActions.get(payload))
  }
  return (
      <div className='card wipSelection margin-bottom-50px'>
      <header className='card-header'>
        <p className='card-header-title'>Machine Master</p>
      </header>
      <div className='card-content'>
        <div className='content'>
          <InputGroup
            placeholder='Type machine name'
            onClick={value => onClickAdd(value)}
            valueName={valueName}
            onChange={value => setValueName(value.currentTarget.value)}
          />
          <div className='field is-grouped is-grouped-multiline machine-master-list'>
            {Array.isArray(responseMachines) &&
              responseMachines.length > 0 &&
              responseMachines.map((item, i) => {
                return (
                  <TagsMachine
                    item={item}
                    onClickEdit={e => onClickEdit(e)}
                    onClickDelete={e => onClickDelete(e)}
                    key={i}
                  />
                )
              })}
          </div>
        </div>
      </div>
      {settingMachineModalOpen && (
        <ModalSettingMachine
          isOpen={settingMachineModalOpen}
          onClose={handleClose}
          item={itemMachine}
          isDelete={isDelete}
          isCreteNew={isCreteNew}
        />
      )}
    </div>
  )
}

export default MachineMaster
