import React, { useState, createContext } from 'react'
import { number, shape, arrayOf, string } from 'prop-types'
import TabItem from '../TabItemButton'
import './TabsButtons.scss'

const MenuContext = createContext({
  tabIndex: null
})

const TabsButtons = ({ tabs, children, className, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const changeTab = index => {
    setActiveIndex(index)
    onChange(index)
  }
  return (
    <MenuContext.Provider value={activeIndex}>
      <>
        <div className='tabs TabsButtons'>
          <ul>
            {Array.isArray(tabs) &&
              tabs.length > 0 &&
              tabs.map((tab, index) => {
                const { label } = tab
                const activeTab = activeIndex === index
                return (
                  <TabItem
                    isActive={activeTab}
                    onChange={() => changeTab(index)}
                    key={index}
                  >
                    {label}
                  </TabItem>
                )
              })}
          </ul>
        </div>
        <div>{children}</div>
      </>
    </MenuContext.Provider>
  )
}

TabsButtons.defaultProps = {
  tabs: [],
  tabIndexActive: 0
}

TabsButtons.propTypes = {
  tabs: arrayOf(
    shape({
      label: string
    })
  ),
  tabIndexActive: number
}

export default TabsButtons
export { MenuContext }
