import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import cx from 'classnames'
import FormInput from 'components/Molecules/FormInput'

import { actions } from 'state/slices/users'

const validationSchema = object({
    CurrentPassword: string().required('Required'),
    NewPassword: string().required('Required'),
    ConfirmPassword: string().required('Required')
})

const initialValues = {
    CurrentPassword: '',
    NewPassword: '',
    ConfirmPassword: ''
}

const EditProfile = () => {
    const [isLoading, setIsLoading] = useState(0)

    const dispatch = useDispatch()

    const properInitialValues = currentValue => {
        const newObj = { ...initialValues }

        Object.entries(newObj).forEach(([key, val]) => {
            newObj[key] = (currentValue && currentValue[key]) || val
        })

        return newObj
    }

    const { me } = useSelector(state => ({
        me: state.users.me
    }))

    useEffect(() => {
        dispatch(actions.me())
        return () => dispatch(actions.clear())
    }, [dispatch])

    const { UserName, Email, Id } = me || {}
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={properInitialValues(me)}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                    setIsLoading(true)
                    values.Id = Id
                    await dispatch(actions.editme(values))
                    setIsLoading(false)
                    resetForm()
                }}
            >
                {() => {
                    return (
                        <div className='EditProfile pt-2 margin-bottom-50px'>
                            <Form autoComplete='off'>
                                <div className='field'>
                                    <label className='label'>Username</label>
                                    <div className='control'>
                                        <input
                                            className='input'
                                            type='text'
                                            value={UserName}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className='field'>
                                    <label className='label'>Email</label>
                                    <div className='control'>
                                        <input
                                            className='input'
                                            value={Email}
                                            type='email'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <FormInput
                                    name='CurrentPassword'
                                    label='Current Password'
                                    type='password'
                                    isRequired
                                />
                                <FormInput
                                    name='NewPassword'
                                    label='New Password'
                                    type='password'
                                    isRequired
                                />
                                <FormInput
                                    name='ConfirmPassword'
                                    label='Confirm Password'
                                    type='password'
                                    isRequired
                                />
                                <div className='field'>
                                    <div className='control'>
                                        <button
                                            className={cx('button is-primary', {
                                                'is-loading': isLoading
                                            })}
                                            type='submit'
                                        >
                                            Update
                    </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </>
    )
}

export default EditProfile
