import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TabsMachineCyrcleList from 'components/Molecules/TabsMachineCyrcleList'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'
import { actions as machineCycleListActions } from 'state/slices/machineCycleList'
import { HeadTable } from 'constants/cycleData'
import { actions as machinesActions } from 'state/slices/machines'
import './MachineCycleListHome.scss'

const MachineCycleListHome = () => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [tab, setTab] = useState(0)
    const [idMachine, setIdMachine] = useState('')
    const [maxResultCount] = useState(5)
    const [skipCount, setSkipCount] = useState(0)

    const {
        responsemachineCycleList,
        responseMachines,
        totalCount,
        isLoading,
        skipCountMachine,
        pageMachine
    } = useSelector(state => ({
        responsemachineCycleList:
            state.machineCycleList.response.Items,
        totalCount: state.machineCycleList.response.TotalCount,
        responseMachines: state.machines.response.Items,
        isLoading: state.machineCycleList.loading,
        machineId: state.machineCycleList.machineId,
        skipCountMachine: state.machineCycleList.skipCount,
        pageMachine: state.machineCycleList.pageMachine,
    }))

    const onChangeTab = e => {
        responseMachines.map((item, index) => {
            if (index === e) {
                setTab(index)
                fetchOptimization(1, item.Id)
            }
        })
    }

    useEffect(() => {
        const payload = {
            skipCount: 0
        }
        dispatch(machinesActions.get(payload))
        return () => dispatch(machinesActions.clear())
    }, [dispatch])

    const fetchOptimization = async (page, machineId) => {
        let payload = {}
        const skipCount = (page - 1) * maxResultCount
        payload = {
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            machineId
        }
        dispatch(machineCycleListActions.setMachineId(machineId))
        dispatch(machineCycleListActions.setSkipCount(skipCount))
        setPage(page)
        console.log("here machineId", machineId)
        await dispatch(machineCycleListActions.get(payload))
        setIdMachine(machineId)
        setSkipCount(skipCount)

    }

    const onClickPrevNext = async page => {
        console.log("onClickPrevNext hello", page)
        const skipCount = (page - 1) * maxResultCount
        responseMachines.map((item, index) => {
            if (index === tab) {
                fetchOptimization(page, item.Id)
            }
        })
        dispatch(machineCycleListActions.setPageMachine(page))
        setSkipCount(skipCount)
    }

    const handleChangePage = async e => {
        const page = e.target.value !== '' ? parseInt(e.target.value) : 0
        console.log("handleChangePage hello", page)
        setPage(page)
        if (page !== 0) {
            const skipCount = (page - 1) * maxResultCount
            responseMachines.map((item, index) => {
                if (index === tab) {
                    fetchOptimization(page, item.Id)
                }
            })
            setSkipCount(skipCount)
        }
    }

    return (
        <div className='columns is-multiline mb-3 cyclesData'>
            <div className='column is-12'>
                <div className='card card-custom'>
                    <header className='card-header'>
                        <p className='card-header-title is-size-5 has-text-weight-bold'>
                            Machine Cycle List
                        </p>
                    </header>
                    <div className='card-content'>
                        {responseMachines && responseMachines.length > 0 && <TabsMachineCyrcleList
                            onChange={e => onChangeTab(e)}
                            dataTable={
                                responsemachineCycleList
                            }
                            headTable={HeadTable}
                            isLoading={isLoading}
                            skipCount={skipCountMachine}
                            maxResultCount={maxResultCount}
                            machineId={responseMachines[0].Id}
                            totalCount={totalCount}
                            page={pageMachine}
                        />}

                    </div>
                    <TableFooterPagination
                        data={
                            totalCount
                        }
                        page={parseInt(page)}
                        totalData={responsemachineCycleList.length}
                        maxResultCount={maxResultCount}
                        skipCount={skipCount}
                        handleChangePage={handleChangePage}
                        onClickPrevNext={page => onClickPrevNext(page)}
                    />
                </div>
            </div>
        </div>
    )
}

export default MachineCycleListHome
