import React from 'react'

import SearchBar from 'components/Atoms/SearchBar'
import FilterDropdown from 'components/Atoms/FilterDropdown'
import './Header.scss'

const Header = ({
  title,
  isHaveSearchBar,
  isHaveFilter,
  dataOptions,
  searchQuery,
  isLoading,
  onChange,
  onChangeFilterDropdown,
  filterByMachine
}) => {
  return (
    <div className='mb-5 HistoryHeader'>
      <h4 className='is-size-1 has-text-weight-bold'>{title}</h4>
      {isHaveSearchBar && (
        <span className='vertical' style={{ float: 'right' }}>
          <SearchBar
            value={searchQuery}
            placeholder='Search'
            onChange={e => onChange(e)}
            isLoading={isLoading}
          />
        </span>
      )}
      {isHaveFilter && (
        <span className='vertical' style={{ float: 'right' }}>
          <FilterDropdown
            data={dataOptions}
            value={filterByMachine}
            placeholder="Machine Filter"
            onChange={e => onChangeFilterDropdown(e)}
            isLoading={isLoading}
          />
        </span>
      )}
    </div>
  )
}
Header.defaultProps = {
  isHaveSearchBar: false
}

export default Header
