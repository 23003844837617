import React from 'react'
import cx from 'classnames'

import './SearchBar.scss'

const SearchBar = ({ value, placeholder, onChange, isLoading }) => {
  return (
    <p
      className={cx(
        'control search-bar',
        isLoading ? 'is-loading' : 'has-icons-right'
      )}
    >
      <input
        disabled={isLoading}
        className='input'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {!isLoading && (
        <span className='icon is-small is-right'>
          <i className='fas fa-search' />
        </span>
      )}
    </p>
  )
}

export default SearchBar
