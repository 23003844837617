import React, { useState } from 'react'
import cx from 'classnames'
import CardCycleProgress from 'components/Molecules/CardCycleProgress'
import CycleDetail from 'components/Molecules/CycleDetail'

import { HeadDetailCycleProgress, color } from 'constants/cycleData'
import './CyclesProgressRelease.scss'

const CyclesProgressRelease = ({ items, status }) => {
  const [indexCard, setIndexCard] = useState(0)
  const [itemLength, setItemLength] = useState(0)
  const Books = items[indexCard] && items[indexCard].Books
  const onClickIndexCard = index => {
    setIndexCard(index)
    setItemLength(items.length)
  }
  let isStatus = 0

  switch (status) {
    case 0:
      isStatus = 'Execute'
      break
    case 1:
      isStatus = 'Processing'
      break
    case 2:
      isStatus = 'Success'
      break
    default:
      break
  }

  const checkStatus = () => {
    switch (status) {
      case 0:
        return 'Execute'
      case 1:
        return 'Processing'
      case 2:
        return 'Success'
      default:
        break
    }
  }
  return (
    <>
      <div className={cx('columns', !Books ? 'firstActive' : '')}>
        {items.length > 0 &&
          items.map((item, index) => {
            return (
              <div
                className={cx(
                  'column',

                  indexCard === index ? 'activeCycle' : '',
                  items.length < 3 ? 'is-3' : ''
                )}
                key={index}
              >
                <CardCycleProgress
                  item={item}
                  onClick={() => onClickIndexCard(index)}
                  isVisit={indexCard}
                  color={color[index]}
                />
              </div>
            )
          })}
      </div>
      {items.length > 0 && (
        <CycleDetail
          data={Books ? Books : items[0].Books}
          processCycle={Books ? items[indexCard] : items[0]}
          headData={HeadDetailCycleProgress}
          isShowTreeDot={false}
          isStatus={checkStatus}
        />
      )}
    </>
  )
}

export default CyclesProgressRelease
