import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/cyclereasons'

const initialState = {
  data: {
    Items: []
  },
  loading: false,
  error: null
}

const cyclereasonsSlice = createSlice({
  name: 'cyclereasons',
  initialState,
  reducers: {
    clear: state => initialState
  },
  extraReducers: {
    [get.pending]: state => {
      state.loading = true
    },
    [get.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [get.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const actions = {
  ...cyclereasonsSlice.actions,
  get
}

export const reducer = cyclereasonsSlice.reducer
