import React, { useState, useEffect } from 'react'
import s from 'shortid'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import SidebarItem from 'components/Atoms/SidebarItem'
import { Link } from 'components/Atoms'
import { actions as authActions } from 'state/slices/auth'
import { actions as userActions } from 'state/slices/users'
import { useHistory } from 'react-router-dom'
import './Sidebar.scss'
import ImgLogo from 'assets/images/logo.png'
import Avatar from 'assets/images/avatar.jpg'
import OptipediaLogin from 'assets/icons/optipedia.png'

const Sidebar = ({ data, isHover }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isOpen] = useState(true)
  const [isOpenProfile, setOpenProfile] = useState(false)
  const [height, setHeight] = useState('100%')

  const getHeight = () => {
    setHeight(window.innerHeight + 'px')
  }

  const { me } = useSelector(state => ({
    me: state.users.me
  }))

  useEffect(() => {
    getHeight()
    window.addEventListener('resize', getHeight)
    return () => window.removeEventListener('resize', null)
  }, [])

  useEffect(() => {
    dispatch(userActions.me())
    return () => dispatch(userActions.clear())
  }, [dispatch])

  const onCLickOpenProfile = () => {
    setOpenProfile(!isOpenProfile)
  }
  const logout = async () => {
    await dispatch(authActions.logout())
    history.push('/login')
  }

  const { UserName, RoleName } = me || {}
  return (
    <aside
      className={cx('aside', { 'is-active': isOpen, 'is-hover': isHover })}
      style={{ height }}
    >
      <div className='field mt-5'>
        <img className='logo' src={ImgLogo} />
      </div>
      <ul>
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map(props => (
            <SidebarItem {...props} key={s.generate()} isIcon={!isOpen} />
          ))}
        <div
          className={cx(
            'editProfile dropdown is-up',
            isOpenProfile && 'is-active'
          )}
        >
          <div className='media' onClick={onCLickOpenProfile}>
            <div className='media-left'>
              <figure>
                <img
                  src={Avatar}
                  alt='Placeholder image'
                  className='imageprofile'
                />
              </figure>
            </div>
            <div className='media-content mouse-cursor'>
              <p className='title is-4'>{UserName}</p>
              <p className='subtitle is-6'>{RoleName}</p>
            </div>
          </div>

          <div className='dropdown-menu' id='dropdown-menu' role='menu'>
            <div className='dropdown-content'>
              <Link to='personal_settings' className='dropdown-item'>
                Personal Settings
              </Link>
              <hr className='dropdown-divider' />
              <a onClick={() => logout()} className='dropdown-item'>
                Logout
              </a>
            </div>
          </div>
        </div>
      </ul>
    </aside>
  )
}

Sidebar.defaultProps = {
  isHover: false
}

export default Sidebar
