import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/optimizationsDataToBeUpdated'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  loading: false,
  success: false,
  error: null
}

const optimizationsDataToBeUpdatedSlice = createSlice({
  name: 'optimizationsDataToBeUpdated',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...optimizationsDataToBeUpdatedSlice.actions,
  get
}

export const reducer = optimizationsDataToBeUpdatedSlice.reducer
