import React from 'react'
import get from 'lodash/get'
import cx from 'classnames'

const TextInput = ({
  field,
  form,
  className,
  type,
  autoComplete,
  ...props
}) => {
  const { name } = field
  const { touched, errors } = form

  const isError = {
    'is-danger': get(touched, name, null) && get(errors, name, null)
  }

  return (
    <div className='control'>
      <input
        {...props}
        {...field}
        className={cx('input', className, isError)}
        type={type}
        autoComplete={autoComplete && 'new-password'}
      />
    </div>
  )
}

TextInput.defaultProps = {
  type: 'text',
  autoComplete: false
}

export default TextInput
