import React from 'react'
import { Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import TextInput from 'components/Atoms/TextInput'

const FormInput = ({
  name,
  label,
  type,
  isRequired,
  isMaterialize,
  className,
  autoComplete,
  ...props
}) => {
  return (
    <div className={cx('field', className)}>
      {isMaterialize ? null : (
        <label className='label' htmlFor={`input-${name}`}>
          {label}
          {isRequired && (
            <span className='is-danger' style={{ color: 'red' }}>
              {' '}
              *
            </span>
          )}
        </label>
      )}
      <Field
        {...props}
        type={type}
        name={name}
        id={`input-${name}`}
        component={TextInput}
        className={cx({ 'is-materialize': isMaterialize })}
        placeholder={isMaterialize ? label : ''}
        autoComplete={autoComplete}
      />
      <ErrorMessage
        name={name}
        render={msg => <p className='help is-danger'>{msg}</p>}
      />
    </div>
  )
}

FormInput.defaultProps = {
  isRequired: false,
  isMaterialize: false,
  type: 'text',
  className: ''
}

export default FormInput
