import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Dashboard from 'components/Templates/Dashboard'

import DetailListPressCycles from 'components/Organisms/DetailListPressCycles'

import './DetailPressCycles.scss'

const DetailPressCycles = () => {
  const [height, setHeight] = useState('100%')

  const getHeight = () => {
    setHeight(window.innerHeight + 'px')
  }

  useEffect(() => {
    getHeight()
    window.addEventListener('resize', getHeight)
    return () => window.removeEventListener('resize', null)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { me } = useSelector(state => ({
    me: state.users.me
  }))
  const { RoleName } = me
  const isHaveAccess =
    RoleName === 'Administrator' || RoleName === 'SuperEditor'

  return (
    <Dashboard>
      <div className='columns is-multiline homepage pt-5' style={{ height }}>
        <div className='column is-12'>
          <DetailListPressCycles isHaveAccess={isHaveAccess} />
        </div>
      </div>
    </Dashboard>
  )
}

export default DetailPressCycles
