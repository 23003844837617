import React, { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import dotImg from 'assets/icons/text_dot.svg'
import { ReactComponent as BellImg } from 'assets/icons/button_bell.svg'

import './Bell.scss'

const Bell = ({ bellData, bellLoading }) => {
  const { Status, Date, Algorithm } = bellData || {};
  let tooltip = useRef(null)
  return (
    <>
      <span
        className='bellbutton'
        data-tip
        data-event='click'
        // data-event-off='dblclick'
        data-for='button-bell'
        // data-delay-hide='300'
      >
        {!bellLoading && (
          <div
            style={{
              width: 8,
              height: 8,
              backgroundColor: Status === 'Success' ? '#7fba7a' : 'red',
              borderRadius: 100,
              position: 'absolute',
              right: -3,
              top: 10,
              padding: 5,
              border: '3px solid #fff'
            }}
          />
        )}
        <BellImg className='bell' fill='#11141A' />
      </span>
      <ReactTooltip
        id='button-bell'
        clickable
        globalEventOff='click'
        place='left'
        ref={el => (tooltip = el)}
      >
        <div className='cycle'>
          <h5 className='mb-17'>
            <span
              style={{
                fontWeight: 'bold'
              }}
            >
              Status
            </span>{' '}
            <span>
              <img className='dotImg' src={dotImg} />
            </span>
            <span
              style={{
                color: Status === 'Success' ? '#7fba7a' : 'red',
                fontWeight: 'bold'
              }}
            >
              {Status}
            </span>
          </h5>
          <h5 className='mb-17'>
            <span
              style={{
                fontWeight: 'bold'
              }}
            >
              Optimization time
            </span>{' '}
            <span>
              <img className='dotImg' src={dotImg} />
            </span>
            {`${moment(Date).format('ll')},`}{' '}
            <span style={{paddingLeft:3}}>{`${moment(Date).format('HH:mm')}`}</span>
          </h5>
          <h5 className='mb-17'>
            <span
              style={{
                fontWeight: 'bold'
              }}
            >
              WIP
            </span>{' '}
            <span>
              <img className='dotImg' src={dotImg} />
            </span>
            {Algorithm}
          </h5>
        </div>
      </ReactTooltip>
    </>
  )
}

export default Bell
