import React from 'react'
import ReactLoading from 'react-loading'

const Loading = ({ type, color, height, width }) => (
  <ReactLoading
    className='loading'
    type={type}
    color={color}
    height={height}
    width={width}
  />
)

Loading.defaultProps = {
  height: '20%',
  width: '20%'
}

export default Loading
