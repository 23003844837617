import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import TableBody from 'components/Atoms/TableBody'
import Book from 'components/Molecules/Book'

import dotImg from 'assets/icons/text_dot.svg'
import './DetailTableCycleItemDetailPressCycles.scss'

const DetailTableCycleItemDetailPressCycles = ({
  data,
  isHaveAccess,
  isShowEdit,
  onCLickReset
}) => {
  // console.log("data pro", data)
  return (
    <>
      {Array.isArray(data) &&
        data.length > 0 &&
        data.map((item, i) => {
          const {
            ProcessCycle,
            PressTypeString,
            Books,
            Machine,
            ReleasedBy,
            ReleasedDate,
            Algorithm,
            StatusString
          } = item || {}
          const { Name } = Machine || {}
          return (
            <>
              <TableBody
                className={cx('card cursorpointer detailpresscycles')}
                key={i}
              >
                <>
                  <tr style={{ height: 0 }}>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th style={{ float: 'right', paddingTop: 20 }}>
                      {StatusString}{' '}
                      <span>
                        <img className='dotImg' src={dotImg} />
                      </span>{' '}
                      {Name}{' '}
                      <span>
                        <img className='dotImg' src={dotImg} />
                      </span>{' '}
                      {Algorithm}{' '}
                      <span>
                        <img className='dotImg' src={dotImg} />
                      </span>{' '}
                      {`Executed by ${ReleasedBy}`}
                      <span>
                        <img className='dotImg' src={dotImg} />
                      </span>{' '}
                      {`${moment(ReleasedDate).format('ll - HH:mm')}`}
                    </th>
                  </tr>
                  <tr>
                    <th className='dark-text'>
                      {ProcessCycle}{' '}
                      <span><img className='dotImg' src={dotImg} /></span>{' '}
                      {PressTypeString}
                    </th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th className='btnrelease'>
                      {isHaveAccess && (
                        <button
                          className='button is-link'
                          onClick={() => onCLickReset(item)}
                        >
                          Reset
                        </button>
                      )}
                    </th>
                  </tr>
                  <Book
                    data={Books}
                    processCycle={data[i]}
                    isHaveAccess={isHaveAccess}
                    isShowEdit={isShowEdit}
                  />
                </>
              </TableBody>
              <div className='m-5' />
            </>
          )
        })}
    </>
  )
}

export default DetailTableCycleItemDetailPressCycles
