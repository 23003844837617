import React, {useRef} from 'react'
import s from 'shortid'
import './Cyclereasons.scss'

const Cyclereasons = ({ data, onChange, value }) => {
  const ref = useRef();
  return (
    <div className='select-movecycle control dropdown' 
    ref={ref}
    >
      <div className='select'>
        <select className='is-rounded' onChange={onChange} value={value}>
          <option value='00000000-0000-0000-0000-000000000000' disabled>
            Please Select
          </option>
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map(item => {
              const { Id, Name } = item
              return (
                <option key={s.generate()} value={Id}>
                  {Name}
                </option>
              )
            })}
        </select>
      </div>
    </div>
  )
}

export default Cyclereasons
