import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs, { MenuContext } from 'components/Atoms/TabsButtons'
import Dashboard from 'components/Templates/Dashboard';
import ThroughputChart from 'components/Organisms/ThroughputChart';
import OccupancyChart from 'components/Organisms/OccupancyChart';
import PressCycleChart from 'components/Organisms/PressCycleChart';
import Header from 'components/Atoms/Header';
import './Report.scss';

const History = () => {
    const dispatch = useDispatch()
    const tabs = [{ label: 'Throughput' }, { label: 'Occupancy' }, { label: 'Press Cycle' }]

    return (
        <Dashboard>
            <div className='columns is-multiline homepage pt-5 report'>
                <div className='column is-12'>
                    <Header title='Efficiency Tracker' />
                    <Tabs tabs={tabs} onChange={() => { }}>
                        <MenuContext.Consumer>
                            {isActiveTab => {
                                return (
                                    <>
                                        {
                                            isActiveTab == 0 ?
                                                <ThroughputChart />
                                                :
                                                isActiveTab == 1 ?
                                                    <OccupancyChart />
                                                    :
                                                    <PressCycleChart />
                                        }
                                    </>
                                )
                            }}
                        </MenuContext.Consumer>
                    </Tabs>
                </div>
            </div>
        </Dashboard>
    )
}

export default History
