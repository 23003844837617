import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import './MultiSelectCheckbox.scss'

const MultiSelectCheckbox = ({ label, width, placeholder, options, onChange, value, getDropdownButtonLabel, setState }) => {
    return (
        <div className="field is-horizontal toolbox-report">
            <div className="field-label is-normal">
                <label className="label">{label}: </label>
            </div>
            <div className="field-body">
                <div className="field is-narrow">
                    <div className="control">
                        <div className="toolbox-report is-fullwidth is-small ">
                            <ReactMultiSelectCheckboxes
                                options={[{ label: "All", value: "*" }, ...options]}
                                placeholderButtonLabel={placeholder}
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={value}
                                onChange={onChange}
                                width={width}
                                setState={setState}
                            />
                            {/* <MultiSelect
                            options={options}
                            value={value}
                            onChange={onChange}
                            labelledBy={palceholder}
                        /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiSelectCheckbox
