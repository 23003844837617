import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import SelectMachine from 'components/Molecules/SelectMachine'
import { actions as machineActions } from 'state/slices/machines'
import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'
import { actions as optimizationsPressCycleActions } from 'state/slices/optimizationsPressMachines'
import { actions as machineCycleListActions } from 'state/slices/machineCycleList'

import './CycleItemStatus.scss'
import { ReactComponent as Layer } from 'assets/icons/art_layers.svg'
import editIcon from 'assets/icons/edit-pen.png'
import saveIcon from 'assets/icons/checklist.png'

const CycleItemStatus = ({ item }) => {
    const dispatch = useDispatch()
    const [isEditMachine, setIsEditMachine] = useState(false)
    const [machineId, setMachineId] = useState(false)
    const { responseMachines, skipCountMachineListId, machineListId } = useSelector(state => ({
        responseMachines: state.machines.response.Items,
        skipCountMachineListId: state.machineCycleList.skipCount,
        machineListId: state.machineCycleList.machineId,
    }))
    const {
        ProcessCycle,
        Status,
        Machine,
        ReleasedDate,
        OptimizationDate,
        ReleasedBy,
        Id
    } = item || {}

    let statusText = ''
    switch (Status) {
        case 10:
            statusText = 'ready for optimization'
            break
        case 20:
            statusText = `${Machine && Machine.Name}`
            break
        default:
            break
    }

    useEffect(() => {
        fetchMachine()
        return () => dispatch(machineActions.clear())
    }, [dispatch])

    const fetchMachine = () => {
        const payload = {
            skipCount: 0
        }
        dispatch(machineActions.get(payload))
    }

    const onClickEdit = () => {
        setIsEditMachine(true)
    }

    const onClickSave = async () => {
        const payload = {
            PressCycleId: Id,
            MachineId: machineId,
            MachineName: Machine.Name
        }
        const payloadAction = {
            statuses: [20, 30],
            skipCount: 0,
            maxResultCount: 5
        }
        await dispatch(optimizationsPressMachinesActions.put(payload))
        await dispatch(optimizationsPressCycleActions.get(payloadAction))

        await fetchMachine()

        const payloadMachineListId = {
            skipCount: skipCountMachineListId,
            maxResultCount: 5,
            machineId: responseMachines.length > 0 ? responseMachines[0].Id : machineId
        }
        await dispatch(machineCycleListActions.get(payloadMachineListId))

        setIsEditMachine(false)
        setMachineId(false)
    }

    const onChangeMachine = value => {
        setMachineId(value.currentTarget.value)
    }

    const onClickOutside = () => {
        setIsEditMachine(false)
        setMachineId(null)
    }

    return (
        <div className='media cycleItemStatus'>
            <div className='media-left'>
                <figure className='image is-48x48'>
                    <Layer className='next' />
                </figure>
            </div>
            <div className='media-content'>
                <p
                    className='title is-4'
                    style={{
                        marginTop: isEditMachine ? 0 : 7,
                        paddingBottom: isEditMachine ? 0 : 7
                    }}
                >
                    {Status === 20 && (
                        <>
                            Cycle {ProcessCycle} has been execute to{' '}
                            {isEditMachine ? (
                                <SelectMachine
                                    data={responseMachines}
                                    onChange={item => onChangeMachine(item)}
                                    value={machineId}
                                    onClickOutside={onClickOutside}
                                />
                            ) : (
                                statusText
                            )}{' '}
                            {!isEditMachine ? (
                                <span>
                                    <img
                                        className='edit-icon'
                                        src={editIcon}
                                        onClick={() => onClickEdit()}
                                    />
                                </span>
                            ) : (
                                <span>
                                    <img
                                        className='save-icon'
                                        src={saveIcon}
                                        onClick={() => onClickSave()}
                                    />
                                </span>
                            )}
                        </>
                    )}
                    {Status !== 20 && (
                        <>
                            Cycle {ProcessCycle} is on progress in {Machine && Machine.Name}
                        </>
                    )}
                </p>
                <p className='subtitle is-6'>
                    {moment(Status === 10 ? OptimizationDate : ReleasedDate).format('ll')}
          ,{' '}
                    {moment(Status === 10 ? OptimizationDate : ReleasedDate).format(
                        'HH:mm'
                    )}{' '}
                    {ReleasedBy}
                </p>
            </div>
        </div>
    )
}

export default CycleItemStatus
