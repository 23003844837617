import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dashboard from 'components/Templates/Dashboard'
import DashboardHeader from 'components/Organisms/DashboardHeader'
import ColumnsInfo from 'components/Organisms/ColumnsInfo'
import CyclesData from 'components/Organisms/CyclesData'
import MachineCycleList from 'components/Organisms/MachineCycleListHome'
import StatusInfo from 'components/Organisms/StatusInfo'
import PressCycles from 'components/Organisms/PressCycles'
import CyclesProgress from 'components/Organisms/CyclesProgress'

import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'
import { actions as activitiesActions } from 'state/slices/activities'

import './Home.scss'

const Home = () => {
  const dispatch = useDispatch()

  const {
    responseDashboardSummary,
    responsePressCycles,
    responseActivities,
    responseActivitiesLoading
  } = useSelector(state => ({
    responseDashboardSummary: state.dashboardSummary.response,
    isLoading: state.dashboardSummary.loading,
    responsePressCycles: state.optimizationsPressCycle.response.Items,
    isLoadingPressCycles: state.optimizationsPressCycle.loading,
    responseActivities: state.activities.response,
    responseActivitiesLoading: state.activities.loading
  }))

  const [height, setHeight] = useState('100%')
  const [statusInfo, setStatusInfo] = useState()

  const getHeight = () => {
    setHeight(window.innerHeight + 'px')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getHeight()
    window.addEventListener('resize', getHeight)
    return () => window.removeEventListener('resize', null)
  }, [])

  useEffect(() => {
    dispatch(dashboardSummaryActions.get())
    return () => dispatch(dashboardSummaryActions.clear())
  }, [dispatch])

  useEffect(() => {
    dispatch(activitiesActions.get())
    return () => dispatch(activitiesActions.clear())
  }, [dispatch])

  const changeTab = tab => {
    setStatusInfo(tab)
  }

  return (
    <Dashboard>
      <div className='columns is-multiline homepage pt-5' style={{ height }}>
        <div className='column is-12'>
          <DashboardHeader
            data={responseDashboardSummary}
            bellData={responseActivities}
            bellLoading={responseActivitiesLoading}
          />
          <ColumnsInfo data={responseDashboardSummary} />
          <CyclesData />
          <StatusInfo
            statusInfo={
              statusInfo
                ? statusInfo
                : responsePressCycles && responsePressCycles[0]
            }
          />
          <PressCycles changeTab={data => changeTab(data)} />
          <MachineCycleList/>
          <CyclesProgress />
        </div>
      </div>
    </Dashboard>
  )
}

export default Home
