import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'connections/restApi';

export const getMonthly = createAsyncThunk(
    'occupancyReport/getMonthly',
    async payload => {
        const response = await api.getOccupancyMonthlyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].Machines;
            var data = response[i].Data;

            series.push({ name: name, type: 'line', data: data });
        }

        return series;
    }
)

export const getWeekly = createAsyncThunk(
    'occupancyReport/getWeekly',
    async payload => {
        const response = await api.getOccupancyWeeklyReport(payload);

        var series = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i].Machines;
            var data = response[i].Data;
            var titles = response[i].Titles;
            var weeks = response[i].Weeks;

            series.push({ name: name,  data: data, weeks: weeks, titles: titles });
        }

        return series;
    }
)