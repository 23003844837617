import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import FormInput from 'components/Molecules/FormInput'
import { actions as machineActions } from 'state/slices/machines'

import './ModalSettingMachine.scss'

const validationSchema = object({
  Code: string().required('Required'),
  Name: string().required('Required')
})

const validationSchemaEdit = object({
  Code: string().required('Required'),
  Name: string().required('Required')
})

const initialValues = {
  Code: '',
  Name: ''
}

const ModalSettingMachine = ({
  isOpen,
  onClose,
  isDelete,
  isCreteNew,
  item
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)

  const properInitialValues = currentValue => {
    const newObj = { ...initialValues }

    Object.entries(newObj).forEach(([key, val]) => {
      newObj[key] = (currentValue && currentValue[key]) || val
    })

    return newObj
  }
  const onClickCreateMachine = async data => {
    setIsLoading(true)
    const value = {
      Code: data,
      Name: data
    }
    await dispatch(machineActions.post(value))
    setIsLoading(false)
    onClose()
  }
  const onClickDeleteMachine = async Id => {
    setIsLoading(true)
    await dispatch(machineActions.destroy(Id))
    setIsLoading(false)
    onClose()
  }
  const { Name } = item || {}
  return (
    <Modal
      haveClose
      isLarge
      isOpen={isOpen}
      onClose={onClose}
      title={
        isDelete
          ? 'Delete Machine'
          : isCreteNew
          ? 'Create new machine'
          : 'Edit machine'
      }
    >
      {isDelete ? (
        <section className={cx('modal-card-body')}>
          <p className={cx('pra')}>
            {`Are you sure you want to delete ${Name}?`}
          </p>
          <div className='field'>
            <div className='control'>
              <button
                className={cx('button is-warning', {
                  'is-loading': isLoading
                })}
                type='submit'
                onClick={() => onClickDeleteMachine(item)}
              >
                Yes
              </button>
            </div>
          </div>
        </section>
      ) : isCreteNew ? (
        <section className={cx('modal-card-body')}>
          <p className={cx('pra')}>
            {`Are you sure you want to add new machine ${Name}?`}
          </p>
          <div className='field'>
            <div className='control'>
              <button
                className={cx('button is-primary', {
                  'is-loading': isLoading
                })}
                type='submit'
                onClick={() => onClickCreateMachine(Name)}
              >
                Yes
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section className={cx('modal-card-body')}>
          <Formik
            enableReinitialize
            initialValues={properInitialValues(item)}
            validationSchema={
              item === null ? validationSchema : validationSchemaEdit
            }
            onSubmit={async values => {
              setIsLoading(true)
              const { Id } = item
              values.Id = Id

              await dispatch(machineActions.put(values))
              setIsLoading(false)
              onClose()
            }}
          >
            {() => {
              return (
                <Form>
                  <FormInput name='Name' label='Name' isRequired />
                  <FormInput name='Code' label='Code' isRequired />
                  <div className='field'>
                    <div className='control'>
                      <button
                        className={cx('button is-primary', {
                          'is-loading': isLoading
                        })}
                        type='submit'
                      >
                        {`${item === null ? 'Create new' : 'Save'}`}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </section>
      )}
    </Modal>
  )
}
ModalSettingMachine.defaultProps = {
  isDelete: false,
  isCreteNew: false
}

export default ModalSettingMachine
