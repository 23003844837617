import React from 'react'
import Switch from 'react-switch'

const SwitchButton = ({
  field,
  label,
  data,
  isChecked,
  form,
  className,
  type,
  onChange,
  ...props
}) => {
  return (
    <div className='control'>
      <label>
        <span style={{ marginRight: 20, fontSize: 15 }}>{label}</span>
        <span style={{ verticalAlign: 'middle' }}>
          <Switch
            onChange={onChange}
            checked={isChecked}
            handleDiameter={28}
            borderRadius={6}
            offColor='#0066CC'
            onColor='#003366'
            width={100}
            height={40}
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 15,
                  color: 'white',
                  paddingRight: 2
                }}
              >
                High
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 15,
                  color: 'white',
                  paddingRight: 2
                }}
              >
                Low
              </div>
            }
          />
        </span>
      </label>
      {/* <input
        {...props}
        {...field}
        className={cx('input', className, isError)}
        type={type}
      /> */}
    </div>
  )
}

export default SwitchButton
