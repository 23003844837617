import React, { useState, useEffect } from 'react'
import LoginForm from 'components/Organisms/LoginForm'
import ImgLogo from 'assets/images/logo-sanmina.png'
import OptipediaLogo from 'assets/icons/optipedia.png'
import './Login.scss'

const Login = () => {
    const [height, setHeight] = useState('100%')

    const getHeight = () => {
        setHeight(window.innerHeight + 'px')
    }

    useEffect(() => {
        getHeight()
        window.addEventListener('resize', getHeight)
        return () => window.removeEventListener('resize', null)
    }, [])

    return (
        <div className='loginPage'>
            <div className='columns is-gapless is-multiline is-desktop'>
                <div className='column is-8 bgImg' />
                <div className='column is-4 customBgForm' style={{ height }}>
                    <div className='has-text-centered customMt'>
                        <img className="logo" src={ImgLogo} />
                        <h3 className='is-size-4 has-text-black'>Welcome !</h3>
                        <h4 className='is-size-5 has-text-light'>Sign in to your account</h4>
                    </div>
                    <LoginForm />
                    <div className="has-text-centered powerby">
                        &copy; 2021 InnoArk Pte Ltd
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
