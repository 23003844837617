import { createSlice } from '@reduxjs/toolkit';
import { get, getYearlyReport, getQuarterlyReport, getDailyReport, getWeeklyReport } from 'state/thunks/throughputReport';

const initialState = {
    response: [],
    yearlyData: [],
    quarterlyData: [],
    dailyData: [],
    weeklyData: [],
    loading: false,
    success: false,
    error: null
}

const throughputReportSlice = createSlice({
    name: 'throughputReport',
    initialState,
    reducers: {
        clear: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(get.pending, state => {
            state.loading = true
        })

        builder.addCase(get.fulfilled, (state, action) => {
            state.response = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(get.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getYearlyReport.pending, state => {
            state.loading = true
        })

        builder.addCase(getYearlyReport.fulfilled, (state, action) => {
            state.yearlyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getYearlyReport.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getQuarterlyReport.pending, state => {
            state.loading = true
        })

        builder.addCase(getQuarterlyReport.fulfilled, (state, action) => {
            state.quarterlyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getQuarterlyReport.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getDailyReport.pending, state => {
            state.loading = true
        })

        builder.addCase(getDailyReport.fulfilled, (state, action) => {
            state.dailyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getDailyReport.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getWeeklyReport.pending, state => {
            state.loading = true
        })

        builder.addCase(getWeeklyReport.fulfilled, (state, action) => {
            state.weeklyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getWeeklyReport.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })
    }
})

export const actions = {
    ...throughputReportSlice.actions,
    get,
    getYearlyReport,
    getQuarterlyReport,
    getDailyReport,
    getWeeklyReport
}

export const reducer = throughputReportSlice.reducer;
