import React, { useState, createContext } from 'react'
import { number, shape, arrayOf, string } from 'prop-types'
import MachineItem from '../MachineItem'

const MenuContext = createContext({
  tabIndex: null
})

const Machines = ({ machines, onClick }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const changeMachine = (index, Name, Id) => {
    setActiveIndex(index)
    onClick(Name, Id)
  }
  return (
    <MenuContext.Provider value={activeIndex}>
      <>
        <div className='columns is-multiline releaseForm'>
          {Array.isArray(machines) &&
            machines.length > 0 &&
            machines.map((machine, index) => {
              const { Name, Id, RemainingCycle } = machine
              const activeMachine = activeIndex === index
              return (
                <MachineItem
                  isActive={activeMachine}
                  onClick={() => changeMachine(index, Name, Id)}
                  key={index}
                  remainingCycle={RemainingCycle}
                >
                  <h3>{Name}</h3>
                  <h4>{`${RemainingCycle} Cycle(s) in queue`}</h4>
                </MachineItem>
              )
            })}
        </div>
      </>
    </MenuContext.Provider>
  )
}

Machines.defaultProps = {
  machines: [],
  tabIndexActive: 0
}

Machines.propTypes = {
  machines: arrayOf(
    shape({
      label: string
    })
  ),
  tabIndexActive: number
}

export default Machines
export { MenuContext }
