import { createSlice } from '@reduxjs/toolkit'

import { editnoofbooktobeutilized } from 'state/thunks/editnoofbooktobeutilized'

const initialState = {
  response: {},
  loading: false,
  success: false,
  error: null
}

const editnoofbooktobeutilizedSlice = createSlice({
  name: 'editnoofbooktobeutilized',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(editnoofbooktobeutilized.pending, state => {
      state.loading = true
    })

    builder.addCase(editnoofbooktobeutilized.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(editnoofbooktobeutilized.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...editnoofbooktobeutilizedSlice.actions,
  editnoofbooktobeutilized
}

export const reducer = editnoofbooktobeutilizedSlice.reducer
