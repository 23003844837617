import { createSlice } from '@reduxjs/toolkit'

import { get, download } from 'state/thunks/activity'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  loading: false,
  success: false,
  error: null
}

const activitySlice = createSlice({
  name: 'activitySlice',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...activitySlice.actions,
  get,
  download
}

export const reducer = activitySlice.reducer
