import React from 'react'

import SwitchButton from 'components/Atoms/SwitchButton'
import './WipSelection.scss'

const WipSelection = ({ data, isChecked, onChange }) => {
  return (
    <div className='card wipSelection'>
      <header className='card-header'>
        <p className='card-header-title'>WIP Selection</p>
      </header>
      <div className='card-content'>
        <div className='content'>
          <SwitchButton
            isChecked={isChecked}
            onChange={() => onChange()}
            label='Select your WIP Algorithm'
            data={data}
          />
        </div>
      </div>
    </div>
  )
}

export default WipSelection
