import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Dashboard from 'components/Templates/Dashboard'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'
import Header from 'components/Atoms/Header'
import CycleDetail from 'components/Molecules/CycleDetail'
import { HeadDetailCycleProgress } from 'constants/cycleData'
import { actions as optimizationsCyclesProgressActions } from 'state/slices/optimizationsCyclesProgress'
import { actions as machinesActions } from 'state/slices/machines'

import dotImg from 'assets/icons/text_dot.svg'

import './History.scss'

const History = () => {
    const dispatch = useDispatch()
    const [height, setHeight] = useState('100%')
    const [page, setPage] = useState(1)
    const [maxResultCount] = useState(5)
    const [skipCount] = useState(1)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [filterByMachine, setFilterByMachine] = useState(null)

    const getHeight = () => {
        setHeight(window.innerHeight + 'px')
    }

    const {
        responseoptimizationsCyclesProgress,
        totalCount,
        isLoading,
        responseMachines
    } = useSelector(state => ({
        responseoptimizationsCyclesProgress:
            state.optimizationsCyclesProgress.response.Items,
        isLoading: state.optimizationsCyclesProgress.loading,
        totalCount: state.optimizationsCyclesProgress.response.TotalCount,
        responseMachines: state.machines.response.Items
    }))

    useEffect(() => {
        const payload = {
            skipCount: 0
        }
        dispatch(machinesActions.get(payload))
        return () => dispatch(machinesActions.clear())
    }, [dispatch])

    const fetchData = async page => {
        const skipCount = (page - 1) * maxResultCount
        const payload = {
            priority: null,
            status: null,
            statuses: [20, 30, 40],
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            searchKeyword: searchKeyword,
            machineId: filterByMachine
        }
        await dispatch(optimizationsCyclesProgressActions.get(payload))
        setPage(page)
        return () => dispatch(optimizationsCyclesProgressActions.clear())
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchData(1)
    }, [dispatch])

    useEffect(() => {
        getHeight()
        window.addEventListener('resize', getHeight)
        return () => window.removeEventListener('resize', null)
    }, [])

    const handleChangePage = async e => {
        const page = e.target.value !== '' ? parseInt(e.target.value) : 0
        setPage(parseInt(page))
        if (page !== 0 && e.key === 'Enter') {
            fetchData(page)
        }
    }
    const onClickPrevNext = async page => {
        await fetchData(page)
        window.scrollTo(0, document.body.scrollHeight)
    }

    const onChangeSearchKeyword = async e => {
        setSearchKeyword(e.target.value)
        const skipCount = (1 - 1) * maxResultCount
        const payload = {
            priority: null,
            status: null,
            statuses: [20, 30, 40],
            skipCount,
            maxResultCount: maxResultCount,
            searchKeyword: e.target.value,
            machineId: filterByMachine
        }
        await debouncedRefreshList(payload)
    }

    const debouncedRefreshList = useMemo(
        () =>
            debounce(args => {
                setPage(1)
                return dispatch(optimizationsCyclesProgressActions.get(args))
            }, 1500),
        [dispatch]
    )

    const onChangeFilterByMachineId = async e => {
        setFilterByMachine(e.target.value)
        const skipCount = (1 - 1) * maxResultCount
        const payload = {
            priority: null,
            status: null,
            statuses: [20, 30, 40],
            skipCount,
            maxResultCount: maxResultCount,
            searchKeyword: searchKeyword,
            machineId: e.target.value
        }
        await debouncedRefreshList(payload)
    }

    return (
        <Dashboard>
            <div
                className='columns is-multiline homepage pt-5 history'
                style={{ height }}
            >
                <div className='column is-12'>
                    <Header
                        title='Optimization Cycles History'
                        isHaveSearchBar
                        isHaveFilter
                        isLoading={isLoading}
                        dataOptions={responseMachines}
                        onChange={e => onChangeSearchKeyword(e)}
                        onChangeFilterDropdown={e => onChangeFilterByMachineId(e)}
                        filterByMachine={filterByMachine}
                    />
                    {responseoptimizationsCyclesProgress.length > 0 &&
                        responseoptimizationsCyclesProgress.map((item, index) => {
                            const {
                                PressTypeString,
                                Books,
                                Algorithm,
                                ReleasedBy,
                                ReleasedDate,
                                Machine
                            } = item
                            const { Name } = Machine || {}
                            return (
                                <div key={index} style={{ marginBottom: 40 }}>
                                    <div className='rightInfo'>
                                        <span>
                                            {Name}{' '}
                                            <span>
                                                <img className='dotImg' src={dotImg} />
                                            </span>{' '}
                                            {PressTypeString}{' '}
                                            <span>
                                                <img className='dotImg' src={dotImg} />
                                            </span>{' '}
                                            {Algorithm}{' '}
                                            <span>
                                                <img className='dotImg' src={dotImg} />
                                            </span>{' '}
                                            {`Executed by ${ReleasedBy}`}{' '}
                                            <span>
                                                <img className='dotImg' src={dotImg} />
                                            </span>{' '}
                                            {`${moment(ReleasedDate).format('ll - HH:mm')}`}
                                        </span>
                                    </div>
                                    <CycleDetail
                                        data={Books}
                                        processCycle={item}
                                        headData={HeadDetailCycleProgress}
                                        isShowTreeDot={false}
                                    />
                                </div>
                            )
                        })}
                    <TableFooterPagination
                        data={totalCount}
                        page={parseInt(page)}
                        totalData={responseoptimizationsCyclesProgress.length}
                        maxResultCount={maxResultCount}
                        skipCount={skipCount}
                        handleChangePage={handleChangePage}
                        onClickPrevNext={page => onClickPrevNext(page)}
                        classNames="margin-bottom-50px"
                    />
                </div>
            </div>
        </Dashboard>
    )
}

export default History
