import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import TabsCyrclesDataPriority from 'components/Molecules/TabsCyrclesDataPriority'
import TableFooterPagination from 'components/Molecules/TableFooterPagination'
import ReleaseModal from 'components/Molecules/ReleaseModal'

import SearchBar from 'components/Atoms/SearchBar'

import { actions as optimizationsPressMachinesActions } from 'state/slices/optimizationsCycleData'
import { actions as optimizationsDataToBeUpdatedActions } from 'state/slices/optimizationsDataToBeUpdated'

import { actions as machineActions } from 'state/slices/machines'

import { actions as machineCycleListActions } from 'state/slices/machineCycleList'

import { actions as releaseActions } from 'state/slices/release'

import { HeadTable } from 'constants/cycleData'

import { ReactComponent as ButtonMagnifier } from 'assets/icons/button_magnifier.svg'

import { ReactComponent as ButtonCancel } from 'assets/icons/cancel.svg'

import './CyclesData.scss'

const CyclesData = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [maxResultCount] = useState(5)
  const [tab, setTab] = useState(1)
  const [skipCount, setSkipCount] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [isReleaseModalOpen, setReleaseModalOpen] = useState(false)
  const [releaseInfo, setReleaseInfo] = useState({})
  const [onSearchActive, setOnSearchActive] = useState(false)

  const {
    responseOptimizationsPressMachines,
    responseOptimizationsDataToBeUpdated,
    totalCountOptimizationsDataToBeUpdated,
    totalCount,
    isLoading,
    
  } = useSelector(state => ({
    responseOptimizationsPressMachines:
      state.optimizationsCycleData.response.Items,
    totalCount: state.optimizationsCycleData.response.TotalCount,
    isLoading: state.optimizationsCycleData.loading,
    responseOptimizationsDataToBeUpdated:
      state.optimizationsDataToBeUpdated.response.Items,
    totalCountOptimizationsDataToBeUpdated:
      state.optimizationsDataToBeUpdated.response.TotalCount,
    responsePreRelease: state.preRelease,
      
  }))

  useEffect(() => {
    const payload = {
      priority: 1,
      status: 10,
      skipCount: 0,
      maxResultCount: maxResultCount
    }
    dispatch(optimizationsPressMachinesActions.get(payload))
    return () => dispatch(optimizationsPressMachinesActions.clear())
  }, [dispatch])

  const onChangeTab = e => {
    let tabIndex = tab
    switch (e) {
      case 0:
        tabIndex = 1
        break
      case 1:
        tabIndex = 2
        break
      case 2:
        tabIndex = 3
        break
      case 3:
        tabIndex = 4
        break
    }

    let payload = {}
    // dispatch(machineCycleListActions.clear())
    if (tabIndex !== 4) {
      payload = {
        priority: tabIndex,
        status: 10,
        skipCount: 0,
        maxResultCount: maxResultCount,
        searchKeyword
      }
      dispatch(optimizationsPressMachinesActions.get(payload))
    } else {
      payload = {
        skipCount: 0,
        maxResultCount: maxResultCount
      }
      dispatch(optimizationsDataToBeUpdatedActions.get(payload))
    }
    setSkipCount(0)
    setPage(1)
    setTab(tabIndex)
    return () => dispatch(optimizationsPressMachinesActions.clear())
  }

  const fetchOptimization = async page => {
    let payload = {}
    const skipCount = (page - 1) * maxResultCount
    if (tab !== 4) {
      payload = {
        priority: tab,
        status: 10,
        skipCount: skipCount,
        maxResultCount: maxResultCount,
        searchKeyword
      }
      await dispatch(optimizationsPressMachinesActions.get(payload))
    } else {
      payload = {
        skipCount: skipCount,
        maxResultCount: maxResultCount
      }
      await dispatch(optimizationsDataToBeUpdatedActions.get(payload))
    }

    setPage(page)
  }

  const onClickPrevNext = async page => {
    const skipCount = (page - 1) * maxResultCount
    fetchOptimization(page)
    setSkipCount(skipCount)
  }

  const handleChangePage = async e => {
    const page = e.target.value !== '' ? parseInt(e.target.value) : 0
    setPage(parseInt(page))
    if (page !== 0 && e.key === 'Enter') {
      const skipCount = (page - 1) * maxResultCount
      fetchOptimization(page)
      setSkipCount(skipCount)
    }
  }

  const onClickButtonRelease = async data => {
    const { Id } = data
    console.log("machine must open")
    const payload = {
      skipCount: 0
    }
    await dispatch(machineActions.get(payload))
    await dispatch(releaseActions.preRelease(Id))
    await setReleaseModalOpen(true)
    setReleaseInfo(data)
  }
  const handleClose = async () => {
    setReleaseModalOpen(false)
    fetchOptimization(page)
  }

  const { me } = useSelector(state => ({
    me: state.users.me
  }))
  const { RoleName } = me
  const isHaveAccess =
    RoleName === 'Administrator' ||
    RoleName === 'SuperEditor' ||
    RoleName === 'Editor'

  const onChangeSearch = e => {
    const skipCount = (1 - 1) * maxResultCount
    const payload = {
      priority: tab,
      statuses: [10],
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      searchKeyword: e.target.value
    }
    setSearchKeyword(e.target.value)
    debouncedRefreshList(payload)
  }

  const debouncedRefreshList = useMemo(
    () =>
      debounce(args => {
        setPage(1)
        return dispatch(optimizationsPressMachinesActions.get(args))
      }, 1500),
    [dispatch]
  )

  const cancelButton = async () => {
    setSearchKeyword('')
    setOnSearchActive(false)
    let payload = {}
    const skipCount = (1 - 1) * maxResultCount
    payload = {
      priority: tab,
      status: 10,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      searchKeyword: ''
    }
    await dispatch(optimizationsPressMachinesActions.get(payload))
    setPage(1)
  }

  return (
    <div className='columns is-multiline mb-3 cyclesData'>
      <div className='column is-12'>
        <div className='card card-custom'>
          <header className='card-header'>
            <p className='card-header-title is-size-5 has-text-weight-bold'>
              Cycles Data
            </p>
            {tab !== 4 && (
              <div className='card-header-icon'>
                {onSearchActive && (
                  <div className='searchInput'>
                    <span style={{ width: '90%' }}>
                      <SearchBar
                        value={searchKeyword}
                        placeholder='Search'
                        onChange={e => onChangeSearch(e)}
                        isLoading={isLoading}
                      />
                    </span>
                    <ButtonCancel
                      className='cancelbutton'
                      fill='#11141A'
                      onClick={() => {
                        cancelButton()
                      }}
                    />
                  </div>
                )}

                {!onSearchActive && (
                  <ButtonMagnifier
                    className='search'
                    fill='#11141A'
                    onClick={() => setOnSearchActive(true)}
                  />
                )}
              </div>
            )}
          </header>
          <div className='card-content'>
            <TabsCyrclesDataPriority
              onChange={e => onChangeTab(e)}
              dataTable={
                tab !== 4
                  ? responseOptimizationsPressMachines
                  : responseOptimizationsDataToBeUpdated
              }
              headTable={HeadTable}
              isLoading={isLoading}
              buttonRelease={e => onClickButtonRelease(e)}
              isHaveAccess={isHaveAccess}
              skipCount={skipCount}
              searchKeyword={searchKeyword}
            />
          </div>
          <TableFooterPagination
            data={
              tab !== 4 ? totalCount : totalCountOptimizationsDataToBeUpdated
            }
            page={parseInt(page)}
            totalData={
              tab !== 4
                ? responseOptimizationsPressMachines.length
                : responseOptimizationsDataToBeUpdated.length
            }
            maxResultCount={maxResultCount}
            skipCount={skipCount}
            handleChangePage={handleChangePage}
            onClickPrevNext={page => onClickPrevNext(page)}
          />
        </div>
      </div>
      {isReleaseModalOpen && (
        <ReleaseModal
          isOpen={isReleaseModalOpen}
          onClose={handleClose}
          data={releaseInfo}
          searchKeyword={searchKeyword}
          priority={tab}
        />
      )}
    </div>
  )
}

export default CyclesData
