import { createSlice } from '@reduxjs/toolkit'

import { preRelease, release } from 'state/thunks/release'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  preRelease: {},
  loading: false,
  success: false,
  error: null
}

const preReleaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(preRelease.pending, state => {
      state.loading = true
    })

    builder.addCase(preRelease.fulfilled, (state, action) => {
      state.preRelease = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(preRelease.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })

    builder.addCase(release.pending, state => {
      state.loading = true
    })

    builder.addCase(release.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(release.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...preReleaseSlice.actions,
  preRelease,
  release
}

export const reducer = preReleaseSlice.reducer
