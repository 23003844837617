import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from 'components/Templates/Dashboard'
import Header from 'components/Atoms/Header'

import WipSelection from 'components/Organisms/WipSelection'

import MachineMaster from 'components/Organisms/MachineMaster'
import WipModal from 'components/Molecules/WipModal'

import { actions as wipActions } from 'state/slices/wip'
import { actions as dashboardSummaryActions } from 'state/slices/dashboardSummary'

import './Settings.scss'

const Settings = () => {
  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState(false)
  const [isWipModalOpen, setIsWipModalOpen] = useState(false)

  const { responseWip } = useSelector(state => ({
    responseWip: state.wip.response,
    isLoading: state.wip.loading
  }))

  const fetchApiGetWip = async () => {
    const response = await dispatch(wipActions.get())
    const { payload } = response || {}
    const { Logic } = (payload && payload.response) || {}
    const IsSetChecked = Logic === 20
    setIsChecked(IsSetChecked)
    return () => dispatch(wipActions.clear())
  }

  useEffect(() => {
    fetchApiGetWip()
  }, [dispatch])

  const onChange = () => {
    setIsWipModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsWipModalOpen(false)
  }
  const confirmChange = async () => {
    const type = responseWip.Logic === 10 ? 'lowwip' : 'highwip'
    setIsWipModalOpen(false)
    await dispatch(wipActions.put({ type }))
    fetchApiGetWip()
    dispatch(dashboardSummaryActions.get())
  }
  const { me, isLoading } = useSelector(state => ({
    me: state.users.me,
    isLoading: state.users.loading
  }))
  const { RoleName } = me
  const isHaveAccess =
    RoleName === 'Administrator' ||
    RoleName === 'Editor' ||
    RoleName === 'SuperEditor' ||
    RoleName === 'SuperUser'

  return (
    <Dashboard>
      <div className='columns is-multiline homepage pt-5'>
        <div className='column is-12'>
          <Header title='Settings' />
          {!isLoading && isHaveAccess && (
            <>
              <WipSelection
                isChecked={isChecked}
                onChange={() => isHaveAccess && onChange()}
                label='Select your WIP Algorithm'
                data={responseWip}
              />
              <MachineMaster />
            </>
          )}
          {!isLoading && !isHaveAccess && (
            <div>
              <p>
                Sorry you do not have access to open this page, please contact
                the administrator
              </p>
            </div>
          )}
        </div>
      </div>
      {isWipModalOpen && (
        <WipModal
          isOpen={isWipModalOpen}
          onClose={handleCloseModal}
          confirmChange={confirmChange}
        />
      )}
    </Dashboard>
  )
}

export default Settings
