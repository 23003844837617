import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'connections/restApi';

export const get = createAsyncThunk(
    'reportFilterMachine/get',
    async payload  => {
        const response = await api.getReportFilterMachines();

        var result = [];
        for (var i = 0; i < response.length; i++) {
            var name = response[i];
            result.push({ id: name, label: name, value: name })
        }

        return result;
    }
)
