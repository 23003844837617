import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'connections/restApi';

export const get = createAsyncThunk(
    'reportYear/get',
    async payload => {
        const response = await api.getReportYears(payload);

        var years = [];
        for (var i = 0; i < response.length; i++) {
            var id = response[i];
            var name = response[i];

            years.push({ Id: id, Name: name });
        }

        return years;
    }
)
