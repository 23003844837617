import React from 'react'
import cx from 'classnames'
import TableBody from 'components/Atoms/TableBody'
import Book from 'components/Molecules/Book'

import './TableCycleItemDataToBeUpdated.scss'

const TableCycleItemDataToBeUpdated = ({ data, onClick, isCollapseFor }) => {
  return (
    <>
      {Array.isArray(data) &&
        data.length > 0 &&
        data.map((item, i) => {
          const { LotNumber, ItemNumber, Issue } = item || {}
          return (
            <>
              <TableBody className={cx('card cursorpointer')} key={i}>
                <>
                  <tr>
                    <th className='dark-text' width='20%'>
                      {LotNumber}
                    </th>
                    <th width='20%'>{ItemNumber}</th>
                    <th>{Issue}</th>
                  </tr>
                </>
              </TableBody>
              <div className='m-5' />
            </>
          )
        })}
    </>
  )
}

export default TableCycleItemDataToBeUpdated
