import React from 'react'
import Tabs, { MenuContext } from 'components/Atoms/TabsButtons'
import TableCycleData from 'components/Organisms/TableCycleData'
import TableCycleDataToBeUpdated from 'components/Organisms/TableCycleDataToBeUpdated'

const data = [
  {
    label: 'Priority 1'
  },
  {
    label: 'Priority 2'
  },
  {
    label: 'Priority 3'
  },
  {
    label: 'Data to be updated'
  }
]

const TabsCyrclesDataPriority = ({
  isLoading,
  onChange,
  headTable,
  dataTable,
  buttonRelease,
  isHaveAccess,
  skipCount,
  searchKeyword
}) => {
  const clickButtonRelease = e => {
    buttonRelease(e)
  }
  return (
    <Tabs tabs={data} onChange={onChange}>
      <MenuContext.Consumer>
        {index => {
          return (
            <>
              {index === 0 && (
                <TableCycleData
                  data={dataTable}
                  tHeadData={headTable}
                  isLoading={isLoading}
                  buttonRelease={data => clickButtonRelease(data)}
                  isHaveAccess={isHaveAccess}
                  skipCount={skipCount}
                  searchKeyword={searchKeyword}
                />
              )}
              {index === 1 && (
                <TableCycleData
                  data={dataTable}
                  tHeadData={headTable}
                  isLoading={isLoading}
                  buttonRelease={data => clickButtonRelease(data)}
                  isHaveAccess={isHaveAccess}
                  skipCount={skipCount}
                  searchKeyword={searchKeyword}
                />
              )}
              {index === 2 && (
                <TableCycleData
                  data={dataTable}
                  tHeadData={headTable}
                  isLoading={isLoading}
                  buttonRelease={data => clickButtonRelease(data)}
                  isHaveAccess={isHaveAccess}
                  skipCount={skipCount}
                  searchKeyword={searchKeyword}
                />
              )}
              {index === 3 && (
                <TableCycleDataToBeUpdated
                  data={dataTable}
                  tHeadData={headTable}
                  isLoading={isLoading}
                />
              )}
            </>
          )
        }}
      </MenuContext.Consumer>
    </Tabs>
  )
}

export default TabsCyrclesDataPriority
