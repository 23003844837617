import React from 'react'
import Tabs, { MenuContext } from 'components/Atoms/TabsButtons'
import CyclesProgressRelease from 'components/Organisms/CyclesProgressRelease'

const data = [
  {
    label: 'Execute'
  },
  {
    label: 'Processing'
  },
  {
    label: 'Success'
  }
]

const TabsCyrclesProgress = ({ onChange, dataTable }) => {
  return (
    <>
      <Tabs tabs={data} onChange={onChange}>
        <MenuContext.Consumer>
          {status => {
            return (
              <div>
                <CyclesProgressRelease items={dataTable} status={status} />
              </div>
            )
          }}
        </MenuContext.Consumer>
      </Tabs>
    </>
  )
}

export default TabsCyrclesProgress
