import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'
import Cookies from 'universal-cookie'

import * as api from 'connections/restApi'

const cookies = new Cookies()

export const login = createAsyncThunk(
  'auth/login',
  async (params, { rejectWithValue }) => {
    try {
      const result = await api.login(params)
      const { access_token, expired_in, issues_in, token_type } = result
      const cookieParams = {
        path: '/',
        secure: false,
        // httpOnly: true,
        sameSite: 'strict'
      }
      cookies.set('sanmina_dashboard_token', access_token, cookieParams)
      return {
        expired_in,
        issues_in,
        token_type
      }
    } catch (error) {
      toastr.error(
        '',
        'You have entered an invalid username or password. Please try again.'
      )
      return rejectWithValue(error.message)
    }
  }
)
