// @flow
import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import s from 'shortid'

import './DropdownWithId.scss'

const DropdownWithId = ({
  field,
  form,
  data,
  className,
  placeholder,
  label,
  ...props
}) => {
  const { name } = field
  const { touched, errors } = form

  const isError = {
    'is-danger': get(touched, name, null) && get(errors, name, null)
  }

  return (
    <div className='control dropdown'>
      <div className={cx('select', isError, className)}>
        <select {...props} {...field} className='is-rounded'>
          <option value='00000000-0000-0000-0000-000000000000' disabled>
            {placeholder}
          </option>
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map(item => {
              const { Id, Name } = item
              return (
                <option key={s.generate()} value={Id}>
                  {Name}
                </option>
              )
            })}
        </select>
      </div>
    </div>
  )
}

DropdownWithId.defaultProps = {
  className: ''
}

export default DropdownWithId
