import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'
import FormInput from 'components/Molecules/FormInput'
import FormDropdown from 'components/Molecules/FormDropdown'
import { actions as rolesActions } from 'state/slices/roles'
import { actions as userActions } from 'state/slices/users'

import './UserModalForm.module.scss'

const status = ['Active', 'Inactive']

const validationSchema = object({
  UserName: string().required('Required'),
  Email: string()
    .email('Invalid email address')
    .required('Required'),
  Status: string(),
  Role: string().required('Required'),
  Password: string().required('Required'),
  ConfirmPassword: string().required('Required')
})

const validationSchemaEdit = object({
  UserName: string().required('Required'),
  Email: string()
    .email('Invalid email address')
    .required('Required'),
  Status: string(),
  Role: string().required('Required')
})

const initialValues = {
  UserName: '',
  Email: '',
  Role: '',
  Status: '',
  Password: '',
  ConfirmPassword: ''
}

const UserModalForm = ({ isOpen, onClose, userDetail }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(0)
  const { roles } = useSelector(state => ({
    roles: state.roles.data.response
  }))

  useEffect(() => {
    dispatch(rolesActions.get())
    return () => dispatch(rolesActions.clear())
  }, [dispatch])

  const properInitialValues = currentValue => {
    const newObj = { ...initialValues }

    Object.entries(newObj).forEach(([key, val]) => {
      newObj[key] = (currentValue && currentValue[key]) || val
    })

    return newObj
  }
  return (
    <Modal
      haveClose
      isLarge
      isOpen={isOpen}
      onClose={onClose}
      title={`${userDetail === null ? 'Create new user' : 'Edit user'}`}
    >
      <section className={cx('modal-card-body')}>
        <Formik
          enableReinitialize
          initialValues={properInitialValues(userDetail)}
          validationSchema={
            userDetail === null ? validationSchema : validationSchemaEdit
          }
          onSubmit={async values => {
            setIsLoading(true)
            if (userDetail === null) {
              await dispatch(userActions.create(values))
            }
            if (userDetail !== null) {
              const { Id } = userDetail
              values.Id = Id
              await dispatch(userActions.edit(values))
            }
            setIsLoading(false)
            onClose()
          }}
        >
          {() => {
            return (
              <Form>
                <FormInput
                  name='UserName'
                  label='Username'
                  isRequired
                  autoComplete
                />
                <FormInput
                  name='Email'
                  label='Email'
                  type='email'
                  isRequired
                  autoComplete
                />
                {userDetail && (
                  <FormDropdown name='Status' label='Status' data={status} />
                )}
                <FormDropdown name='Role' label='Role' data={roles} />

                {userDetail === null && (
                  <>
                    <FormInput
                      name='Password'
                      label='Password'
                      type='password'
                      autoComplete
                      isRequired
                    />
                    <FormInput
                      name='ConfirmPassword'
                      label='Confirm Password'
                      type='password'
                      autoComplete
                      isRequired
                    />
                  </>
                )}

                <div className='field'>
                  <div className='control'>
                    <button
                      className={cx('button is-primary', {
                        'is-loading': isLoading
                      })}
                      type='submit'
                    >
                      {`${userDetail === null ? 'Create new' : 'Save'}`}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </section>
    </Modal>
  )
}

export default UserModalForm
