import React from 'react'
import './Card.scss'

const Card = ({ cardImage, content, children, className }) => {
  return <div className='card'>{children}</div>
}

Card.defaultProps = {
  cardImage: false,
  content: false
}

export default Card
