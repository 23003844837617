import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { actions as pressCycleReportActions } from 'state/slices/pressCycleReport';
import { actions as reportYearActions } from 'state/slices/reportYear';
import { actions as reportFilterMachineActions } from 'state/slices/reportFilterAllMachine';
import DateObject from "react-date-object";
import ReactApexChart from 'react-apexcharts';
import Swal from 'sweetalert2';
import InputDatePicker from 'components/Atoms/InputDatePicker';
import FilterYearDropdown from 'components/Atoms/FilterYearDropdown';
import FilterPressTypeDropdown from 'components/Atoms/FilterPressTypeDropdown';
import FilterReportDropdown from 'components/Atoms/FilterReportDropdown';
import MultiSelectCheckbox from 'components/Atoms/MultiSelectCheckbox';
import Loading from 'components/Atoms/Loading';
import { DisplayReport } from 'constants/pressCycleReport';
import { PressType } from 'constants/throughputReport'

import './PressCycleChart.scss';

const PressCycleChart = () => {
    const dispatch = useDispatch();
    const defaultYear = new Date().getFullYear();
    const [currentLoading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(defaultYear);
    const [currentPressType, setCurrentPressType] = useState(defaultYear);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [currentDisplayReport, setCurrentDisplay] = useState('Weekly');
    const [currentDateFrom, setCurrentDateFrom] = useState(new Date(`October 01, ${currentYear - 1} 00:00:00`));
    const [currentDateTo, setCurrentDateTo] = useState(new Date(`September 30, ${currentYear} 00:00:00`));
    const [optionWeeklyNew, setOptionWeeklyNew] = useState({});

    const { chartType, dataLabels, stroke, tooltip, chartUtilize, legend } = {
        chartType: {
            id: "basic-bar",
            stacked: false,
            height: 480
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 2,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(2) + " cycles";
                    }
                    return y;

                }
            }
        },
        chartUtilize: {
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        legend: {
            show: 'always',
            showForSingleSeries: true
        }
    }

    const { years } = useSelector(function (state) {
        return {
            years: state.reportYear.response
        };
    });

    const { machines } = useSelector(state => ({
        machines: state.reportFilterAllMachine.response
    }));

    useEffect(() => {
        const fetchFirstData = async () => {
            setLoading(true);
            const payload = {
                year: currentYear,
                machines: [],
                pressType: currentPressType
            };
            await dispatch(pressCycleReportActions.getWeekly(payload))
            setLoading(false);
        };
        fetchFirstData();

        return () => dispatch(pressCycleReportActions.clear())
    }, [dispatch]);

    useEffect(() => {
        setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
    }, [machines]);

    useEffect(() => {
        const payload = {
            year: currentYear
        }
        dispatch(reportYearActions.get(payload))
        return () => dispatch(reportYearActions.clear())
    }, []);

    useEffect(() => {
        const payload = {
            machine: selectedMachines.map(i => i.value)
        }
        dispatch(reportFilterMachineActions.get(payload))
        return () => dispatch(reportFilterMachineActions.clear())
    }, []);

    const maxYear = Math.max.apply(Math, years.map(function (o) { return o.Id; }))
    const minYear = Math.min.apply(Math, years.map(function (o) { return o.Id; }))

    const onChangeSelectYear = async e => {
        setLoading(true);
        const year = e.target.value;

        setCurrentYear(year);
        setCurrentDateFrom(new Date(`October 01, ${year - 1} 00:00:00`));
        setCurrentDateTo(new Date(`September 30, ${year} 00:00:00`));

        const payload = { year: year, machines: selectedMachines.map(i => i.value), pressType: currentPressType };

        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(pressCycleReportActions.getMonthly(payload));
                break;

            case 'Yearly':
                await dispatch(pressCycleReportActions.getYearly(payload));
                break;

            case 'Quarterly':
                await dispatch(pressCycleReportActions.getQuarterly(payload));
                break;

            case 'Weekly':
                await dispatch(pressCycleReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    const onChangeSelectPressType = async e => {
        setLoading(true);
        const pressType = e.target.value;

        setCurrentPressType(pressType);
        const payload = { year: currentYear, machines: selectedMachines.map(i => i.value), pressType: pressType };

        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(pressCycleReportActions.getMonthly(payload));
                break;

            case 'Yearly':
                await dispatch(pressCycleReportActions.getYearly(payload));
                break;

            case 'Quarterly':
                await dispatch(pressCycleReportActions.getQuarterly(payload));
                break;

            case 'Weekly':
                await dispatch(pressCycleReportActions.getWeekly(payload));
                break;
            
            case 'Daily':
                const dateFrom = new DateObject(currentDateFrom).format('YYYY-MM-DD') + 'T00:00:00'
                const dateTo = new DateObject(currentDateTo).format('YYYY-MM-DD') + 'T00:00:00'
                const dailyPayload = { dateFrom: dateFrom, dateTo: dateTo, machines: selectedMachines.map(i => i.value), pressType: pressType };
                await dispatch(pressCycleReportActions.getDaily(dailyPayload));
                break;
        }
        setLoading(false);
    }

    const onChangeDateFrom = async e => {
        const dateFrom = e.format('YYYY-MM-DD') + 'T00:00:00';
        const dateTo = new DateObject(currentDateTo).format('YYYY-MM-DD') + 'T00:00:00'

        if (dateFrom > dateTo) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                html: '<b>Date From</b> should be smaller than <b>Date To</b>',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#0066cc'
            })
            setCurrentDateFrom(new DateObject(currentDateFrom));
        }
        else {
            setLoading(true);
            setCurrentDateFrom(e);
            const payload = { dateFrom: dateFrom, dateTo: dateTo, machines: selectedMachines.map(i => i.value), pressType: currentPressType };

            await dispatch(pressCycleReportActions.getDaily(payload));
            setLoading(false);
        }
    }

    const onChangeDisplayReport = async e => {
        setLoading(true)

        const displayReport = e.target.value;
        const year = currentYear;
        const payload = { year: year, machines: selectedMachines.map(i => i.value), pressType: currentPressType };

        const dateFrom = new DateObject(new Date(`October 01, ${year - 1} 00:00:00`)).format('YYYY-MM-DD') + 'T00:00:00';
        const dateTo = new DateObject(new Date(`September 30, ${year} 00:00:00`)).format('YYYY-MM-DD') + 'T00:00:00';

        setCurrentDisplay(displayReport);
        setCurrentDateFrom(new DateObject(dateFrom));
        setCurrentDateTo(new DateObject(dateTo));

        switch (displayReport) {
            case 'Monthly':
                await dispatch(pressCycleReportActions.getMonthly(payload));
                break;

            case 'Yearly':
                await dispatch(pressCycleReportActions.getYearly(payload));
                break;

            case 'Quarterly':
                await dispatch(pressCycleReportActions.getQuarterly(payload));
                break;

            case 'Daily':
                const dailyPayload = { dateFrom: dateFrom, dateTo: dateTo, machines: selectedMachines.map(i => i.value), pressType: currentPressType };
                await dispatch(pressCycleReportActions.getDaily(dailyPayload));
                break;

            case 'Weekly':
                await dispatch(pressCycleReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    const onChangeDateTo = async e => {
        const dateTo = e.format('YYYY-MM-DD') + 'T00:00:00';
        const dateFrom = new DateObject(currentDateFrom).format('YYYY-MM-DD') + 'T00:00:00';

        if (dateTo < dateFrom) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                html: '<b>Date To</b> should be larger than <b>Date From</b>',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#0066cc'
            })
            setCurrentDateTo(new DateObject(currentDateTo));
        }
        else {
            setLoading(true);
            setCurrentDateTo(e);
            const payload = { dateFrom: dateFrom, dateTo: dateTo, machines: selectedMachines.map(i => i.value), pressType: currentPressType };
            await dispatch(pressCycleReportActions.getDaily(payload));
            setLoading(false);
        }
    }

    const onChangeMachineReport = async (value, event) => {
        setLoading(true);
        if (event.action === "select-option" && event.option.value === "*") {
            setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setSelectedMachines([]);
        } else if (event.action === "deselect-option") {
            setSelectedMachines(value.filter((o) => o.value !== "*"));
        }
        else if (value.length === machines.length) {
            setSelectedMachines([{ label: "All", value: "*" }, ...machines]);
        }
        else {
            setSelectedMachines(value);
        }

        const selectedMachine = [];
        if (event.action === "select-option" && event.option.value === "*") { }
        else if (event.action === "deselect-option" && event.option.value === "*") { }
        else {
            value.map((data) => {
                selectedMachine.push(data.value)
            });
        }

        const year = currentYear;
        const payload = { year: year, machines: selectedMachine, pressType: currentPressType };
        switch (currentDisplayReport) {
            case 'Monthly':
                await dispatch(pressCycleReportActions.getMonthly(payload));
                break;
            case 'Yearly':
                await dispatch(pressCycleReportActions.getYearly(payload));
                break;
            case 'Quarterly':
                await dispatch(pressCycleReportActions.getQuarterly(payload));
                break;
            case 'Daily':
                const dateFrom = new DateObject(currentDateFrom).format('YYYY-MM-DD') + 'T00:00:00'
                const dateTo = new DateObject(currentDateTo).format('YYYY-MM-DD') + 'T00:00:00'
                const dailyPayload = { dateFrom: dateFrom, dateTo: dateTo, machines: selectedMachine, pressType: currentPressType };
                await dispatch(pressCycleReportActions.getDaily(dailyPayload));
                break;
            case 'Weekly':
                await dispatch(pressCycleReportActions.getWeekly(payload));
                break;
        }
        setLoading(false);
    }

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    let { weeklyData } = useSelector(
        (state) => ({
            weeklyData: state.pressCycleReport.weeklyData
        }),
        shallowEqual
    );

    useEffect(() => {
        var option = {
            chart: chartType, dataLabels: dataLabels, stroke: stroke, legend: legend,
            colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
            markers: {
                size: 5,
                hover: {
                    size: undefined,
                    sizeOffset: 3
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(2) + " cycles";
                        }
                        return y;
                    }
                },
                x: {
                    formatter: function (y, { w }) {
                        var category = w.globals.lastXAxis.categories[y - 1];
                        if (w.globals.lastXAxis.categories.length == 0) {
                            return "Wk-" + y;
                        }
                        return "Wk-" + category.split(";")[0] + " (" + category.split(";")[1] + ")";
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Number of Cycles',
                    style: {
                        fontFamily: 'PoppinsMedium'
                    }
                }
            },
            xaxis: {
                categories: weeklyData.length > 0 ? weeklyData[0].title : "",
                labels: {
                    formatter: function (value) {
                        if (typeof value === "undefined")
                            return "";
                        return "Wk-" + value.split(";")[0];
                    }
                }
            },
            chart: chartUtilize,
            legend: legend,
        };

        setOptionWeeklyNew(option);
    }, [weeklyData]);

    const { seriesMonthly, optionsMonthly, seriesYearly, optionsYearly, seriesQuarterly, optionsQuarterly, seriesDaily, optionsDaily } = useSelector(function (state) {
        const year = currentYear.toString();
        const quarterlyData = state.pressCycleReport.quarterlyData;
        const dailyData = state.pressCycleReport.dailyData;
        const yearlyData = state.pressCycleReport.yearlyData;

        return {
            seriesMonthly: state.pressCycleReport.monthlyData,
            seriesQuarterly: state.pressCycleReport.quarterlyData,
            seriesWeeklyPressCycle: weeklyData,
            seriesYearly: yearlyData,
            seriesDaily: dailyData,
            optionsYearly: {
                chart: chartType,
                dataLabels: dataLabels,
                stroke: stroke,
                tooltip: tooltip,
                legend: legend,
                colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                yaxis: {
                    title: {
                        text: 'Number of Cycles',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    }
                },
                xaxis: {
                    categories: yearlyData.length > 0 ? yearlyData[0].years : "",
                },
                chart: chartUtilize,
                legend: legend,
            },
            optionsQuarterly: {
                chart: chartType, dataLabels: dataLabels, stroke: stroke, legend: legend,
                colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + " cycles";
                            }
                            return y;
                        }
                    },
                    x: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.split(";")[0] + " (" + y.split(";")[1] + ")";
                            }

                            return y;
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                yaxis: {
                    title: {
                        text: 'Number of Cycles',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    }
                },
                xaxis: {
                    categories: quarterlyData.length > 0 ? quarterlyData[0].title : "",
                    labels: {
                        formatter: function (value) {
                            return value.split(";")[0];
                        }
                    }
                },
                chart: chartUtilize,
                legend: legend,
            },
            optionsDaily: {
                chart: chartType, dataLabels: dataLabels, stroke: stroke, tooltip: tooltip, legend: legend,
                colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                zoom: {
                    enabled: false,
                    autoScaleYaxis: false
                },
                markers: {
                    size: 5,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                    }
                },
                toolbar: {
                    autoSelected: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                yaxis: {
                    title: {
                        text: 'Number of Cycles',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    }
                },
                xaxis: {
                    categories: dailyData.length > 0 ? dailyData[0].titles : "",
                },
                chart: chartUtilize,
                legend: legend,
            },
            optionsMonthly: {
                chart: {
                    type: 'line',
                    stacked: false,
                    height: 480
                },
                markers: {
                    size: 5,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                        curve: 'straight',
                        lineCap: 'butt',
                    }
                },
                colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FD89C8', '#800000', '#808080', '#0000FF', '#556B2F'],
                stroke: {
                    show: true,
                    width: 2,
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2) + " cycles";
                            }
                            return y;
                        }
                    }
                },
                xaxis: {
                    categories: ['Oct ' + (year - 1), 'Nov ' + (year - 1), 'Dec ' + (year - 1), 'Jan ' + year, 'Feb ' + year, 'Mar ' + year, 'Apr ' + year, 'May ' + year, 'Jun ' + year, 'Jul ' + year, 'Aug ' + year, 'Sept ' + year],
                },
                yaxis: {
                    title: {
                        text: 'Number of Cycles',
                        style: {
                            fontFamily: 'PoppinsMedium'
                        }
                    },
                    labels: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(2);
                            }
                            return y;
                        }
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                        tools: {
                            download: false
                        }
                    }
                },
                legend: legend,
            }
        };
    });

    return (
        <div className='PressCycleChart'>
            <div className="is-pulled-left mt-3">
                <div className='card toolbox'>
                    <FilterReportDropdown
                        data={DisplayReport}
                        label={'Display By'}
                        value={currentDisplayReport}
                        placeholder="Select Display Report"
                        width={currentDisplayReport != 'Daily' ? '190px' : '125px'}
                        onChange={e => onChangeDisplayReport(e)}
                    />
                    <MultiSelectCheckbox
                        label='Select Machine'
                        width={currentDisplayReport != 'Daily' ? '190px' : '140px'}
                        placeholder='Select Machine'
                        options={machines}
                        getDropdownButtonLabel={e => getDropdownButtonLabel(e)}
                        onChange={onChangeMachineReport}
                        value={selectedMachines}
                        setState={setSelectedMachines}
                    />
                    <FilterPressTypeDropdown
                        data={PressType}
                        value={currentPressType}
                        placeholder="Select Press Type"
                        onChange={e => onChangeSelectPressType(e)}
                        isLoading={currentLoading}
                    />
                    {currentDisplayReport === 'Daily' ?
                        <div>
                            <InputDatePicker data={currentDateFrom} onChange={e => onChangeDateFrom(e)} label={'Date From'} minDate={new DateObject(new Date(`October 01, ${minYear - 1} 00:00:00`))} />
                            <InputDatePicker data={currentDateTo} onChange={e => onChangeDateTo(e)} label={'Date To'} maxDate={new DateObject(new Date(`September 30, ${maxYear} 00:00:00`))} />
                        </div>
                        :
                        <FilterYearDropdown
                            data={years}
                            value={currentYear}
                            placeholder="Select Year"
                            onChange={e => onChangeSelectYear(e)}
                            isLoading={currentLoading}
                        />}
                </div>
            </div>
            <h4 className='is-size-2 has-text-weight-bold'>
                Total Press Cycle by Machine
            </h4>
            <div className='chart'>
                {currentLoading && <Loading color='#80808030' type='spin' />}
                {!currentLoading && currentDisplayReport === 'Monthly' && <ReactApexChart options={optionsMonthly} series={seriesMonthly} type="line" height={460} />}
                {!currentLoading && currentDisplayReport === 'Yearly' && <ReactApexChart options={optionsYearly} series={seriesYearly} type="bar" height={460} />}
                {!currentLoading && currentDisplayReport === 'Quarterly' && <ReactApexChart options={optionsQuarterly} series={seriesQuarterly} type="bar" height={460} />}
                {weeklyData.length > 0 && !currentLoading && currentDisplayReport === 'Weekly' && <ReactApexChart options={optionWeeklyNew} series={weeklyData} type="line" height={460} />}
                {!currentLoading && currentDisplayReport === 'Daily' && <ReactApexChart options={optionsDaily} series={seriesDaily} type="line" height={460} />}
            </div>
        </div>
    )
}

export default PressCycleChart;
