import React, { useState } from 'react'
import Table from 'components/Atoms/Table'
import TableHead from 'components/Atoms/TableHead'
import TableCycleItem from 'components/Organisms/TableCycleItem'
import './TableCycleData.scss'

const TableCycleData = ({
  tHeadData,
  isLoading,
  data,
  buttonRelease,
  isHaveAccess,
  skipCount,
  searchKeyword
}) => {
  const [isCollapseFor, setCollapseFor] = useState(null)
  const onClickCollapse = e => {
    if (isCollapseFor === e) {
      setCollapseFor(null)
    } else {
      setCollapseFor(e)
    }
  }
  const onClickButtonRelease = e => {
    buttonRelease(e)
  }
  return (
    <Table>
      <TableHead tHeadData={tHeadData} />
      {data.length === 0 && (
        <tr>
          <td colSpan='7' className='nodata'>
            There is no data to display
          </td>
        </tr>
      )}
      {data.length !== 0 && (
        <TableCycleItem
          data={data}
          onClick={i => onClickCollapse(i)}
          isCollapseFor={isCollapseFor}
          onClickButtonRelease={data => onClickButtonRelease(data)}
          isHaveAccess={isHaveAccess}
          skipCount={skipCount}
          searchKeyword={searchKeyword}
        />
      )}
    </Table>
  )
}

export default TableCycleData
