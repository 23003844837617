import { createSlice } from '@reduxjs/toolkit';
import { getMonthly, getYearly, getQuarterly, getWeekly, getDaily } from 'state/thunks/pressCycleReport';

const initialState = {
    monthlyData: [],
    yearlyData: [],
    quarterlyData: [],
    weeklyData: [],
    dailyData: [],
    loading: false,
    success: false,
    error: null
}

const pressCycleReportSlice = createSlice({
    name: 'pressCycleReport',
    initialState,
    reducers: {
        clear: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(getMonthly.pending, state => {
            state.loading = true
        })

        builder.addCase(getMonthly.fulfilled, (state, action) => {
            state.monthlyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getMonthly.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getYearly.pending, state => {
            state.loading = true
        })

        builder.addCase(getYearly.fulfilled, (state, action) => {
            state.yearlyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getYearly.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getQuarterly.pending, state => {
            state.loading = true
        })

        builder.addCase(getQuarterly.fulfilled, (state, action) => {
            state.quarterlyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getQuarterly.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getWeekly.pending, state => {
            state.loading = true
        })

        builder.addCase(getWeekly.fulfilled, (state, action) => {
            state.weeklyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getWeekly.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })

        builder.addCase(getDaily.pending, state => {
            state.loading = true
        })

        builder.addCase(getDaily.fulfilled, (state, action) => {
            state.dailyData = action.payload
            state.loading = false
            state.success = true
            state.error = null
        })

        builder.addCase(getDaily.rejected, (state, action) => {
            state.loading = false
            state.success = false
            state.error = action.payload
        })
    }
})

export const actions = {
    ...pressCycleReportSlice.actions,
    getMonthly,
    getYearly,
    getQuarterly,
    getWeekly,
    getDaily
}

export const reducer = pressCycleReportSlice.reducer;
