import React from 'react'
import { Field, ErrorMessage } from 'formik'

import Dropdown from 'components/Atoms/Dropdown'

const FormDropdown = ({
  name,
  label,
  data,
  placeholder,
  required,
  ...props
}) => {
  return (
    <div className='field'>
      <label className='label' htmlFor={`dropdown-${name}`}>
        {label}
      </label>
      {data && (
        <Field
          {...props}
          name={name}
          id={`dropdown-${name}`}
          component={Dropdown}
          data={data}
          placeholder={placeholder}
        />
      )}

      <ErrorMessage
        name={name}
        render={msg => <p className='help is-danger'>{msg}</p>}
      />
    </div>
  )
}

FormDropdown.defaultProps = {
  placeholder: '--Please Select--',
  required: false
}

export default FormDropdown
