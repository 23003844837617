import React from 'react'
import cx from 'classnames'
import Modal from 'components/Atoms/Modal'

import styles from './WipModal.module.scss'

const WipModal = ({ isOpen, onClose, confirmChange }) => {
  return (
    <>
      <Modal
        haveClose
        isLarge
        isOpen={isOpen}
        onClose={onClose}
        title='Change the WIP algorithm'
      >
        <section className={cx('modal-card-body', styles.modalCard)}>
          <div className={cx(styles.customContainer)}>
            <h3>Are you sure you want to change the WIP algorithm?</h3>
            <p style={{ fontStyle: 'italic' }}>
              Note: This will take effect on the next optimization.
            </p>
          </div>
        </section>
        <footer className={cx('modal-card-foot', styles.rightButton)}>
          <button className='button is-success' onClick={() => confirmChange()}>
            Change Now
          </button>
        </footer>
      </Modal>
    </>
  )
}

export default WipModal
