import React from 'react';
import DatePicker from "react-multi-date-picker";

import './InputDatePicker.scss'

const InputDatePicker = ({ data, label, onChange, minDate, maxDate }) => {
    return (
        <div className="field is-horizontal calendar-date-picker">
            <div className="field-label is-normal">
                <label className="label">{label}: </label>
            </div>
            <div className="field-body">
                <div className="field is-narrow">
                    <div className="control">
                        <DatePicker value={data} onChange={onChange} minDate={minDate} maxDate={maxDate} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InputDatePicker
