import { createSlice } from '@reduxjs/toolkit'

import { get } from 'state/thunks/optimizationsMachines'

const initialState = {
  response: {
    TotalCount: 0,
    Items: []
  },
  newArr : [],
  arrayRes: [],
  loading: false,
  success: false,
  error: null
}

const optimizationsMachinesSlice = createSlice({
  name: 'optimizationsMachines',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      const { meta } = action || {}
      const { arg } = meta || {}
      state.response = action.payload.response
      if(action.payload.response.Items.length === 0){
        state.newArr.push(
          { 
            machineCode : arg.machineName, 
            machine: {
              Name : arg.machineName,
              Id: arg.machineId,
              Books : [],
              Data : [{
                Books : []
              }]
            }
           }
        )
      }
      
      // console.log("action.payload.response.Items", action.payload.response.Items)

      action.payload.response.Items.map((item)=> {
        // console.log('itemnya', item)
        state.newArr.push({
          machineCode : arg.machineName, 
          machine: {
            Name : arg.machineName,
            Id: arg.machineId,
            ReleasedBy : item.ReleasedBy,
            ReleasedDate : item.ReleasedDate,
            ProcessCycle : item.ProcessCycle,
            StatusString : item.StatusString,
            Books : item.Books,
            Data : []
          }
        })
        
        state.newArr.map((value, index)=>{
          if(value.machine.Name === item.Machine.Name){
            state.newArr[index].machine.Data.push(item)
          }
        })

        
      })
      
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...optimizationsMachinesSlice.actions,
  get
}

export const reducer = optimizationsMachinesSlice.reducer
