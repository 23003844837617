import { createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'connections/restApi'

export const get = createAsyncThunk('optimizationsMachines/get', payload => {
  const {
    statuses,
    skipCount,
    maxResultCount,
    machineId,
    machineName
  } = payload
  const payloads = {
    statuses,
    skipCount,
    maxResultCount,
    machineName
  }
  const response = api.getOptimizationsMachines(payloads, machineId)
  return response
})
