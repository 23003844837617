import { createSlice } from '@reduxjs/toolkit'

import { get, put } from 'state/thunks/wip'

const initialState = {
  response: {
    Logic: 0,
    LogicString: ''
  },
  loading: false,
  success: false,
  error: null
}

const wipSlice = createSlice({
  name: 'wip',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(get.pending, state => {
      state.loading = true
    })

    builder.addCase(get.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(get.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })

    builder.addCase(put.pending, state => {
      state.loading = true
    })

    builder.addCase(put.fulfilled, (state, action) => {
      state.response = action.payload.response
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(put.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...wipSlice.actions,
  get,
  put
}

export const reducer = wipSlice.reducer
