import { createSlice } from '@reduxjs/toolkit';
import { getMonthly, getWeekly } from 'state/thunks/occupancyReport';

const initialState = {
  response: [],
  weeklyData: [],
  loading: false,
  success: false,
  error: null
}

const occupancyReportSlice = createSlice({
  name: 'occupancyReport',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(getMonthly.pending, state => {
      state.loading = true
    })

    builder.addCase(getMonthly.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(getMonthly.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })

    builder.addCase(getWeekly.pending, state => {
      state.loading = true
    })

    builder.addCase(getWeekly.fulfilled, (state, action) => {
      state.weeklyData = action.payload
      state.loading = false
      state.success = true
      state.error = null
    })

    builder.addCase(getWeekly.rejected, (state, action) => {
      state.loading = false
      state.success = false
      state.error = action.payload
    })
  }
})

export const actions = {
    ...occupancyReportSlice.actions,
    getMonthly,
    getWeekly
}

export const reducer = occupancyReportSlice.reducer;
